import axios from "axios";

const state = {
    clients: [],
    zohoClients: {
        synchronized: false,
        synchronizing: false,
    },
};

const getters = {
    clients: (state) => state.clients,
    client: (state) => (zoho_id) => {
        return state.clients.find((client) => client.zoho_id == zoho_id);
    },
    getClientCertifications: (state) => (zoho_id) => {
        var client = state.clients.find((client) => client.zoho_id == zoho_id);
        client.clientStatus = "grey";
        var clientCertsCount = 0;
        var clientCertsOkCount = 0;
        client.certifications.forEach(function (cert) {
            clientCertsCount++;
            if (
                cert.temp.certification_step_id == 3 &&
                cert.temp.has_cert == 1
            ) {
                clientCertsOkCount++;
                cert.badge = "green";
            }
            if (
                (cert.temp.certification_step_id == 3 ||
                    cert.temp.certification_step_id == 4) &&
                cert.temp.has_cert == 0
            ) {
                cert.badge = "red";
                client.certStatus = "red";
            }
        });
        if (clientCertsCount == 0) {
            client.certStatus = "grey";
        } else if (clientCertsOkCount == clientCertsCount) {
            client.certStatus = "green";
        }

        return client.certifications;
    },
    getCertifications: (state) => {
        var certifications = [];

        state.clients.forEach(function (client) {
            client.certifications.forEach(function (certification) {
                var cert = {
                    client_id: certification.client_id,
                    client: client.account_name,
                    norm: certification.norm.norm,
                    accreditation: certification.accreditation.accreditation,
                };
            });
        });
    },
};

const mutations = {
    storeClients(state, payload) {
        state.clients = payload;
    },
    zohoSync(state, payload) {
        state.zohoClients.synchronized = payload.synchronized;
        state.zohoClients.synchronizing = payload.synchronizing;
    },
    deleteClientsBatch(state, clients) {
        state.clients = [];
    },
    updateClient(state, client) {
        var clientId = state.clients.findIndex(
            (clientState) => clientState.zoho_id == client.zoho_id
        );
        state.clients[clientId].id = client.id;
        state.clients[clientId].exists_in_db = true;
        state.clients[clientId].certifications = [];
    },
    storeNewCert(state, certification) {
        var clientId = state.clients.findIndex(
            (client) => client.id === certification.client_id
        );

        if (typeof state.clients[clientId].certifications === "undefined") {
            state.clients[clientId].certifications = [];
        }
        state.clients[clientId].certifications.push(certification);
    },
    editCertification(state, certification) {
        var client = state.clients.find(
            (client) => client.id == certification.client_id
        );
        var certifications = client.certifications;
        var certifId = certifications.findIndex(
            (cert) => cert.id == certification.id
        );
        certifications.splice(certifId, 1, certification);

        client.clientStatus = "grey";
        var clientCertsCount = 0;
        var clientCertsOkCount = 0;
        client.certifications.forEach(function (cert) {
            clientCertsCount++;
            if (
                cert.temp.certification_step_id == 3 &&
                cert.temp.has_cert == 1
            ) {
                clientCertsOkCount++;
                // cert.badge = 'green'
            }
            if (
                (cert.temp.certification_step_id == 3 ||
                    cert.temp.certification_step_id == 4) &&
                cert.temp.has_cert == 0
            ) {
                // cert.badge = 'red'
                client.certStatus = "red";
            }
        });
        if (clientCertsCount == 0) {
            client.certStatus = "grey";
        } else if (clientCertsOkCount == clientCertsCount) {
            client.certStatus = "green";
        }

        // return client.certifications
    },
};

const actions = {
    /** OK  */
    storeClients({ commit, rootGetters }) {
        /** Store clients from database in vuex store */
        axios
            .get("clients", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((response) => {
                commit("storeClients", response.data.data);
                commit("zohoSync", {
                    synchronized: false,
                    synchronizing: true,
                });
            })
            .catch((error) => {
                let popupPayload = {
                    appears: true,
                    message:
                        "Hubo un error al intentar sincronizar los clientes de Zoho.",
                    type: "error",
                };
                commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                commit("zohoSync", {
                    synchronized: false,
                    synchronizing: false,
                });
                console.log(
                    "store clients from database error",
                    error.response
                );
            });
    },
    updateClient({ commit, rootGetters }, zoho_id) {
        axios
            .post(
                "clients",
                {
                    zoho_id: zoho_id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("updateClient", response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    storeNewCert({ commit, rootGetters }, certification) {
        axios
            .post(
                "certifications",
                {
                    client_id: certification.client_id,
                    norm_id: certification.norm,
                    accreditation_id: certification.accreditation,
                    certification_step_id: certification.certstep,
                    fase_two: certification.fase_two,
                    emite: certification.emite,
                    tiene: certification.tiene,
                    next_step_id: certification.nextstep,
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("storeNewCert", response.data.data);
            })
            .catch((error) => {
                console.log("storeNewCert error", error);
            });
    },
    editCertification({ commit, rootGetters }, certification) {
        axios
            .put(
                "certifications/" + certification.id,
                {
                    client_id: certification.client_id,
                    norm_id: certification.norm,
                    accreditation_id: certification.accreditation,
                    certification_step_id: certification.certstep,
                    fase_two: certification.fase_two,
                    emite: certification.emite,
                    tiene: certification.tiene,
                    next_step_id: certification.nextstep,
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("editCertification", response.data.data);
            })
            .catch((error) => {
                console.log("editCertification action: ", error.response);
            });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
