<script>
import DashboardCard from "./DashboardCard.vue";

export default {
    name: "DashboardCertificationsWithoutData",
    components: {
        DashboardCard,
    },
    props: {
        certificationsWithoutData: {
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            certifications: {},
        };
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Certificaciones sin información </template>
        <template #subheader
            >Aquí se listan certificaciones que tienen faltantes de información
            (fechas, mds, acreditación, etc)</template
        >

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="certificationsWithoutData.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="certification in certificationsWithoutData"
                    :key="certification.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                            v-if="certification.client"
                        >
                            {{ certification.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            {{ certification.norm.norm }}
                            {{ certification.accreditation.accreditation }}
                        </p>
                        <p>
                            Faltantes:
                            <span
                                v-for="(
                                    item, index
                                ) in certification.doesntHave"
                                class="mr-1"
                                ><span v-if="index > 0">, </span>
                                {{ item }}</span
                            >
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
