<script setup>
import { computed, ref } from "vue";
import { useUserStore } from "../stores/user";
import axios from "axios";

const user = useUserStore();

const file = ref(null);
const fileName = computed(() => file.value?.name);
// const fileUpload = ref(null);
const type = ref("curso");
const sending = ref(false);
// const message = ref("");
const alert = ref({
    message: "",
    alertType: "",
});

function selectFile(event) {
    file.value = event.target.files[0];
}

const sendCertificates = async () => {
    if (!file.value) {
        alert.value.message = "Tiene que seleccionar un archivo";
        alert.value.alertType = "warning";
        sending.value = false;
        return;
    }

    alert.value.message = "";
    alert.value.alertType = "";
    sending.value = true;

    let data = new FormData();
    data.append("csv", file.value);
    data.append("tipo", type.value);

    axios
        .post("akademie/certificates/send", data)
        .then((response) => {
            sending.value = false;
            alert.value.message =
                "Se enviaron " +
                response.data.cantidad +
                " certificados correctamente";
            alert.value.alertType = "success";
        })
        .catch((error) => {
            console.log(error.response);
            sending.value = false;
            alert.value.message =
                "Se produjo un error al intentar enviar los certificados";
            alert.value.alertType = "danger";
        })
        .finally(() => {
            file.value = null;
        });
};

const alertType = computed(() => {
    return "alert-" + alert.value.alertType;
});
</script>

<template>
    <div>
        <div class="cards__container w-1/2">
            <div class="card">
                <div v-if="alert.message">
                    <div class="alert" v-bind:class="alertType" v-if="alert">
                        {{ alert.message }}
                    </div>
                </div>
                <ol class="mb-4 list-inside list-decimal">
                    <li>Descargue archivo modelo (Excel) y edítelo</li>
                    <li>Guarde el archivo como csv (separado con comas)</li>
                    <li>Seleccione el tipo de certificado (curso o webinar)</li>
                    <li>Suba el archivo csv</li>
                    <li>Haga click en "Enviar certificados"</li>
                </ol>

                <a
                    class="text-blue-700 underline p-2 text-center"
                    href="https://tuv-nord.com.ar/akademie_files/tuvnord-certificados-akademie-example.csv"
                    target="_blank"
                    >Descargar archivo csv modelo</a
                >
                <div>
                    <div class="w-full">
                        <label for="type" class="">Tipo de certificados</label>
                        <select
                            class="w-full"
                            name="type"
                            id="type"
                            v-model="type"
                            required
                        >
                            <option
                                value=""
                                selected
                                disabled
                                class="text-gray-400"
                            >
                                Seleccione el tipo de Certificado
                            </option>
                            <option value="curso">Curso</option>
                            <option value="webinar">Webinar</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label
                            class="mb-4 w-full flex flex-col items-center px-4 py-6 bg-white hover:bg-blue-700 text-gray-700 hover:text-gray-50 rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer ease-linear transition-all duration-150"
                        >
                            <i class="fas fa-cloud-upload-alt fa-2x"></i>
                            <span
                                class="mt-2 text-base leading-normal text-center break-words inline-block w-56"
                                v-if="fileName"
                                >{{ fileName }}</span
                            >
                            <span
                                class="mt-2 text-base leading-normal text-center break-words inline-block w-56"
                                v-else
                                >Seleccione archivo</span
                            >
                            <input
                                class="hidden"
                                type="file"
                                ref="fileUpload"
                                @change="selectFile"
                        /></label>
                    </div>
                    <div class="w-full">
                        <button
                            class="button blue-button w-full"
                            disabled
                            v-if="sending"
                        >
                            Enviando certificados...
                        </button>
                        <button
                            class="button blue-button w-full"
                            @click="sendCertificates"
                            v-else
                        >
                            <span>Enviar certificados</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
