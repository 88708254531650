<script>
import axios from "axios";
import Accreditation from "../components/SettingsAccreditationsItem.vue";
import SettingsEac from "../components/SettingsEacsItem.vue";
import SettingsRisk from "../components/SettingsRisksItem.vue";

import { mapActions } from "vuex";

export default {
    name: "SettingsAccredEacs",
    components: {
        Accreditation,
        SettingsEac,
        SettingsRisk,
    },
    data() {
        return {
            // EACS
            loadingEacs: false,
            eacs: {},

            accreditations: this.$store.state.accreditations.accreditations,
            newAccreditation: {
                accreditation: "",
                description: "",
            },
            createAccreditation: false,
            newEac: {
                eac: "",
                description: "",
            },
            createEac: false,
            newRisk: {
                risk: "",
                description: "",
            },
            createRisk: false,
        };
    },
    computed: {
        // orderedEacs(){
        //     return this.$store.state.eacs.eacs.sort((a, b) => a.eac.localeCompare(b.eac, undefined, {
        //         numeric: true, sensitivity: 'base'
        //     }));
        // },
        risks() {
            return this.$store.state.risks.risks;
        },
    },
    methods: {
        ...mapActions({
            storeAccreditation: "accreditations/storeAccreditation",
            storeEac: "eacs/storeEac",
            storeRisk: "risks/storeRisk",
        }),
        clearNewAccreditation() {
            this.newAccreditation.accreditation = "";
            this.newAccreditation.description = "";
        },
        clearNewEac() {
            this.newEac.eac = "";
            this.newEac.description = "";
        },
        clearNewRisk() {
            this.newRisk.risk = "";
            this.newRisk.description = "";
        },
    },
    created() {
        this.loadingEacs = true;

        axios
            .get("eacs", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                this.eacs = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loadingEacs = false;
            });
    },
};
</script>

<template>
    <!-- <div class="mt-8 grid grid-cols-2 w-full gap-4 text-slate-600"> -->
    <div class="mt-8 columns-2 w-full gap-4 text-slate-600">
        <div class="bg-slate-50 mb-4 rounded-xl py-8 px-4 shadow-slate-500/25">
            <div class="flex flex-row items-center justify-between mb-4">
                <h2 class="text-xl font-bold">Acreditaciones</h2>
                <button
                    class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Agregar acreditación"
                    @click="createAccreditation = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-plus"
                        class="text-lg text-slate-50 fa-swap-opacity"
                    />
                </button>
            </div>

            <div class="grid grid-cols-4 mb-4">
                <div class="col-span-1 font-bold">Acreditación</div>
                <div class="col-span-2 font-bold">Descripción</div>
                <div class="col-span-1 font-bold">
                    <!-- Acciones -->
                </div>
            </div>
            <div
                class="grid grid-cols-4 items-center gap-4"
                v-if="createAccreditation"
            >
                <div class="col-span-1">
                    <span>
                        <input
                            type="text"
                            v-model="newAccreditation.accreditation"
                            placeholder="Acreditación"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-2">
                    <span>
                        <input
                            type="text"
                            v-model="newAccreditation.description"
                            placeholder="Descripción"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-1 flex flex-row gap-2">
                    <button
                        class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-1 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Guardar"
                        @click="
                            storeAccreditation(newAccreditation);
                            clearNewAccreditation();
                            createAccreditation = false;
                        "
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-floppy-disk"
                            class="text-lg text-slate-50"
                        />
                    </button>
                    <button
                        class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Cancelar"
                        @click="createAccreditation = false"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-xmark"
                            class="text-lg text-slate-600 fa-swap-opacity"
                        />
                    </button>
                </div>
            </div>

            <accreditation
                v-for="accreditation in accreditations"
                :key="accreditation.id"
                :accreditation="accreditation"
            ></accreditation>
        </div>

        <div
            class="bg-slate-50 rounded-xl mb-4 py-8 px-4 shadow-slate-500/25 break-after-column"
        >
            <div class="flex flex-row items-center justify-between mb-4">
                <h2 class="text-xl font-bold">Riesgos</h2>
                <button
                    class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Agregar riesgo"
                    @click="createRisk = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-plus"
                        class="text-lg text-slate-50 fa-swap-opacity"
                    />
                </button>
            </div>

            <div class="grid grid-cols-4 mb-4">
                <div class="col-span-1 font-bold">Riesgo</div>
                <div class="col-span-2 font-bold">Descripción</div>
                <div class="col-span-1 font-bold">
                    <!-- Acciones -->
                </div>
            </div>
            <div class="grid grid-cols-4 items-center gap-4" v-if="createRisk">
                <div class="col-span-1">
                    <span>
                        <input
                            type="text"
                            v-model="newRisk.risk"
                            placeholder="Riesgo"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-2">
                    <span>
                        <input
                            type="text"
                            v-model="newRisk.description"
                            placeholder="Descripción"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-1 flex flex-row gap-2">
                    <button
                        class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-1 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Guardar"
                        @click="
                            storeRisk(newRisk);
                            clearNewRisk();
                            createRisk = false;
                        "
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-floppy-disk"
                            class="text-lg text-slate-50"
                        />
                    </button>
                    <button
                        class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Cancelar"
                        @click="createRisk = false"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-xmark"
                            class="text-lg text-slate-600 fa-swap-opacity"
                        />
                    </button>
                </div>
            </div>

            <settings-risk
                v-for="risk in risks"
                :key="risk.id"
                :risk="risk"
            ></settings-risk>
        </div>

        <div class="bg-slate-50 rounded-xl mb-4 py-8 px-4 shadow-slate-500/25">
            <div class="flex flex-row items-center justify-between mb-4">
                <h2 class="text-xl font-bold">Alcances</h2>
                <button
                    class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Agregar alcance"
                    @click="createEac = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-plus"
                        class="text-lg text-slate-50 fa-swap-opacity"
                    />
                </button>
            </div>

            <div class="grid grid-cols-4 mb-4">
                <div class="col-span-1 font-bold">Alcance</div>
                <div class="col-span-2 font-bold">Descripción</div>
                <div class="col-span-1 font-bold">
                    <!-- Acciones -->
                </div>
            </div>
            <div class="grid grid-cols-4 items-center gap-4" v-if="createEac">
                <div class="col-span-1">
                    <span>
                        <input
                            type="text"
                            v-model="newEac.eac"
                            placeholder="Alcance"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-2">
                    <span>
                        <input
                            type="text"
                            v-model="newEac.description"
                            placeholder="Descripción"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-1 flex flex-row gap-2">
                    <button
                        class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-1 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Guardar"
                        @click="
                            storeEac(newEac);
                            clearNewEac();
                            createEac = false;
                        "
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-floppy-disk"
                            class="text-lg text-slate-50"
                        />
                    </button>
                    <button
                        class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Cancelar"
                        @click="createEac = false"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-xmark"
                            class="text-lg text-slate-600 fa-swap-opacity"
                        />
                    </button>
                </div>
            </div>

            <settings-eac
                v-for="eac in eacs"
                :key="eac.id"
                :eac="eac"
            ></settings-eac>
        </div>
    </div>
</template>
