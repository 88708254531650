<script>
import DashboardCardMini from "../components/DashboardCardMini.vue";
import DashboardCardsContainer from "../components/DashboardCardsContainer.vue";
import DashboardCardsMiniContainer from "../components/DashboardCardsMiniContainer.vue";
import DashboardFinishedPhasesWithoutInvoice from "../components/DashboardFinishedPhasesWithoutInvoice.vue";

export default {
    name: "DashboardAdmin",
    components: {
        DashboardCardMini,
        DashboardCardsContainer,
        DashboardCardsMiniContainer,
        DashboardFinishedPhasesWithoutInvoice,
    },
    data() {
        return {
            servicesWithoutInvoice: {},
            servicesWithoutInvoiceLoading: false,
        };
    },
    created() {
        this.servicesWithoutInvoiceLoading = true;

        this.$axios
            .get("dashboard-services-no-invoices", {
                headers: { Authorization: "Bearer " + this.getToken },
            })
            .then((response) => {
                this.servicesWithoutInvoice = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.servicesWithoutInvoiceLoading = false;
            });
    },
    computed: {
        countServicesWithoutInvoice() {
            return this.servicesWithoutInvoice.length;
        },
    },
};
</script>

<template>
    <div>
        <DashboardCardsMiniContainer>
            <DashboardCardMini>
                <template #counter v-if="servicesWithoutInvoiceLoading">
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-notch fa-spin"
                    />
                </template>
                <template #counter v-else>
                    {{ countServicesWithoutInvoice }}
                </template>

                <template #title>Etapas finalizadas sin facturar</template>
            </DashboardCardMini>
        </DashboardCardsMiniContainer>
        <DashboardCardsContainer>
            <DashboardFinishedPhasesWithoutInvoice
                :services="servicesWithoutInvoice"
            />
        </DashboardCardsContainer>
    </div>
</template>
