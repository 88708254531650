import axios from "axios";

const state = {
	risks: [],
};

const getters = {
	
};

const mutations = {
    loadRisks(state, risks){
        state.risks = risks;
    },
    storeRisk(state, payload){
        state.risks.push(payload);

    },
    updateRisk(state, payload){
        const riskIndex = state.risks.findIndex(risk => risk.id == payload.id);
        state.risks.splice(riskIndex, 1, payload);
        
    },
    deleteRisk(state, payload){
        const riskIndex = state.risks.findIndex(risk => risk.id == payload.id);
        state.risks.splice(riskIndex, 1);
    },
};

const actions = {
    loadRisks({ commit, rootGetters }){
        axios.get('risks', {
                headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
            })
            .then((response) => {
                commit('loadRisks', response.data.data);
            })
            .catch((error) => {
                console.log('error en storeAccreditations (store): ', error.response)
            })
    },
    storeRisk({ commit, rootGetters }, payload) {
        axios.post('risks', {
            risk: payload.risk,
            description: payload.description,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('storeRisk', response.data.data);
            let popupPayload = {
                appears: true,
                message: `Riesgo ${payload.risk} creado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response)
            let popupPayload = {
                appears: true,
                message: `No pudo crearse el riesgo ${payload.risk}`,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    updateRisk({ commit, rootGetters }, payload) {
        axios.put('risks/' + payload.id, {
            risk: payload.risk,
            description: payload.description,
        }, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('updateRisk', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Riesgo ${payload.risk} actualizado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    deleteRisk({ commit, rootGetters }, payload) {
        axios.delete('risks/' + payload.id, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('deleteRisk', response.data.data)

            let popupPayload = {
                appears: true,
                message: `Riesgo ${payload.risk} eliminado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}