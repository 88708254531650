<script>
import DashboardCard from "./DashboardCard.vue";
import moment from "moment";

export default {
    name: "DashboardClientsWithoutSchedule",
    components: {
        DashboardCard,
    },
    props: {
        clientsWithoutSchedule: {
            required: true,
        },
    },
    computed: {
        expiresInCheck(client) {
            if (client.first_opened_certification_phase) {
                return moment(
                    client.first_opened_certification_phase.expires
                ).isBefore(moment());
            } else {
                return false;
            }
        },
    },
    methods: {
        expiresIn(client) {
            if (client.first_opened_certification_phase) {
                return moment(
                    client.first_opened_certification_phase.expires
                ).toNow(true);
            } else {
                return "";
            }
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Clientes sin agenda </template>

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="clientsWithoutSchedule.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="client in clientsWithoutSchedule"
                    :key="client.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ client.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            <span
                                >{{ client.norm.norm }} -
                                {{ client.accreditation.accreditation }}</span
                            >
                            <span class="font-normal">
                                <span
                                    v-if="
                                        client.first_opened_certification_phase
                                    "
                                    >{{
                                        client.first_opened_certification_phase
                                            .phase.phase_name
                                    }}</span
                                ><br />
                                <span class="text-rose-500" v-if="client">
                                    Venció hace {{ expiresIn(client) }}
                                </span>
                                <span class="text-blue-500" v-else>
                                    Vence en {{ expiresIn(client) }}
                                </span>
                            </span>
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
