<script setup lang="ts">
import AppLogo from "./AppLogo.vue";

const logo_src: string = "/images/logo-tuvnord-argentina.svg";
const condor_src: string = "/images/condor.svg";
</script>
<template>
    <div>
        <AppLogo class="h-24 mx-auto mb-4" />
        <img class="h-32 mx-auto" :src="condor_src" alt="Sistema Cóndor" />
    </div>
</template>
