import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUserStore = defineStore("user", () => {
    const id = ref(0);
    const email = ref("");
    const token = ref("");
    const isLoggedIn = ref(false);
    const first_name = ref("");
    const last_name = ref("");
    const documents_check = ref(false);
    const roles = ref([]);
    const norms = ref([]);

    const isOperator = computed(
        () => !!roles.value.find((role) => role.role == "operador")
    );
    const isAuditor = computed(
        () => !!roles.value.find((role) => role.role == "auditor")
    );

    const getToken = computed(() => {
        return token.value;
    });

    return {
        id,
        email,
        token,
        isLoggedIn,
        first_name,
        last_name,
        documents_check,
        roles,
        norms,
        isOperator,
        isAuditor,
        getToken,
    };
});
