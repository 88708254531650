<script>
import DashboardCard from "./DashboardCard.vue";

export default {
    name: "DashboardCertificationsWithOneAuditor",
    components: {
        DashboardCard,
    },
    props: {
        certificationsWithOneAuditor: {
            required: true,
        },
    },
    computed: {
        certificationsSortedByName() {
            const certs = this.certificationsWithOneAuditor.filter(
                (cert) => cert.client != "sin nombre"
            );
            return certs.sort((a, b) => a.client.localeCompare(b.client));
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Certificaciones con un auditor </template>

        <template #subheader>
            Certificaciones que tienen disponible un único auditor líder, senior
            o especialista.
        </template>

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="certificationsSortedByName.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="(certification, index) in certificationsSortedByName"
                    :key="index"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                            v-if="certification.client"
                        >
                            {{ certification.client }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            <span
                                class="font-normal"
                                v-if="
                                    certification.norm &&
                                    certification.accreditation
                                "
                            >
                                <span v-if="certification.norm">{{
                                    certification.norm
                                }}</span>
                                <span v-if="certification.accreditation">
                                    - {{ certification.accreditation }}</span
                                >
                                <span v-if="certification.eac">
                                    - Alcance {{ certification.eac }}</span
                                ><br />
                                <span v-if="certification.auditor"
                                    >Auditor: {{ certification.auditor }}</span
                                >

                                <!-- <span v-if="certification.eacs_norms[0].eac"> - {{ certification.eacs_norms[0].eac.eac }}</span>
                                <span v-if="certification.eacs_norms[0].risk"> - {{ certification.eacs_norms[0].risk.risk }}</span> -->
                            </span>
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
