<script>
import { mapActions } from "vuex";

export default {
    name: "SettingsContainer",
    methods: {
        ...mapActions({
            loadNorms: "norms/storeNorms",
            loadAccreditations: "accreditations/storeAccreditations",
            loadEacs: "eacs/storeEacs",
            loadRisks: "risks/loadRisks",
        }),
    },
    created() {
        this.loadNorms();
        this.loadAccreditations();
        this.loadEacs();
        this.loadRisks();
    },
};
</script>

<template>
    <div class="mb-16 mx-8">
        <header class="flex flex-row justify-between w-full">
            <div class="flex flex-col justify-start items-baseline my-2 w-1/5">
                <h1 class="text-xl text-tuvdarkblue">Ajustes</h1>
                <p class="text-tuvdarkblue ml-6 md:ml-0">
                    Configuración de Cóndor
                </p>
            </div>
            <div class="w-full my-2 relative md:col-span-5 xl:col-span-6"></div>
            <div class="w-ful my-2 md:col-span-4 xl:col-span-3 md:ml-2"></div>
        </header>
        <nav class="flex flex-row justify-start gap-2">
            <router-link
                :to="{ name: 'settingsnorms' }"
                class="bg-slate-50 shadow shadow-slate-500/25 cursor-pointer disabled:cursor-not-allowed px-6 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow-none disabled:shadow transition-shadow ease-out duration-100 mr-2 text-slate-600 disabled:text-slate-400 no-underline hover:bg-slate-200"
            >
                Normas
            </router-link>
            <router-link
                :to="{ name: 'settingseacs' }"
                class="bg-slate-50 shadow shadow-slate-500/25 cursor-pointer disabled:cursor-not-allowed px-6 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow-none disabled:shadow transition-shadow ease-out duration-100 mr-2 text-slate-600 disabled:text-slate-400 no-underline hover:bg-slate-200"
            >
                Alcances y acreditaciones
            </router-link>
        </nav>

        <router-view></router-view>
    </div>
</template>

<style scoped>
.router-link-exact-active {
    @apply bg-blue-500 hover:bg-blue-500 text-slate-50;
}
</style>
