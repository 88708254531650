<script>
export default {
    data() {
        return {
            wasClicked: false,
        };
    },
    methods: {
        handleClick() {
            this.wasClicked = !this.wasClicked;
        },
    },
};
</script>

<template>
    <button type="button" class="button blue-button" @click="handleClick">
        <slot></slot>
        <div class="snippet" data-title=".dot-flashing" v-if="wasClicked">
            <div class="stage">
                <div class="dot-flashing"></div>
            </div>
        </div>
    </button>
</template>

<style scoped>
button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.snippet {
    margin-left: 2rem;
}
.dot-flashing {
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 5px;
    background-color: #dddce5;
    color: #dddce5;
    animation: dotFlashing 0.3s infinite linear alternate;
    animation-delay: 0.12s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 5px;
    background-color: #dddce5;
    color: #dddce5;
    animation: dotFlashing 0.3s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 5px;
    background-color: #dddce5;
    color: #dddce5;
    animation: dotFlashing 0.3s infinite alternate;
    animation-delay: 0.3s;
}

@keyframes dotFlashing {
    0% {
        background-color: #0050b8;
    }
    50%,
    100% {
        background-color: #dddce5;
    }
}
</style>
