import { ref } from "vue";
import axios from "axios";
import { useUserStore } from "../stores/user";

export function useNorms(normId) {
    const user = useUserStore();

    const norms = ref([]);
    const norm = ref([]);

    axios
        .get("norms", {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        })
        .then((response) => {
            norms.value = response.data.data;
        });

    if (normId) {
        norm.value = norms.value.filter((norm) => (norm.id = normId));
    }
    return {
        norms,
        norm,
    };
}
