<script>
import axios from "axios";
import navigation from "../../views/spa/mixins/navigation";

export default {
    data() {
        return {
            loading: false,
            user: {
                persona: {
                    first_name: "",
                    last_name: "",
                },
                auditors: [],
                email: "",
                username: "",
                roles: [],
            },
        };
    },
    mixins: [navigation],
    created() {
        this.loading = true;
        axios
            .get("users/" + this.$route.params.id)
            .then((response) => {
                this.user = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    computed: {
        userData() {
            return this.user;
        },
    },
    methods: {
        updateCertifications(newCertification) {
            this.user.auditors.push(newCertification);
        },
    },
};
</script>
<template>
    <div>
        <div v-if="loading">Cargando información</div>
        <header class="grid grid-cols-1 md:grid-cols-12" v-else>
            <div class="w-full my-2 md:col-span-1">
                <button
                    class="bg-slate-300 w-16 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-sm text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2"
                    @click="goToPreviousPage()"
                    v-title
                    title="Volver a usuarios"
                >
                    <i
                        class="fa-duotone fa-arrow-left text-xl text-slate-600"
                    ></i>
                </button>
            </div>

            <div
                class="flex flex-row md:flex-col justify-start items-baseline my-2 md:col-span-4"
            >
                <h1 class="text-2xl font-bold text-slate-600 py-0">
                    <font-awesome-icon icon="fa-duotone fa-user-tie" />
                    {{ user.persona.first_name }} {{ user.persona.last_name }}
                </h1>
                <p class="text-slate-400 ml-6 md:ml-0 capitalize">
                    {{ user.roles[0].role }}
                </p>
            </div>

            <div class="w-full my-2 relative md:col-span-5 xl:col-span-5"></div>
        </header>
        <router-view
            :user="userData"
            @updateUserCertifications="updateCertifications"
        ></router-view>
    </div>
</template>
