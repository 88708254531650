<template>
    <div class="flex flex-col w-full">
        <button
            class="text-xs p-2 w-full flex items-center gap-2 justify-center"
            :class="syncStatus"
            @click="!synchronizing && syncWithZoho()"
        >
            <font-awesome-icon
                :icon="['fal', 'arrows-rotate']"
                class="text-lg"
                :class="{ 'fa-spin': synchronizing }"
            />
            <span v-if="syncStatus == 'bg-tuvgreen'">Zoho: sincronizado</span>
            <span v-if="syncStatus == 'bg-tuvyellow'">Zoho: sincronizando</span>
            <span v-if="syncStatus == 'bg-tuvred'">Zoho: error</span>
        </button>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import axios from "axios";
import { useUserStore } from "../stores/user";
import { useClientsStore } from "../stores/clients";
const user = useUserStore();
const clients = useClientsStore();

const synchronizing = ref(false);
const syncStatus = ref("");

const syncWithZoho = function () {
    synchronizing.value = true;
    syncStatus.value = "bg-tuvyellow";
    axios
        .get("zoho-clients", {
            headers: { Authorization: "Bearer " + user.token },
        })
        .then((response) => {
            syncStatus.value = "bg-tuvgreen";
            clients.storeClients(response.data.data);
        })
        .catch((error) => {
            syncStatus.value = "bg-tuvred";
        })
        .finally(() => {
            synchronizing.value = false;
        });
};

syncWithZoho();
</script>
