<script>
import axios from "axios";
import DashboardCard from "./DashboardCard.vue";
// import excel from 'vue-excel-export';

export default {
    name: "DashboardAuditorsCertificationsQty",
    components: {
        DashboardCard,
    },
    data() {
        return {
            totalCertificationsCount: 0,
            certificationsPerAuditor: {},
            loadingCertificationsPerAuditor: false,
            json_fields: {
                cliente: "client.account_name",
                norma: "norm.norm",
                acreditacion: "accreditation.accreditation",
                alcance: "certification_eac_norm.eac_norm.eac.eac",
                riesgo: "certification_eac_norm.eac_norm.risk.risk",
            },
        };
    },
    created() {
        this.loadingCertificationsPerAuditor = true;
        axios
            .get("dashboard/auditors/certifications-per-auditor", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                this.totalCertificationsCount =
                    response.data.totalCertificationsCount;
                this.certificationsPerAuditor = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loadingCertificationsPerAuditor = false;
            });
    },
    computed: {
        auditors() {
            let auditors = JSON.parse(
                JSON.stringify(this.certificationsPerAuditor)
            );
            let sortedAuditors = Object.entries(auditors)
                .map((auditor) => auditor[1])
                .sort(function (a, b) {
                    return b.certificationsCount - a.certificationsCount;
                });

            return sortedAuditors;
        },
        certificationsQty() {
            return this.totalCertificationsCount;
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Certificaciones por auditor </template>

        <template #subheader>
            Cantidad de certificaciones que un auditor puede realizar como
            Senior o Líder (total de certificaciones: {{ certificationsQty }})
        </template>

        <template #body>
            <ul class="h-60 overflow-y-auto">
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="(auditor, index) in auditors"
                    :key="auditor.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ auditor.persona.last_name }}
                            {{ auditor.persona.first_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            Cantidad de certificaciones:
                            <span
                                class="text-slate-600 font-bold"
                                v-if="certificationsQty"
                                >{{ auditor.certificationsCount }} ({{
                                    (
                                        (auditor.certificationsCount * 100) /
                                        certificationsQty
                                    ).toFixed(2)
                                }}%)</span
                            >
                        </p>
                        <download-excel
                            class="cursor-pointer text-tuvblue"
                            :data="auditor.noCertifications"
                            :fields="json_fields"
                            :title="
                                'Certificaciones que no puede realizar ' +
                                auditor.persona.first_name +
                                ' ' +
                                auditor.persona.last_name
                            "
                            :worksheet="
                                auditor.persona.last_name +
                                '-' +
                                auditor.persona.first_name
                            "
                            :name="
                                'certificaciones-que-no-puede-realizar-' +
                                auditor.persona.last_name +
                                '.xls'
                            "
                            >Certificaciones que no puede realizar (excel)
                        </download-excel>
                    </span>
                </li>
            </ul>
        </template>
    </DashboardCard>
</template>
