<script>

export default {
    data(){
        return {
            currentStep: 'CLIENT',
            steps: [
                'CLIENT',
                'NORMS',
                'TEAM',
                'AGENDA',
            ],
            client: {},
            newServiceForm: {
                client: {},
                services: [],
                team: [],
                agenda: [],
            },
        }
    },
    methods: {
        prevStep(){
            let currentStepIndex = this.steps.indexOf(this.currentStep);

            if(currentStepIndex == 0){
                return;
            }

            this.currentStep = this.steps[currentStepIndex - 1];
        },
        nextStep(){
            let currentStepIndex = this.steps.indexOf(this.currentStep);
            
            if(currentStepIndex + 1 == this.steps.length) {
                return;
            }

            if(this.currentStep == 'CLIENT'){
                this.loadClientData(this.newServiceForm.client.id);
            }

            this.currentStep = this.steps[currentStepIndex + 1];
        },
        loadClientData(clientID){
            this.$axios.get('clients/' + clientID, {
                headers: { Authorization: 'Bearer ' + this.$store.getters['user/getToken'] }
            })
            .then((response) => {
                this.client = response.data.data
            })
            .catch((error) => {
                console.log(error.response)
            })
        },
        addDate(date, fullDate, userId, dataField){
            dataField.push({date: date, fullDate, userId})
        },
        removeDate(index, dataField){
            dataField.splice(index, 1);
        },
        changeFullDate(index, dataField){
            dataField[index].fullDate = !dataField[index].fullDate;
        }
    },  
    computed: {
        sortedClients(){
            let clients = this.$store.state.clients.clients;
            return clients.sort((a,b) => a.account_name.localeCompare(b.account_name, undefined, {numeric: true, sensitivity: 'base'}));
        },
        certificationsSelectedClient(){
            this.$axios.get('clients/' + this.client.id, {

            })
            .then((response) => {
                this.client = response.data.data
            })
            .catch((error) => {
                console.log(error.response)
            })
            let clientCertifications = this.$store.state.certifications.certifications.filter(norm => norm.client_id == this.newServiceForm.client.zoho_id)
            return clientCertifications;
        },

    }
}

</script>


<template>
    <div>
        <div v-if="currentStep == 'CLIENT'">
            <h2 class="text-2xl">Seleccione un cliente</h2>
            <select name="" id="" v-model="newServiceForm.client">
                <option value="">Cliente</option>
                <option 
                    v-for="client in sortedClients" 
                    :key="client.zoho_id" 
                    :value="client">{{ client.account_name }}
                </option>
            </select>
        </div>
        <div v-if="currentStep == 'NORMS'">
            <h2 class="text-2xl">seleccionar normas</h2>

            <div v-for="certification in client.certifications" :key="certification.id">

                <h3>{{ certification.norm.norm }} {{ certification.accreditation.accreditation }}</h3>
                <p>{{ certification.first_opened_certification_phase.phase.phase_name }} - MDs: {{ certification.first_opened_certification_phase.man_days }}</p>
                
                <label :for="certification.first_opened_certification_phase.id">
                    <input 
                        type="checkbox" 
                        :id="certification.first_opened_certification_phase.id"
                        v-model="newServiceForm.services"
                        :value="certification"
                    >
                    {{ certification.norm.norm }} {{ certification.accreditation.accreditation }}<br>
                    {{ certification.first_opened_certification_phase.phase.phase_name }}
                </label>
                
            </div>

        </div>
        <div v-if="currentStep == 'TEAM'">
            <h2 class="text-2xl">Crear equipo auditor</h2>
            <div v-for="service in newServiceForm.services" :key="service.id">
                <p>{{ service.norm.norm }} {{ service.accreditation.accreditation }}</p>
                <p>MDs: {{ service.first_opened_certification_phase.man_days }}</p>
                <p>EACs:
                    <ul>
                        <li v-for="eac in service.eacs_norms" :key="eac.id">
                            <h3>{{ eac.eac.eac }}</h3>
                            <div v-for="auditor in eac.auditors" :key="auditor.id">
                                <label :for="auditor.id">
                                    <input 
                                        type="checkbox" 
                                        :id="auditor.id"
                                        v-model="newServiceForm.team"
                                        :value="auditor"
                                    >
                                    {{ auditor.user.username }} - {{ auditor.status.status }}
                                </label>
                                
                            </div>
                        </li>
                    </ul>
                </p>
            </div>
        </div>
        <div v-if="currentStep == 'AGENDA'">
            <h2 class="text-2xl">Agenda</h2>
            <div v-for="member in newServiceForm.team" :key="member.id">
                {{ member.user.username }}
                <div v-if="!newServiceForm.agenda.length" @click="addDate(null, true, member.id, newServiceForm.agenda)">agregar fecha</div>
                <div v-else>
                    <div v-for="(date, index) in newServiceForm.agenda" :key="`date-${index}`">
                        <input type="date" v-model="date.date"> <input type="checkbox" :value="date.fullDate" :checked="date.fullDate" @change="changeFullDate(index, newServiceForm.agenda)"> <span @click="addDate(date, true, member.id, newServiceForm.agenda)"><i class="fa-solid fa-plus"></i></span> <span @click="removeDate(index, newServiceForm.agenda)"><i class="fa-solid fa-minus"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button @click="prevStep()">Anterior</button>
            <button @click="nextStep()">Siguiente</button>
        </div>
    </div>
</template>