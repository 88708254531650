<script>
import { Bar } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels
);

export default {
    name: "CardMDPerMonthYear",
    components: {
        Bar,
    },
    data() {
        return {
            height: 300,
            chartOptions: {
                legend: {
                    display: true,
                },
                tooltips: {
                    enabled: false,
                },
                hover: {
                    animationDuration: 0,
                },
                scales: {
                    xAxes: [
                        {
                            barThickness: 30,
                            maxBarThickness: 30,
                        },
                    ],
                },
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20,
                },
                responsive: true,
                animation: {
                    duration: 1,
                },
                plugins: {
                    datalabels: {
                        color: "#475569",
                        anchor: "end",
                        align: "end",
                    },
                },
            },
            currentYear: {
                label: "",
                manDays: [],
                total: 0,
            },
            nextYear: {
                label: "",
                manDays: [],
                total: 0,
            },
            nextNextYear: {
                label: "",
                manDays: [],
                total: 0,
            },
            chartData: {},
            loaded: false,
        };
    },
    methods: {
        completeWithMonths(elements) {
            let completed = [];

            let year = elements[0].year;

            for (let i = 1; i <= 12; i++) {
                var result = elements.find((element) => element.month == i);

                if (result === undefined) {
                    completed.push({ man_days: 0, year: year, month: i });
                } else {
                    completed.push(result);
                }
            }
            return completed;
        },
    },
    async mounted() {
        await this.$axios
            .get("mds-subtotals", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                let thisYear = new Date().getFullYear();
                let thisYearPlusOne = thisYear + 1;
                let thisYearPlusTwo = thisYear + 2;

                this.currentYear.label = thisYear;
                this.nextYear.label = thisYearPlusOne;
                this.nextNextYear.label = thisYearPlusTwo;

                let dataFromAPI = response.data.data;

                this.currentYear.manDays = dataFromAPI.filter(
                    (month) => month.year == thisYear
                );

                this.currentYear.total = this.currentYear.manDays.reduce(
                    (a, b) => a + b.man_days,
                    0
                );

                this.currentYear.manDays = this.completeWithMonths(
                    this.currentYear.manDays
                ).map((month) => month.man_days);

                this.nextYear.manDays = dataFromAPI.filter(
                    (month) => month.year == thisYearPlusOne
                );

                this.nextYear.total = this.nextYear.manDays.reduce(
                    (a, b) => a + b.man_days,
                    0
                );

                this.nextYear.manDays = this.completeWithMonths(
                    this.nextYear.manDays
                ).map((month) => month.man_days);

                this.nextNextYear.manDays = dataFromAPI.filter(
                    (month) => month.year == thisYearPlusTwo
                );

                this.nextNextYear.total = this.nextNextYear.manDays.reduce(
                    (a, b) => a + b.man_days,
                    0
                );

                this.nextNextYear.manDays = this.completeWithMonths(
                    this.nextNextYear.manDays
                ).map((month) => month.man_days);

                this.chartData = {
                    labels: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    datasets: [
                        {
                            label:
                                this.currentYear.label +
                                " (total " +
                                this.currentYear.total +
                                ")",
                            backgroundColor: "#3245D3",
                            data: this.currentYear.manDays,
                            datalabels: {
                                color: "#3245D3",
                            },
                        },
                        {
                            label:
                                this.nextYear.label +
                                " (total " +
                                this.nextYear.total +
                                ")",
                            backgroundColor: "#00A5CF",
                            data: this.nextYear.manDays,
                            datalabels: {
                                color: "#00A5CF",
                            },
                        },
                        {
                            label:
                                this.nextNextYear.label +
                                " (total " +
                                this.nextNextYear.total +
                                ")",
                            backgroundColor: "#73CBA3",
                            data: this.nextNextYear.manDays,
                            datalabels: {
                                color: "#73CBA3",
                            },
                        },
                    ],
                };

                this.loaded = true;

                // this.renderChart({
                //     labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                //     datasets: [
                //         {
                //             label: this.currentYear.label + ' (total ' + this.currentYear.total + ')',
                //             backgroundColor: '#3E82F8',
                //             data: this.currentYear.manDays,
                //             datalabels: {
                //                 color: '#3E82F8',
                //             },
                //         },
                //         {
                //             label: this.nextYear.label + ' (total ' + this.nextYear.total + ')',
                //             backgroundColor: '#f43f5e',
                //             data: this.nextYear.manDays,
                //             datalabels: {
                //                 color: '#f43f5e',
                //             },
                //         },
                //         {
                //             label: this.nextNextYear.label + ' (total ' + this.nextNextYear.total + ')',
                //             backgroundColor: '#11B981',
                //             data: this.nextNextYear.manDays,
                //             datalabels: {
                //                 color: '#11B981',
                //             },
                //         }
                //     ],

                // },
                // this.options
                // )
            })
            .catch((error) => {
                console.log("error chart data md per month year: ", error);
            });
    },
};
</script>

<template>
    <div
        class="bg-slate-100 p-8 rounded-xl h-fit shadow-2xl shadow-slate-500/25"
    >
        <h2 class="text-lg font-bold text-slate-600">
            Forecast MDs 3 años vista
        </h2>
        <div v-if="loaded">
            <Bar
                :data="chartData"
                :options="chartOptions"
                ref="barChartMDs"
                :height="100"
            />
        </div>
    </div>
</template>
