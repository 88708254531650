<template>
    <div class="bg-slate-50 py-8 px-4 h-96 cursor-default rounded-xl">
        <h2 class="text-lg text-slate-600">
            <slot name="header"></slot>
        </h2>
        <p class="text-slate-400 h-9 min-h-fit">
            <slot name="subheader"></slot>
        </p>
        <div class="transition-opacity duration-500">
            <slot name="body"></slot>
        </div>
    </div>
</template>
