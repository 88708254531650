<script>
import DashboardCard from "./DashboardCard.vue";
import BadgeStatus from "./BagdeStatus.vue";
import moment from "moment";

export default {
    name: "DashboardLogEmails",
    components: {
        DashboardCard,
        BadgeStatus,
    },

    data() {
        return {
            loading: true,
            emails: {},
        };
    },
    created() {
        this.loading = true;
        this.$axios
            .get("dashboard/logs/emails", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                this.emails = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        emailStatus(status) {
            if (status) {
                return true;
            } else {
                return false;
            }
        },
        emailSent(email) {
            return moment(email.created_at).format("DD-MM-YYYY HH:mm:ss");
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header>
            Log envío de emails
            <!-- <span><font-awesome-icon icon="fa-solid fa-arrows-rotate" class="text-slate-400" /></span> -->
        </template>

        <template #body>
            <p v-if="loading">Cargando logs...</p>
            <ul class="h-60 overflow-y-auto" v-else>
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="email in emails"
                    :key="email.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ email.subject }}
                            <BadgeStatus :status="emailStatus(email.sent)"
                                ><span v-if="emailStatus(email.sent)"
                                    >Enviado</span
                                ><span v-else>Error</span></BadgeStatus
                            >
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            De: {{ email.from }}<br />A: {{ email.to
                            }}<br />Enviado: {{ emailSent(email) }}
                        </p>
                    </span>
                </li>
            </ul>
        </template>
    </DashboardCard>
</template>
