const state = {
	months: {"1":"Enero", "2":"Febrero","3":"Marzo","4":"Abril","5":"Mayo","6":"Junio","7":"Julio","8":"Agosto","9":"Septiembre","10":"Octubre","11":"Noviembre","12":"Diciembre"},
	alertPopup: {
		appears: false,
		message: '',
		type: '',
		
	}
};


const getters = {

};

const mutations = {
	changePopup(state, payload){

		var message = '';

		/**
		 * If payload type is error, contains an array of errors from 
		 * Laravel Requests Validations
		 * Otherwise, it contains a string.
		 */
		if(payload.type === 'error'){
			for(let [key, value] of Object.entries(payload.message)) {
                message += value[0] + ' - ';
            }
		}else{
			message = payload.message;
		}

		state.alertPopup.appears = payload.appears;
		state.alertPopup.message = message;
		state.alertPopup.type = payload.type;
		
		var timeout = setTimeout(function(){
			state.alertPopup.appears = false;
			state.alertPopup.message = '';
			state.alertPopup.type = '';
		
		}, 3000);
	},
};

const actions = {

};



export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}