<script>
import Modal from "../../../_elements/Modal.vue";
import { mapActions } from "vuex";

export default {
    name: "CertificationEacModal",
    components: {
        Modal,
    },
    props: {
        certification: {
            required: true,
        },
        norms: {
            required: true,
        },
    },
    data() {
        return {
            addEac: false,
            addingEac: false,
            newEac: "",
            deletingEac: false,
        };
    },
    methods: {
        ...mapActions({
            updateEacNormInCertification:
                "certifications/updateEacNormInCertification",
        }),
        openEditEacsModal() {
            this.$refs.editarEacs.openModal();
        },
        addEacFn() {
            this.addEac = true;
            const interval = setInterval(() => {
                if (this.$refs.eacInput) {
                    this.$refs.eacInput.focus();
                    clearInterval(interval);
                }
            }, 50);
        },
        addingEacFn() {
            this.addEac = false;
            this.addingEac = true;

            this.$axios
                .post(
                    "certifications/" + this.certification.id + "/eacs",
                    { eac: this.newEac },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    let eac = response.data.data;
                    this.$store.commit(
                        "certifications/addCertificationEac",
                        eac
                    );
                    this.addingEac = false;
                    this.newEac = "";
                })
                .catch((error) => {
                    console.log(error.response);
                    this.addingEac = false;
                    this.newEac = "";
                });
        },
        cancelAddEacFn() {
            this.addEac = false;
        },
        deleteEacFn(eac) {
            this.$axios
                .delete(
                    "certifications/" + this.certification.id + "/eacs/" + eac,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    let eac = response.data.data;
                    this.$store.commit(
                        "certifications/deleteCertificationEac",
                        eac
                    );
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        isInCertification(id) {
            return this.certification.eacs_norms.some(
                (eacNorm) => eacNorm.id === id
            );
        },
    },
    computed: {
        normEacs() {
            let norm = this.norms.find(
                (norm) => norm.id === this.certification.norm_id
            );
            return norm.eacs_norms;
        },
    },
};
</script>

<template>
    <modal ref="editarEacs">
        <template v-slot:header>
            <h2 class="text-xl uppercase">
                {{ certification.client.account_name }} - Alcances/Riesgos
            </h2>
            <p class="text-neutral-500 text-lg">
                {{ certification.norm.norm }} -
                {{ certification.accreditation.accreditation }}
            </p>
        </template>

        <template v-slot:body>
            <p class="text-slate-500 text-xs mb-4">
                Seleccione los alcances y riesgos para esta certificación (los
                EAC marcados en azul son los pertenecientes a esta
                certificación). En el supuesto de que no encuentre, debe generar
                primero la relación entre la norma y el alcance/riesgo en
                <router-link class="inline" :to="{ name: 'SettingsNorms' }"
                    >ajustes</router-link
                >.
            </p>
            <div class="grid grid-cols-6 gap-4 mb-4" v-if="normEacs">
                <div
                    class="mr-2 rounded-full border-2 border-blue-500 text-sm py-2 px-4 cursor-pointer"
                    v-for="normEac in normEacs"
                    :key="normEac.id"
                    :class="[
                        isInCertification(normEac.id)
                            ? ['bg-blue-500', 'text-white']
                            : ['bg-blue-100', 'border-dashed', 'text-blue-500'],
                    ]"
                    @click="
                        updateEacNormInCertification({
                            certification_id: certification.id,
                            eac_norm_id: normEac.id,
                        })
                    "
                >
                    <span v-if="normEac.eac">{{ normEac.eac.eac }}</span>
                    <span v-if="normEac.risk">{{ normEac.risk.risk }}</span>
                </div>
            </div>
        </template>

        <template v-slot:footer>
            <div class="flex flex-row justify-end">
                <button
                    class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                    @click="$refs.editarEacs.closeModal()"
                >
                    Finalizar
                </button>
            </div>
        </template>
    </modal>
</template>
