<script>
import axios from "axios";
import SettingsNorm from "../components/SettingsNormsItem.vue";

import { mapActions } from "vuex";

export default {
    name: "SettingsNorms",
    components: {
        SettingsNorm,
    },
    data() {
        return {
            loadingNorms: false,
            norms: {},
            createNorm: false,
            newNorm: {
                norm: "",
                description: "",
            },
        };
    },
    computed: {
        // norms(){
        //     return this.$store.state.norms.norms
        // }
    },
    methods: {
        ...mapActions({
            storeNorms: "norms/storeNorms",
            storeNorm: "norms/storeNorm",
        }),
        clearNewNorm() {
            this.newNorm.norm = "";
            this.newNorm.description = "";
        },
    },
    created() {
        this.loadingNorms = true;

        axios
            .get("norms", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                this.norms = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loadingNorms = false;
            });
    },
};
</script>

<template>
    <div class="mt-8 grid grid-cols-1 w-full gap-4 text-slate-600">
        <div class="bg-slate-50 rounded-xl py-8 px-4 shadow-slate-500/25">
            <div class="flex flex-row items-center justify-between mb-4">
                <h2 class="text-xl font-bold">Normas</h2>
                <button
                    class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Agregar norma"
                    @click="createNorm = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-plus"
                        class="text-lg text-slate-50 fa-swap-opacity"
                    />
                </button>
            </div>

            <div class="grid grid-cols-5 mb-4">
                <div class="col-span-1 font-bold">Norma</div>
                <div class="col-span-1 font-bold">Descripción</div>
                <div class="col-span-2 font-bold">Alcances</div>
                <div class="col-span-1 font-bold">Acciones</div>
            </div>
            <div class="grid grid-cols-4 items-center gap-4" v-if="createNorm">
                <div class="col-span-1">
                    <span>
                        <input
                            type="text"
                            v-model="newNorm.norm"
                            placeholder="Norma"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-2">
                    <span>
                        <input
                            type="text"
                            v-model="newNorm.description"
                            placeholder="Descripción"
                            class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        />
                    </span>
                </div>
                <div class="col-span-1 flex flex-row gap-2">
                    <button
                        class="bg-blue-500 hover:bg-blue-500 text-slate-50 shadow shadow-slate-500/25 cursor-pointer px-5 py-1 uppercase rounded-xl tracking-wide text-sm active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Guardar"
                        @click="
                            storeNorm(newNorm);
                            clearNewNorm();
                            createNorm = false;
                        "
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-floppy-disk"
                            class="text-lg text-slate-50"
                        />
                    </button>
                    <button
                        class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100"
                        v-title
                        title="Cancelar"
                        @click="createNorm = false"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-xmark"
                            class="text-lg text-slate-600 fa-swap-opacity"
                        />
                    </button>
                </div>
            </div>

            <settings-norm
                v-for="norm in norms"
                :key="norm.id"
                :norm="norm"
            ></settings-norm>
        </div>
    </div>
</template>
