<template>
    <tr class="px-4 py-2">
        <td class="table-cell">
            <div
                class="bg-zinc-300 w-10/12 block h-8 my-2 rounded-lg animate-pulse"
            ></div>
        </td>
        <td class="table-cell">
            <div
                class="bg-zinc-300 w-10/12 block h-8 my-2 rounded-lg animate-pulse"
            ></div>
        </td>
        <td class="table-cell">
            <div
                class="bg-zinc-300 w-8/12 block h-8 my-2 rounded-lg animate-pulse"
            ></div>
        </td>
        <td class="table-cell">
            <div
                class="bg-zinc-300 w-11/12 block h-8 my-2 rounded-lg animate-pulse"
            ></div>
        </td>
        <td class="table-cell">
            <div
                class="bg-zinc-300 w-10/12 block h-8 my-2 rounded-lg animate-pulse"
            ></div>
        </td>
    </tr>
</template>
