<script>
// import GraphNormProportion from './GraphNormProportion.vue';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: "CardGraphNormsProportion",
    components: {
        Pie,
        // GraphNormProportion,
    },
    data() {
        return {
            loaded: false,
            chartData: {},
            height: 300,
            chartOptions: {
                legend: {
                    display: true,
                    position: "left",
                    align: "start",
                    labels: {
                        generateLabels: function (chart) {
                            var data = chart.data;

                            if (data.labels.length && data.datasets.length) {
                                return data.labels.map(function (label, i) {
                                    // var meta = chart.getDatasetMeta(0);
                                    var ds = data.datasets[0];

                                    // var arc = meta.data[i];
                                    // var custom = arc && arc.custom || {};
                                    // // var getValueAtIndexOrDefault = theHelp.getValueAtIndexOrDefault;
                                    // var arcOpts = chart.options.elements.arc;
                                    // var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                    // var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                    // var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                                    return {
                                        // And finally :
                                        text: ds.label[i],
                                        fillStyle: ds.backgroundColor[i],
                                        // strokeStyle: stroke,
                                        lineWidth: 0,
                                        // hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        // index: i
                                    };
                                });
                            }
                            return [];
                        },
                    },
                },
                tooltips: {
                    enabled: true,
                },
                hover: {
                    animationDuration: 0,
                },
                height: 1000,
                // scales: {
                //     xAxes: [{
                //         barThickness: 25,
                //         maxBarThickness: 25,
                //     }]
                // }
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 20,
                        bottom: 20,
                    },
                },

                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 1,
                },
                plugins: {
                    datalabels: {
                        color: "#475569",
                        anchor: "end",
                        align: "end",
                        display: "auto",
                        formatter: function (value, context) {
                            let totalCerts = context.dataset.data.reduce(
                                (acc, curr) => acc + curr,
                                0
                            );
                            return (
                                context.chart.data.labels[context.dataIndex] +
                                " (" +
                                Math.round((value * 100) / totalCerts) +
                                "%)"
                            );
                        },
                    },
                    legend: {
                        display: true,
                        position: "left",
                        align: "start",
                        filter: function (value, context) {
                            return (
                                context.chart.data.labels[context.dataIndex] +
                                " (" +
                                value +
                                ")"
                            );
                        },
                        labels: {
                            generateLabels: (chart) => {
                                if (
                                    chart.data.labels.length &&
                                    chart.data.datasets.length
                                ) {
                                    return chart.data.labels.map(
                                        (label, i) => ({
                                            text:
                                                label +
                                                " (" +
                                                chart.data.datasets[0].data[i] +
                                                ")",
                                            fillStyle:
                                                chart.data.datasets[0]
                                                    .backgroundColor[i],
                                            strokeStyle: "#F1F5F9",
                                        })
                                    );
                                }
                            },

                            // generateLabels: function (value, context) {
                            //     let totalCerts = context.dataset.data.reduce(
                            //         (acc, curr) => acc + curr,
                            //         0
                            //     );
                            //     return (
                            //         context.chart.data.labels[
                            //             context.dataIndex
                            //         ] +
                            //         " (" +
                            //         Math.round((value * 100) / totalCerts) +
                            //         "%)"
                            //     );
                            // },
                        },
                    },
                },
            },
        };
    },
    methods: {
        completeWithMonths(elements) {
            let completed = [];

            let year = elements[0].year;

            for (let i = 1; i <= 12; i++) {
                var result = elements.find((element) => element.month == i);

                if (result === undefined) {
                    completed.push({ man_days: 0, year: year, month: i });
                } else {
                    completed.push(result);
                }
            }
            return completed;
        },
    },
    async mounted() {
        await this.$axios
            .get("dashboard-norms-proportion", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                let dataFromAPI = response.data;
                let labels = [];
                let labelsData = [];
                let data = [];
                let bgColors = [
                    "#F04262",
                    "#EB4999",
                    "#D747ED",
                    "#A657F7",
                    "#8A5EF6",
                    "#6366F1",
                    "#3D81F5",
                    "#04A5E9",
                    "#05B6D5",
                    "#0EB8A7",
                    "#11B984",
                    "#23C461",
                    "#83CC20",
                    "#E7B211",
                    "#F39E17",
                    "#F47521",
                    "#EC4748",
                ];

                dataFromAPI.forEach(function (element, index) {
                    labels.push(element.norm);
                    data.push(element.quantity);
                });
                this.chartData = {
                    labels: labels,
                    datasets: [
                        {
                            backgroundColor: bgColors,
                            label: labelsData,
                            data: data,
                            borderWidth: 0,
                        },
                    ],
                };

                this.loaded = true;
            })
            .catch((error) => {
                console.log("error chart data md per month year: ", error);
            });
    },
};
</script>

<template>
    <div
        class="bg-slate-100 py-12 px-4 rounded-xl shadow-2xl shadow-slate-500/25"
    >
        <h2 class="text-lg font-bold text-slate-600">
            Distribución de certificados
        </h2>
        <div v-if="loaded">
            <Pie
                :options="chartOptions"
                :data="chartData"
                :height="300"
                ref="barChartMDs"
            />
        </div>
        <!-- <graph-norm-proportion
                :options="chartOptions"
                :height="300"
                ref="barChartMDs">
        </graph-norm-proportion> -->
    </div>
</template>
