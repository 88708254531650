<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import Modal from "./_elements/Modal.vue";
import moment from "moment";
import AlertPopup from "./AlertPopup.vue";
import axios from "axios";

export default {
    components: {
        FullCalendar,
        Modal,
        AlertPopup,
    },
    props: {
        search: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            serviceData: {
                title: null,
                date: null,
                status: null,
            },
            serviceUpdateData: {
                name: null,
                status: null,
                date: null,
                auditor: null,
            },
            services: [],
            searchText: "",
            filteredServices: [],
            prevSearchText: "",
            filteredServicesIndex: 0,
        };
    },
    watch: {
        queriedAvailabilities(dates) {
            dates.forEach((date) => {
                var calendarDate = document.querySelectorAll(
                    `[data-date="${date}"]`
                );

                for (var item of calendarDate) {
                    item.children[0].style.background = "#10b981";
                }
            });
        },
    },
    computed: {
        queriedAvailabilities() {
            return this.$store.state.operationsCalendar.queriedAvailabilities;
        },
        ...mapGetters({
            servicesTemp: "servicesTemp/getServicesTemp",
            clients: "clients/clients",
        }),
        config() {
            return {
                ...this.configOptions,
                ...this.eventHandlers,
            };
        },
        configOptions() {
            console.log("eventos", [
                { events: [...this.servicesTemp, ...this.services] },
            ]);
            return {
                firstDay: 0,
                locale: esLocale,
                eventSources: [
                    { events: [...this.servicesTemp, ...this.services] },
                ],
                eventDisplay: "block",
                displayEventTime: false,
                // events: this.availabilitiesBk,
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                contentHeight: "auto",
                selectOverlap: false,
                selectable: true,
                progressiveEventRendering: true,
                eventDidMount: function (info) {
                    let eventEl = info.el;
                    eventEl.setAttribute("v-title", "");
                    eventEl.setAttribute("title", info.event.title);
                },
            };
        },
        eventHandlers() {
            return {
                dateClick: this.handleDateCLick,
                // select: this.handleSelect,
                // loading: this.handleLoading,
                eventClick: this.handleEventClick,
            };
        },
    },
    methods: {
        ...mapActions({
            /** OK */
            getUsers: "users/getUsers",
            storeClients: "clients/storeClients",
            storeServicesTempBatch: "servicesTemp/storeServicesTempBatch",
            deleteServiceTemp: "servicesTemp/deleteServiceTemp",

            /** Chequear */

            storeServiceTemp: "servicesTemp/storeServiceTemp",

            updateServiceTemp: "servicesTemp/updateServiceTemp",
            storeAccreditations: "accreditations/storeAccreditations",
            // 'storeAuditors': 'auditors/storeAuditors',
            storeCertifications: "certifications/storeCertifications",
            storeEacs: "eacs/storeEacs",
        }),
        searchServices(data) {
            this.searchText = data;
            if (this.filteredServices.length == 0) {
                this.filteredServices =
                    this.$store.state.servicesTemp.servicesTemp.filter(
                        (service) =>
                            service.title
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                    );
            }

            if (this.searchText !== this.prevSearchText) {
                this.prevSearchText = this.searchText;
                this.filteredServices =
                    this.$store.state.servicesTemp.servicesTemp.filter(
                        (service) =>
                            service.title
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                    );
            }

            if (this.filteredServicesIndex + 1 > this.filteredServices.length) {
                this.filteredServicesIndex = 0;
            }

            if (
                this.filteredServices[this.filteredServicesIndex] &&
                this.filteredServices[this.filteredServicesIndex].start
            ) {
                this.goToDate(
                    this.filteredServices[this.filteredServicesIndex].start
                );
            }

            this.filteredServicesIndex++;
        },
        clearSearchServices() {
            this.searchText = "";
            this.prevSearchText = "";
            this.filteredServices = [];
            this.filteredServicesIndex = 0;
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.unselect();
            this.goToDate(moment().format("YYYY-MM-DD"));
        },
        goToDate(date) {
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.gotoDate(date);
            calendarApi.select(date);
            // var dateString = moment(date).format('YYYY-MM-DD');

            // calendarApi.find('.fc-day-number[data-date="' + dateString + '"]').css('background-color', '#FAA732');
        },
        changeDayBackground(date) {
            var calendarDate = document.querySelectorAll(
                `[data-date="${date}"]`
            );

            for (var item of calendarDate) {
                item.children[0].style.background = "#21C55D";
            }
        },
        handleDateCLick: function (info) {
            var clickedDate = moment(info.date);

            /** No permitir crear servicios sábados ni domingos */
            if (
                clickedDate.isoWeekday() == 6 ||
                clickedDate.isoWeekday() == 7
            ) {
                let popupPayload = {
                    appears: true,
                    message:
                        "No se pueden crear servicios los fines de semana.",
                    type: "info",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
            } else {
                let serviceTitle = prompt("Crear servicio");

                if (serviceTitle === "" || serviceTitle === null) {
                    return;
                }

                const serviceTemp = [
                    {
                        title: serviceTitle,
                        date: info.dateStr,
                        status: "sin-agenda",
                    },
                ];

                let popupPayload = {
                    appears: true,
                    message: `Creando el servicio ${serviceTitle}`,
                    type: "loading",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });

                this.storeServiceTemp(serviceTemp)

                    .then((response) => {
                        let popupPayload = {
                            appears: true,
                            message: `Se creó el servicio ${serviceTitle} correctamente.`,
                            type: "success",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    })
                    .catch((error) => {
                        let popupPayload = {
                            appears: true,
                            message: `Hubo un error al intentar crear el servicio ${serviceTitle}.`,
                            type: "error",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    });
            }
        },
        handleEventClick(event) {
            var service = null;
            const serviceOld = this.$store.state.servicesTemp.servicesTemp.find(
                (serviceTemp) => serviceTemp.id == event.event.id
            );

            if (serviceOld) {
                service = serviceOld;
                this.serviceData.id = serviceOld.id;
                this.serviceData.title = serviceOld.title;
                this.serviceData.date = serviceOld.start;
                this.serviceData.status = serviceOld.status;
                console.log(this.serviceData);
                this.$refs.modificarServicio.openModal();
            } else {
                service = this.services.find(
                    (service) => service.id == event.event.id
                );

                this.serviceData.id = service.id;
                this.serviceData.title = service.title;
                this.serviceData.date = service.start;
                this.serviceData.status = service.status;

                console.log(this.serviceData);

                this.$refs.updateService.openModal();
            }
        },
        deleteServiceTempBtn(serviceTemp) {
            this.deleteServiceTemp(serviceTemp.id)
                .then((response) => {
                    let popupPayload = {
                        appears: true,
                        message: `Se eliminó el servicio ${serviceTemp.title} correctamente.`,
                        type: "success",
                    };
                    this.$store.commit(
                        "miscelaneous/changePopup",
                        popupPayload,
                        { root: true }
                    );
                    this.$refs.modificarServicio.closeModal();
                })
                .catch((error) => {
                    let popupPayload = {
                        appears: true,
                        message: `Hubo un error al intentar eliminar el servicio ${serviceTemp.title}.`,
                        type: "error",
                    };
                    this.$store.commit(
                        "miscelaneous/changePopup",
                        popupPayload,
                        { root: true }
                    );
                    this.$refs.modificarServicio.closeModal();
                });
        },
        deleteService(service) {
            axios
                .delete(`v2/services/${service.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " + this.$store.getters["user/getToken"],
                    },
                })
                .then((response) => {
                    this.getServices();
                    this.$refs.fullCalendar.$emit("rerender-events");
                    this.$refs.updateService.closeModal();

                    let popupPayload = {
                        appears: true,
                        message: `Se eliminó el servicio ${service.title} correctamente.`,
                        type: "success",
                    };
                    this.$store.commit(
                        "miscelaneous/changePopup",
                        popupPayload,
                        { root: true }
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateServiceTempBtn(serviceData) {
            if (
                typeof serviceData.id == "string" &&
                serviceData.id.includes("-")
            ) {
                const data = {};
                data.name = serviceData.title;
                data.date = serviceData.date;

                switch (serviceData.status) {
                    case "reservado":
                        data.confirmed = false;
                        data.agenda = false;
                        data.done = false;
                        break;
                    case "confirmado":
                        data.confirmed = true;
                        data.agenda = false;
                        data.done = false;
                        break;
                    case "con-agenda":
                        data.confirmed = true;
                        data.agenda = true;
                        data.done = false;
                        break;

                    case "realizado":
                        data.confirmed = true;
                        data.agenda = true;
                        data.done = true;
                        break;
                }

                axios
                    .put(`v2/services/${serviceData.id}`, data, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    })
                    .then((res) => {
                        // this.$refs.fullCalendar.$emit("rerender-events");
                        this.getServices();
                        this.$refs.updateService.closeModal();
                        let popupPayload = {
                            appears: true,
                            message: `Se modifició el servicio ${serviceData.title} correctamente.`,
                            type: "success",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
            } else {
                let formData = new FormData(
                    document.getElementById("updateServiceTempForm")
                );
                let popupPayload = {
                    appears: true,
                    message: `Modificando el servicio.`,
                    type: "loading",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                this.$refs.modificarServicio.closeModal();
                this.updateServiceTemp({ serviceData, formData })
                    .then(() => {
                        this.$refs.fullCalendar.$emit("rerender-events");
                        let popupPayload = {
                            appears: true,
                            message: `Se modifició el servicio ${serviceData.title} correctamente.`,
                            type: "success",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                        let popupPayload = {
                            appears: true,
                            message: `Hubo un error intentando modificar el servicio.`,
                            type: "error",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    });
            }
        },
        queryAvailabilitesFn() {
            this.clearErrorsQueryAvailabilityForm();

            if (this.queryAvailabilityForm.norm_id) {
                var norm_id = this.queryAvailabilityForm.norm_id;
            }

            if (this.queryAvailabilityForm.accreditation_id) {
                var accreditation_id =
                    this.queryAvailabilityForm.accreditation_id;
            }

            var eac_id = this.queryAvailabilityForm.eac_id;

            this.$axios
                .get(
                    "auditors-availabilities?norm_id=" +
                        norm_id +
                        "&accreditation_id=" +
                        accreditation_id +
                        "&eac_id=" +
                        eac_id
                )
                .then((response) => {
                    let dates = response.data.data;

                    dates.forEach((date) => {
                        this.changeDayBackground(date);
                    });

                    this.$refs.queryAvailability.closeModal();
                })
                .catch((error) => {
                    this.alert.message = error.response.data.errors;
                    this.queryAvailabilityForm.errors.exists = true;
                    this.queryAvailabilityForm.errors.errors =
                        error.response.data.errors;

                    if (error.response.status > 400) {
                        this.queryAvailabilityForm.errors.type = "danger";
                    }
                });
        },
        clearErrorsQueryAvailabilityForm() {
            this.queryAvailabilityForm.errors.exists = false;
            this.queryAvailabilityForm.errors.errors = [];
            this.queryAvailabilityForm.errors.type = "";
        },
        setColorsForService(service) {
            var bgColor, txtColor;

            if (service.done) {
                bgColor = "#9FD356";
                txtColor = "#000045";
            } else {
                if (service.agenda) {
                    bgColor = "#06BAE8";
                    txtColor = "#000045";
                } else {
                    if (service.confirmed) {
                        bgColor = "#4AEFCA";
                        txtColor = "#000045";
                    } else {
                        bgColor = "#FECF56";
                        txtColor = "#000045";
                    }
                }
            }

            return [bgColor, txtColor];
        },
        async getServices() {
            await axios.get("v2/services").then(
                (res) =>
                    (this.services = res.data.map((service) => {
                        let status = "";
                        if (service.done) {
                            status = "realizado";
                        } else {
                            if (service.agenda) {
                                status = "con-agenda";
                            } else {
                                if (service.confirmed) {
                                    status = "confirmado";
                                } else {
                                    status = "reservado";
                                }
                            }
                        }
                        return {
                            ...service,
                            id: service.uuid,
                            title: service.name,
                            start: moment(service.schedules[0].started_at)
                                .add(3, "hours")
                                .format("YYYY-MM-DD"),

                            status: status,
                            backgroundColor:
                                this.setColorsForService(service)[0],
                            borderColor: this.setColorsForService(service)[0],
                            textColor: this.setColorsForService(service)[1],
                        };
                    }))
            );
        },
    },
    async created() {
        // this.getUsers();
        this.storeServicesTempBatch();
        await this.getServices();
    },
};
</script>

<template>
    <div id="HomeCalendar" class="relative">
        <div
            class="flex flex-row justify-center p-4 relative mx-auto lg:top-12"
        >
            <div
                class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvyellow"
            ></div>
            <div class="leading-4 align-middle">Reservado</div>
            <div
                class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvbluelight"
            ></div>
            <div class="leading-4 align-middle">Sin agenda</div>
            <div
                class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvbluemed"
            ></div>
            <div class="leading-4 align-middle">Confirmado</div>
            <div
                class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvgreen"
            ></div>
            <div class="leading-4 align-middle">Realizado</div>
        </div>
        <FullCalendar ref="fullCalendar" :options="config" />

        <modal ref="modificarServicio">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    {{ serviceData.title }}
                </h2>
            </template>

            <template v-slot:body>
                <div>
                    <form
                        @submit.prevent
                        id="updateServiceTempForm"
                        name="updateServiceTempForm"
                    >
                        <div
                            class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs"
                        >
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Estado</label
                                >
                                <select
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="status"
                                    id="status"
                                    type="text"
                                    v-model="serviceData.status"
                                >
                                    <option value="sin-agenda">
                                        Sin Agenda
                                    </option>
                                    <option value="con-agenda">
                                        Con Agenda
                                    </option>
                                    <option value="realizado">Realizado</option>
                                </select>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="title"
                                    >Servicio</label
                                >
                                <input
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="title"
                                    id="title"
                                    type="text"
                                    v-model="serviceData.title"
                                />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="date"
                                    >Fecha</label
                                >
                                <input
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="date"
                                    id="date"
                                    type="date"
                                    v-model="serviceData.date"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-between">
                    <div>
                        <button
                            class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center"
                            @click="updateServiceTempBtn(serviceData)"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-floppy-disk"
                                class="text-xl text-white md:mr-2"
                            />
                            <span class="hidden md:inline-block">Guardar</span>
                        </button>
                    </div>
                    <div class="flex flex-row justify-center">
                        <button
                            class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2 flex flex-row items-center"
                            @click="$refs.modificarServicio.closeModal()"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-xmark"
                                class="text-xl text-slate-600 md:mr-2"
                            />
                            <span class="hidden md:inline-block">Cancelar</span>
                        </button>
                        <button
                            class="bg-rose-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center"
                            @click="deleteServiceTempBtn(serviceData)"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-trash-can"
                                class="text-xl text-white md:mr-2"
                            />
                            <span class="hidden md:inline-block">Eliminar</span>
                        </button>
                    </div>
                </div>
            </template>
        </modal>

        <modal ref="updateService">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    {{ serviceData.name }}
                </h2>
            </template>

            <template v-slot:body>
                <div>
                    <form
                        @submit.prevent
                        id="updateServiceTempForm"
                        name="updateServiceTempForm"
                    >
                        <div
                            class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs"
                        >
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Estado</label
                                >
                                <select
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="status"
                                    id="status"
                                    type="text"
                                    v-model="serviceData.status"
                                >
                                    <option value="reservado">Reservado</option>
                                    <option value="confirmado">
                                        Confirmado
                                    </option>
                                    <option value="con-agenda">
                                        Con Agenda
                                    </option>
                                    <option value="realizado">Realizado</option>
                                </select>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="title"
                                    >Servicio</label
                                >
                                <input
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="title"
                                    id="title"
                                    type="text"
                                    v-model="serviceData.title"
                                />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="date"
                                    >Fecha</label
                                >
                                <input
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="date"
                                    id="date"
                                    type="date"
                                    v-model="serviceData.date"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-between">
                    <div>
                        <button
                            class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center"
                            @click="updateServiceTempBtn(serviceData)"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-floppy-disk"
                                class="text-xl text-white md:mr-2"
                            />
                            <span class="hidden md:inline-block">Guardar</span>
                        </button>
                    </div>
                    <div class="flex flex-row justify-center">
                        <button
                            class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2 flex flex-row items-center"
                            @click="$refs.updateService.closeModal()"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-xmark"
                                class="text-xl text-slate-600 md:mr-2"
                            />
                            <span class="hidden md:inline-block">Cancelar</span>
                        </button>
                        <button
                            class="bg-rose-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center"
                            @click="deleteService(serviceData)"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-trash-can"
                                class="text-xl text-white md:mr-2"
                            />
                            <span class="hidden md:inline-block">Eliminar</span>
                        </button>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>
