<script setup>
import { ref, reactive } from "vue";
import axios from "axios";
import DashboardCard from "./DashboardCard.vue";
import moment from "moment";
import { useUserStore } from "../stores/user";

const user = useUserStore();

const diffDays = 20;

defineProps({
    certificationsWithoutVeto: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(["updateCertificationsWithoutVeto"]);

const canUndo = ref(false);
const certificationUpdated = reactive({
    certificationId: null,
    phaseId: null,
    veto: null,
});

async function updateCertificationPhase(certificationId, phaseId, veto) {
    await axios
        .put(
            `/v2/certifications/${certificationId}/phases/${phaseId}`,
            {
                veto: veto,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.token,
                },
            }
        )
        .then((response) => {
            console.log(response.data);
            if (veto === 1) {
                certificationUpdated.certificationId = certificationId;
                certificationUpdated.phaseId = phaseId;
                certificationUpdated.veto = veto;
                canUndo.value = true;
                setTimeout(() => {
                    canUndo.value = false;
                    certificationUpdated.certificationId = null;
                    certificationUpdated.phaseId = null;
                    certificationUpdated.veto = null;
                }, 6000);
            }
            if (veto === 0) {
                canUndo.value = false;
            }

            emit("updateCertificationsWithoutVeto");
        })
        .catch((error) => {
            console.error(error);
        });
}
</script>

<template>
    <DashboardCard>
        <template #header> Certificaciones sin veto </template>

        <template #subheader>
            Parámetros: {{ diffDays }} días. <br />
            <span>Cantidad: {{ certificationsWithoutVeto.length }}</span>
        </template>

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="certificationsWithoutVeto.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="certification in certificationsWithoutVeto"
                    :key="certification.id"
                >
                    <div class="col-span-2">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ certification.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            {{ certification.norm.norm }} -
                            {{ certification.accreditation.accreditation
                            }}<br />
                            <span class="text-rose-500"
                                >{{
                                    certification
                                        .first_opened_certification_phase
                                        .finished_at
                                }}
                                días desde finalización</span
                            >
                        </p>
                    </div>
                    <div class="col-span-1 flex justify-center">
                        <div
                            class="flex flex-col justify-center items-center w-1/4"
                        >
                            <button
                                class="hover:bg-tuvgreen p-2 rounded-lg bg-tuvgreen/20"
                                @click="
                                    updateCertificationPhase(
                                        certification.id,
                                        certification
                                            .first_opened_certification_phase
                                            .id,
                                        1
                                    )
                                "
                            >
                                Veto realizado
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
            <div
                class="bg-tuvyellow/40 p-2 rounded-lg relative"
                :class="{ hidden: !canUndo }"
            >
                <div
                    class="absolute h-full rounded-lg top-0 left-0 bg-tuvyellow z-0"
                    :class="{ 'expand-animation': canUndo }"
                ></div>
                <div class="relative flex gap-2 z-10">
                    <span>Etapa actualizada.</span>
                    <button
                        class="underline"
                        @click="
                            updateCertificationPhase(
                                certificationUpdated.certificationId,
                                certificationUpdated.phaseId,
                                0
                            )
                        "
                    >
                        Deshacer
                    </button>
                </div>
            </div>
        </template>
    </DashboardCard>
</template>

<style scoped>
@keyframes expandWidth {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.expand-animation {
    animation: expandWidth 5s linear;
}
</style>
