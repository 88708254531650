<script>
export default {
    name: "ServiceModal",
    data() {
        return {
            show: false
        };
    },
    methods: {
        closeModal() {
            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
        },
        openModal() {
            this.show = true;
            document.querySelector("body").classList.add("overflow-hidden");
        }
    }
};
</script>

<template>
     <transition name="fade">
        <div class="service-modal" v-if="show">
            <div class="service-modal__backdrop" @click="closeModal()"/>
                <div class="service-modal__dialog">
                    <div class="service-modal__header">
                        <slot name="header"/>
                        <button type="button" class="service-modal__close" @click="closeModal()">
                            <i class="far fa-times"></i>
                        </button>
                    </div>

                    <div class="service-modal__body">
                        <slot name="body"/>
                    </div>

                    <div class="service-modal__footer">
                        <slot name="footer"/>
                    </div>
                </div>
            </div>
      </transition>
</template>

<style lang="scss" scoped>
.service-modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    &__backdrop {
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    &__dialog {
        background-color: #ffffff;
        position: relative;
        width: 600px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        z-index: 2;
        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }
    &__close {
        width: 30px;
        height: 30px;
    }
    &__header {
        padding: 20px 20px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        h2 {
          font-size: 2rem;

          i {
            margin-right: 2rem;
          }
        }
    }
    &__body {
        padding: 10px 20px 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    &__footer {
        padding: 10px 20px 20px;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>