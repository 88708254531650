<template>
    <div class="flex w-screen">
        <AppSidebar
            class="w-3/12 max-w-[200px] absolute -left-[200px] lg:fixed lg:-left-0 min-h-screen"
            :class="[menuOpen ? '-left-0' : '-left-[200px]']"
        />

        <main
            id="main"
            class="w-full lg:w-[calc(100vw - 200px)] bg-tuvlightgray block lg:ml-[200px]"
            role="main"
        >
            <AppHeader v-on:open-menu="menuOpen = !menuOpen" />
            <router-view v-slot="{ Component }" class="min-h-screen">
                <transition
                    name="fade"
                    mode="out-in"
                    :duration="{ enter: 100, leave: 100 }"
                >
                    <component :is="Component" :key="$route.path"></component>
                </transition>
            </router-view>
        </main>
    </div>
</template>
<script setup>
import { ref } from "vue";
import AppSidebar from "../components/AppSidebar.vue";
import AppHeader from "../components/AppHeader.vue";

const menuOpen = ref(false);
</script>
