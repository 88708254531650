<script>
import { mapActions } from "vuex";

export default {
    name: "SettingsEac",
    props: {
        eac: {
            required: true,
        },
    },
    data() {
        return {
            editEac: false,
            editDescription: false,
        };
    },
    methods: {
        ...mapActions({
            updateEac: "eacs/updateEac",
            deleteEac: "eacs/deleteEac",
        }),
    },
};
</script>

<template>
    <div
        class="grid grid-cols-4 items-center gap-4 mb-4 py-4 px-2 hover:bg-white"
    >
        <div class="col-span-1">
            <span v-if="editEac">
                <input
                    type="text"
                    v-model="eac.eac"
                    placeholder="Alcance"
                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    @keyup.esc="editEac = false"
                    @keyup.enter="
                        updateEac(eac);
                        editEac = false;
                    "
                />
            </span>
            <span class="h-8" v-else>
                {{ eac.eac }}
                <font-awesome-icon
                    icon="fa-duotone fa-pen-line"
                    class="cursor-pointer"
                    @click="editEac = true"
                ></font-awesome-icon>
            </span>
        </div>
        <div class="col-span-2 text-slate-400" ref="descriptionField">
            <span v-if="editDescription">
                <input
                    type="text"
                    v-model="eac.description"
                    placeholder="Descripción"
                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    @keyup.esc="editDescription = false"
                    @keyup.enter="
                        updateEac(eac);
                        editDescription = false;
                    "
                />
            </span>
            <span class="h-8" v-else>
                <span v-if="eac.description">{{ eac.description }}</span>
                <span v-else>Agregar una descripción</span>
                <font-awesome-icon
                    icon="fa-duotone fa-pen-line"
                    class="cursor-pointer text-slate-600"
                    @click="editDescription = true"
                ></font-awesome-icon>
            </span>
        </div>
        <div class="col-span-1">
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-2 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Eliminar"
                @click="deleteEac(eac)"
            >
                <font-awesome-icon
                    icon="fa-duotone fa-trash-can"
                    class="text-lg text-slate-600"
                />
            </button>
        </div>
    </div>
</template>
