import axios from "axios";

const state = {
    queriedAvailabilities: {}
};

const getters = {
	
};

const mutations = {
    queryAvailabilities(state, payload){
        state.queriedAvailabilities = payload;
    }
};

const actions = {
    queryAvailabilities({commit, rootGetters}, payload){
        return new Promise((resolve, reject) => {
			axios.get('query-availabilities?norm_id=' + payload.norm_id + '&accreditation_id=' + payload.accreditation_id + '&eac_id=' + payload.eac_id, {
				headers: { Authorization: 'Bearer ' + rootGetters['user/getToken'] }
			})
			.then((response) => {
                commit('queryAvailabilities', response.data.data)
				resolve(response.data.data)
			})
			.catch((error) => {
				reject(error.response)
			});
		})
    },
    
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}