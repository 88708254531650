import axios from "axios";

const state = {
	norms: [],
};

const getters = {
	
};

const mutations = {
    storeNorms(state, norms){
        state.norms = norms;
    },
    STORE_NORM(state, payload){
        state.norms.push(payload);

    },
    UPDATE_NORM(state, payload){
        const normIndex = state.norms.findIndex(norm => norm.id == payload.id);
        state.norms.splice(normIndex, 1, payload);
        
    },
    deleteNorm(state, payload){
        const normIndex = state.norms.findIndex(norm => norm.id == payload.id);
        state.norms.splice(normIndex, 1);
    },
};

const actions = {
    storeNorms({ commit, rootGetters }){
        axios.get('norms', {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
            })
            .then((response) => {
                let norms = response.data.data;

                commit('storeNorms', norms)
            })
            .catch((error) => {
                console.log('error en storeNorms (store): ', error.response)
            })
    },
    storeNorm({ commit, rootGetters }, payload) {
        axios.post('norms', {
            norm: payload.norm,
            description: payload.description,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('STORE_NORM', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Norma ${payload.norm} creada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response)
            let popupPayload = {
                appears: true,
                message: `No pudo crearse la norma ${payload.norm}`,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    updateNorm({ commit, rootGetters }, payload) {
        axios.put('norms/' + payload.id, {
            norm: payload.norm,
            description: payload.description,
        }, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('UPDATE_NORM', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Norma ${payload.norm} actualizada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    deleteNorm({ commit, rootGetters }, payload) {
        axios.delete('norms/' + payload.id, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('deleteNorm', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Norma ${payload.norm} eliminada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    storeEacNorm({ commit, rootGetters }, payload) {
        axios.post('norms/' + payload.norm + '/eacs', {
            eac_id: payload.eac,
            risk_id: payload.risk,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('UPDATE_NORM', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Alcance agregado correctamente a la norma`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    deleteEacNorm({ commit, rootGetters }, payload) {
        console.log('deleteEacNorm action: ', payload);
        axios.delete('norms/' + payload.norm_id + '/eacs/' + payload.id, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('UPDATE_NORM', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Alcance eliminado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}