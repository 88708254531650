import axios from "axios";

const state = {
	auditors: [],
};

const getters = {
	auditors: state => state.auditors,
};

const mutations = {
	storeAuditors(state, auditors){
		state.auditors = auditors
	},
    deleteAuditors(state){
		state.auditors = []
	}
};

const actions = {
    // storeAuditors({commit, rootGetters}){
    //     axios.get('users?role=auditor', {
    //             headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
    //         })
    //         .then((response) => {
    //             commit('storeAuditors', response.data.data)
    //         })
    //         .catch((error) => {
    //             console.log('error en storeAuditors (store): ', error.response)
    //         })
	// },
	deleteAuditors({commit}){
		commit('deleteAuditors')
	}
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}