import axios from "axios";

const state = {
    users: {},
};

const getters = {
    totalUsers(state) {
        if (state.users) {
            var auditors = state.users.filter(
                (user) =>
                    user.roles.some((role) => role.role == "auditor") == true
            );
            var operators = state.users.filter(
                (user) =>
                    user.roles.some((role) => role.role == "operador") == true
            );
            return {
                total_users: state.users.length,
                total_auditors: auditors.length,
                total_operators: operators.length,
            };
        }
    },
};

const mutations = {
    /** OK */
    storeUsers(state, users) {
        state.users = users;
    },

    /** Chequear */
    storeUser(state, user) {
        state.users.push(user);
    },
    updateUser(state, user) {
        const userIndex = state.users.findIndex(
            (userFromState) => userFromState.id === user.id
        );
        state.users.splice(userIndex, 1, user);
    },
    deleteUser(state, user) {
        const userIndex = state.users.findIndex(
            (userFromState) => userFromState.id === user.id
        );
        state.users.splice(userIndex, 1);
    },
};

const actions = {
    /** OK */
    getUsers({ commit, rootGetters }) {
        axios
            .get("users", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((response) => {
                commit("storeUsers", response.data.data);
            })
            .catch((error) => {
                console.log("error en getUsers (store): ", error.response);
            });
    },

    /** Chequear */

    storeUser({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "users",
                    {
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        username: payload.username,
                        email: payload.email,
                        password: payload.password,
                        role_id: payload.role_id,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("storeUser", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUser({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    "users/" + payload.user_id,
                    {
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        username: payload.username,
                        email: payload.email,
                        password: payload.password,
                        role_id: payload.role_id,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("updateUser", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    suspendUser({ commit, rootGetters }, user_id) {
        return new Promise((resolve, reject) => {
            axios
                .delete("users/" + user_id, {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                })
                .then((response) => {
                    commit("updateUser", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    restoreUser({ commit, rootGetters }, user_id) {
        return new Promise((resolve, reject) => {
            axios
                .put("users/" + user_id + "/restore", {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                })
                .then((response) => {
                    commit("updateUser", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteUser({ commit, rootGetters }, user_id) {
        return new Promise((resolve, reject) => {
            axios
                .put("users/" + user_id + "/delete", {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                })
                .then((response) => {
                    commit("deleteUser", response.data.data);
                    let popupPayload = {
                        appears: true,
                        message:
                            "Usuario eliminado definitivamente del sistema",
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
