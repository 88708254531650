<script>
import DashboardCard from "./DashboardCard.vue";

export default {
    name: "CardServicesWithoutInvoice",
    components: {
        DashboardCard,
    },
    props: {
        services: {
            required: true,
        },
    },
    methods: {
        goToCertificationDetails(certification_id) {
            this.$router.push({
                name: "CertificationDetails",
                params: { certification_id: certification_id },
            });
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Etapas finalizadas sin facturar </template>

        <template #body>
            <ul class="h-60 overflow-y-auto" v-if="services.length > 0">
                <li
                    class="grid grid-cols-3 gap-x-4 mt-4 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="service in services"
                    :key="service.id"
                    @click="goToCertificationDetails(service.certification_id)"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                            v-if="service.certification"
                        >
                            {{ service.certification.client.account_name }} -
                            {{ service.certification.norm.norm }}
                            {{
                                service.certification.accreditation
                                    .accreditation
                            }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            {{ service.phase.phase_name }}
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
