<script>
import DashboardCard from "./DashboardCard.vue";
import moment from "moment";

export default {
    name: "DashboardClientsWCert",
    components: {
        DashboardCard,
    },
    props: {
        clientsWithoutCertifications: {
            required: true,
        },
    },
    methods: {
        checkIn(client) {
            return moment(client.created_at).format("DD-MM-YYYY");
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Clientes sin certificaciones </template>
        <template #subheader
            >Aquí se listan los clientes activos en Zoho que aún no tienen
            certificaciones cargadas en Cóndor</template
        >

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="clientsWithoutCertifications.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="client in clientsWithoutCertifications"
                    :key="client.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            Ingreso: {{ checkIn(client) }}
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
