<template>
    <div class="flex flex-col lg:flex-row">
        <alert-popup></alert-popup>
        <router-view></router-view>
    </div>
</template>
<script>
import AlertPopup from "./AlertPopup.vue";

export default {
    components: {
        AlertPopup,
    },
};
</script>
