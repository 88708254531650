<script setup>
import axios from "axios";
import { computed, ref, watchEffect } from "vue";
import Invoice from "../components/InvoicesItem.vue";
import PageInvoicesHeader from "../components/PageInvoicesHeader.vue";
import InvoicesItemLoading from "../components/InvoicesItemLoading.vue";

const phases = ref([]);
const invoicesFilter = ref("");
const phasesByClient = ref("");
const loadingPhases = ref(true);
const invoices = ref([]);

watchEffect(() => {
    loadingPhases.value = true;
    phases.value = [];
    axios
        .get("certifications-phases/invoices?filter=" + invoicesFilter.value)
        .then((response) => {
            phases.value = response.data;
        })
        .catch((error) => {
            console.log(error.resposne);
        })
        .finally(() => {
            loadingPhases.value = false;
        });
});

const getInvoices = async function () {
    await axios
        .get("invoices")
        .then((response) => {
            invoices.value = response.data.data;
        })
        .catch((error) => {
            console.log("error invoices", error.response);
        });
};

getInvoices();

const filteredPhases = computed(() => {
    return phases.value.filter((phase) =>
        phase.certification?.client?.account_name
            .toLowerCase()
            .includes(phasesByClient.value.toLowerCase())
    );
});

const updateInvoice = async function (
    phase,
    invoice_id = null,
    invoice_number = 0,
    no_fee = "false"
) {
    await axios
        .post(`certifications-phases/${phase.id}/invoices`, {
            invoice_id,
            no_fee,
            invoice_number,
        })
        .then((response) => {
            console.log(response.data);
            phases.value.forEach((updatedPhase) => {
                if (updatedPhase.id == phase.id) {
                    updatedPhase.nofee = response.data.nofee;
                    updatedPhase.invoice_id = response.data.invoice_id;
                    updatedPhase.invoice = response.data.invoice;
                }
            });
            getInvoices();
        })
        .catch((error) => {
            console.log(error);
        });
};
</script>

<template>
    <div class="mb-16 mx-8">
        <PageInvoicesHeader />

        <div class="mb-12">
            <div class="flex gap-4">
                <select
                    v-model="invoicesFilter"
                    class="text-sm py-1 pl-4 pr-8 h-10 border-zinc-300 rounded cursor-pointer"
                >
                    <option value="">Todos los servicios</option>
                    <option value="with">Servicios con factura</option>
                    <option value="without">Servicios sin factura</option>
                    <option value="free">Servicios bonificados</option>
                </select>
                <input
                    class="text-sm py-2 px-4 h-10 border-zinc-300 w-1/3 rounded placeholder:text-zinc-400 placeholder:italic placeholder:text-xs"
                    type="text"
                    v-model="phasesByClient"
                    @keyup.esc="phasesByClient = ''"
                    placeholder="Buscar cliente..."
                />
            </div>
            <table
                class="table w-full border-collapse border-none rounded text-sm"
            >
                <thead class="rounded-t table-row-group">
                    <tr class="table-row">
                        <th
                            class="table-cell font-bold w-4/12 border-none text-neutral-600 text-left p-2"
                        >
                            Cliente
                        </th>
                        <th
                            class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                        >
                            Norma / Acreditación
                        </th>

                        <th
                            class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                        >
                            Servicio
                        </th>
                        <th
                            class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                        >
                            Factura
                        </th>
                        <th
                            class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                        >
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody class="table-row-group" v-if="loadingPhases">
                    <InvoicesItemLoading />
                    <InvoicesItemLoading />
                    <InvoicesItemLoading />
                    <InvoicesItemLoading />
                </tbody>
                <tbody
                    class="table-row-group"
                    v-if="loadingPhases == 'success' && invoices?.length == 0"
                >
                    <span>No hay servicios para mostrar</span>
                </tbody>
                <tbody class="table-row-group" v-if="invoices?.length > 0">
                    <invoice
                        v-for="phase in filteredPhases"
                        @load-invoices="loadingPhases"
                        :key="phase.id"
                        :phase="phase"
                        :invoices="invoices"
                        @update-invoice="updateInvoice"
                    ></invoice>
                </tbody>
            </table>
        </div>
    </div>
</template>
