<script>
export default {
    name: "BadgeStatus",
    props: {
        status: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<template>
    <div
        class="inline-block text-xs py-1 px-4 rounded"
        :class="[
            status ? ['bg-tuvgreen text-tuvblack'] : ['bg-tuvred text-white'],
        ]"
    >
        <slot class="text-white text-xs">Sin valor</slot>
    </div>
</template>
