// import Vue from 'vue'
// import Vuex from 'vuex'
import { createStore } from "vuex";
import axios from "axios";
// import VuexORM from '@vuex-orm/core'
import miscelaneous from "./modules/miscelaneous/miscelaneous";
import user from "./modules/user";
import auditors from "./modules/users/auditors";
import users from "./modules/users/users";
import auditss from "./modules/events/auditss";
import clients from "./modules/clients/clients";
import certifications from "./modules/certifications/certifications";
import norms from "./modules/certifications/norms";
import accreditations from "./modules/certifications/accreditations";
import eacs from "./modules/certifications/eacs";
import risks from "./modules/certifications/risks";
import invoices from "./modules/certifications/invoices";
import operationsCalendar from "./modules/calendars/operationsCalendar";

// //Vuex models
// import Person from './models/Persona';
// import User from './models/User';
// import Role from './models/Role';
// import RoleUser from './models/RoleUser';

// import Norm from './models/Norms/Norm';
// import Accreditation from './models/Norms/Accreditation';
// import Eac from './models/Norms/Eac';
// import Risk from './models/Norms/Risk';
// import EacNorm from './models/Norms/EacNorm';

// eliminar una vez finalizada la conversion
import availabilities from "./modules/events/availabilities";

// import persona from './modules/persona';

import servicesTemp from "./modules/services/servicesTemp";

// Vue.use(Vuex);

// const database = new VuexORM.Database();

// database.register(Person);
// database.register(User);
// database.register(Role);
// database.register(RoleUser);

// database.register(Norm, norms);
// database.register(Accreditation, accreditations);
// database.register(Eac, eacs);
// database.register(Risk);
// database.register(EacNorm);

export const store = createStore({
    // plugins: [VuexORM.install(database)],
    modules: {
        miscelaneous,

        user,
        users,
        auditors,
        auditss,
        availabilities,
        servicesTemp,
        clients,
        certifications,
        norms,
        accreditations,
        eacs,
        risks,
        invoices,
        operationsCalendar,
        // eliminar

        // auditsCalen,
    },
});
