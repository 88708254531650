<script setup>
import { onMounted, ref, reactive, defineAsyncComponent } from "vue";
import axios from "axios";
import { useUserStore } from "../stores/user";
import AkademieCourseListItemLoading from "../components/AkademieCourseListItemLoading.vue";
import AppButtonMain from "../components/AppButtonMain.vue";
import { useToast } from "vue-toastification";

const useUser = useUserStore();
const courses = ref([]);
const loadingCourses = ref(false);
const toast = useToast();

const CourseItem = defineAsyncComponent({
    loader: () => import("../components/AkademieCourseListItem.vue"),
    loadingComponent: AkademieCourseListItemLoading,
});

const newCourseData = reactive({
    data: {
        attributes: {
            nameEs: "",
            nameEn: "",
            place: "",
            workload: "",
            startedAt: "",
            finishedAt: "",
            courseTypeId: 1,
        },
    },
});

async function storeNewCourse() {
    await axios
        .post("v2/courses", newCourseData, {
            headers: {
                Authorization: `Bearer ${useUser.token}`,
            },
        })
        .then((response) => {
            loadCourses();
            toast.success("Curso creado correctamente", {
                timeout: 3000,
            });
        })
        .catch((error) => {
            console.log("error", error.response);
        });
}

async function loadCourses() {
    loadingCourses.value = true;

    axios
        .get("v2/courses", {
            headers: {
                Authorization: `Bearer ${useUser.token}`,
            },
        })
        .then((response) => {
            courses.value = response.data.data;
        })
        .finally(() => {
            loadingCourses.value = false;
        });
}

onMounted(() => {
    loadCourses();
});
</script>

<template>
    <div>
        <header class="flex justify-between items-center mb-2">
            <h2 class="text-lg">Cursos</h2>
            <a
                href="https://tuv-nord.com.ar/akademie_files/akademie_diplomas.xlsx"
                >Descargar modelo</a
            >
            <div class="flex gap-4">
                <v-dialog max-width="700">
                    <template v-slot:activator="{ props: activatorProps }">
                        <AppButtonMain
                            class="bg-slate-500 border-slate-500 px-1"
                            v-bind="activatorProps"
                            >¿Cómo se utiliza?
                        </AppButtonMain>
                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card title="¿Cómo se utiliza?">
                            <v-card-text>
                                <ul class="list-disc *:mb-2">
                                    <li>
                                        Ir a "Crear curso" en la esquina
                                        superior derecha
                                    </li>
                                    <li>
                                        Ingresar los datos solicitados. Estos
                                        datos servirán para crear el curso en la
                                        base de datos, y son los datos comunes a
                                        todos los certificados a enviar.
                                    </li>
                                    <li>
                                        Una vez creado el curso, descargar el
                                        archivo xlsx en el link "Descargar
                                        modelo".
                                    </li>
                                    <li>
                                        Completar las columnas con la
                                        información solicitada en la cabecera de
                                        cada columna. Este archivo corresponde a
                                        la información particular de cada uno de
                                        los certificados.
                                    </li>
                                    <li>
                                        Una vez guardado como archivo Excel
                                        (extensión xlsx), seleccionar el archivo
                                        en el modal que aparece al clickear
                                        "Enviar certificados" en la fila del
                                        curso creado.
                                    </li>
                                    <li>Subir el archivo Excel</li>
                                    <li>
                                        Al confirmar, los certificados serán
                                        enviados por email a cada uno de los
                                        participantes ingresados en el Excel.
                                    </li>
                                </ul>
                            </v-card-text>

                            <!-- <v-card-actions>
                            <v-spacer></v-spacer>
                            <AppButtonMain @click="isActive.value = false"
                                >Crear curso</AppButtonMain
                            >
                        </v-card-actions> -->
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="700">
                    <template v-slot:activator="{ props: activatorProps }">
                        <AppButtonMain v-bind="activatorProps"
                            >Crear curso</AppButtonMain
                        >
                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card title="Crear curso">
                            <v-card-text>
                                <form @submit.prevent="storeNewCourse">
                                    <div class="flex gap-4">
                                        <div class="flex-1">
                                            <label for="nameEs" class="text-sm"
                                                >Nombre en español</label
                                            >
                                            <input
                                                type="text"
                                                id="nameEs"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.nameEs
                                                "
                                                class="w-full placeholder:text-xs"
                                                placeholder="Nombre del curso en español"
                                            />
                                        </div>
                                        <div class="flex-1">
                                            <label for="nameEn" class="text-sm"
                                                >Nombre en inglés</label
                                            >
                                            <input
                                                type="text"
                                                id="nameEn"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.nameEn
                                                "
                                                class="w-full placeholder:text-xs"
                                                placeholder="Nombre del curso en inglés"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex gap-4">
                                        <div class="flex-1">
                                            <label for="place" class="text-sm"
                                                >Lugar del curso</label
                                            >
                                            <input
                                                type="text"
                                                id="place"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.place
                                                "
                                                class="w-full placeholder:text-xs"
                                                placeholder="Lugar del curso"
                                            />
                                        </div>
                                        <div class="flex-1">
                                            <label
                                                for="workload"
                                                class="text-sm"
                                                >Carga horaria</label
                                            >
                                            <input
                                                type="text"
                                                id="workload"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.workload
                                                "
                                                class="w-full placeholder:text-xs"
                                                placeholder="Carga horaria"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex gap-4">
                                        <div class="flex-1">
                                            <label
                                                for="startedAt"
                                                class="text-sm"
                                                >Fecha de inicio</label
                                            >
                                            <input
                                                type="date"
                                                id="startedAt"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.startedAt
                                                "
                                                class="w-full placeholder:text-xs"
                                            />
                                        </div>
                                        <div class="flex-1">
                                            <label
                                                for="finishedAt"
                                                class="text-sm"
                                                >Fecha de finalización</label
                                            >
                                            <input
                                                type="date"
                                                id="finishedAt"
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.finishedAt
                                                "
                                                class="w-full placeholder:text-xs"
                                            />
                                        </div>
                                        <div class="flex-1">
                                            <label
                                                for="courseTypeId"
                                                class="text-sm"
                                                >Tipo de curso</label
                                            >
                                            <select
                                                v-model="
                                                    newCourseData.data
                                                        .attributes.courseTypeId
                                                "
                                                id="courseTypeId"
                                                class="w-full placeholder:text-xs"
                                            >
                                                <option value="1">Curso</option>
                                                <option value="2">
                                                    Webinar
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <AppButtonMain
                                        type="submit"
                                        @click="isActive.value = false"
                                        >Crear curso</AppButtonMain
                                    >
                                </form>
                            </v-card-text>

                            <!-- <v-card-actions>
                            <v-spacer></v-spacer>
                            <AppButtonMain @click="isActive.value = false"
                                >Crear curso</AppButtonMain
                            >
                        </v-card-actions> -->
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </header>
        <section class="text-sm">
            <div class="grid" v-if="loadingCourses">
                <AkademieCourseListItemLoading />
                <AkademieCourseListItemLoading />
                <AkademieCourseListItemLoading />
                <AkademieCourseListItemLoading />
                <AkademieCourseListItemLoading />
            </div>
            <div class="grid" v-else>
                <CourseItem
                    v-for="course in courses"
                    :key="course.id"
                    :course="course"
                />
            </div>
        </section>
    </div>
</template>
