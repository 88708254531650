<script>
import moment from "moment";
import DashboardAuditorsGraphMdsForecast from "./DashboardAuditorsGraphMdsForecast.vue";
import { mapState } from "pinia";
import { useUsersStore } from "../stores/users";
import axios from "axios";
import { toValue } from "@vueuse/core";

export default {
    setup() {
        const users = useUsersStore();

        return { users };
    },
    name: "DashboardAuditorsMdsForecast",
    components: {
        DashboardAuditorsGraphMdsForecast,
    },
    data() {
        return {
            height: 300,
            selectedUser: {
                user_id: "",
                user_name: "todos los auditores",
            },
            loadingUserData: false,
            selectedData: {},

            dataFromAPI: [],
            chartOptions: {
                legend: {
                    display: true,
                },
                tooltips: {
                    enabled: false,
                },
                hover: {
                    animationDuration: 0,
                },
                scales: {
                    xAxes: [
                        {
                            barThickness: 20,
                            maxBarThickness: 20,
                        },
                    ],
                },
                padding: {
                    left: 20,
                    right: 20,
                    top: 30,
                    bottom: 30,
                },
                responsive: true,
                animation: {
                    duration: 1,
                },
                plugins: {
                    datalabels: {
                        color: "#475569",
                        anchor: "end",
                        align: "end",
                        font: {
                            size: 12,
                        },
                    },
                },
            },
        };
    },
    async mounted() {
        this.getManDaysTotals();
    },
    methods: {
        async getManDaysTotals() {
            this.loadingUserData = true;
            await axios
                .get(
                    "dashboard/man-days-forecast?user_id=" +
                        this.selectedUser.user_id,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    this.selectedData = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response);
                })
                .finally(() => {
                    this.loadingUserData = false;
                });
        },
    },
    computed: {
        ...mapState(useUsersStore, ["users"]),
        auditorsList() {
            var auditors = this.users.users.filter(({ roles }) =>
                roles.some((role) => role.role == "auditor")
            );
            auditors.sort((a, b) =>
                a.persona.last_name > b.persona.last_name ? 1 : -1
            );
            return auditors;
        },
    },
};
</script>

<template>
    <div class="bg-slate-50 py-8 px-4 max-h-fit">
        <div class="flex items-center justify-between gap-4">
            <div>
                <h2 class="text-lg text-slate-600">
                    Forecast MDs 3 años vista: auditores
                </h2>
                <p
                    class="text-slate-400 h-9 min-h-fit max-w-xl"
                    v-if="selectedUser.user_id != ''"
                >
                    Cantidad de man days a realizarse por mes según la fecha de
                    cada etapa de las certificaciones vigentes considerando al
                    auditor seleccionado como el único disponible en el equipo
                    auditor.
                </p>
                <p class="text-slate-400 h-9 min-h-fit" v-else>
                    Cantidad de man days a realizarse por mes según la fecha de
                    cada etapa de las certificaciones vigentes.
                </p>
            </div>

            <div>
                <select
                    class="rounded-none border border-tuvdarkblue text-sm p-2 mb-0"
                    name="selectedUser"
                    id="selectedUser"
                    v-model="selectedUser.user_id"
                    @change="getManDaysTotals"
                >
                    <option selected="true" value="">
                        Todos los auditores
                    </option>
                    <option
                        v-for="user in auditorsList"
                        :key="user.id"
                        :value="user.id"
                    >
                        {{ user.persona.last_name }},
                        {{ user.persona.first_name }}
                    </option>
                </select>
                <span v-if="loadingUserData"
                    ><font-awesome-icon
                        icon="fa-duotone fa-spinner-third"
                        class="fa-spin fa-2x"
                /></span>
            </div>
        </div>

        <DashboardAuditorsGraphMdsForecast
            :options="chartOptions"
            :selectedData="selectedData"
            ref="barChartMDs"
            :height="100"
            v-if="dataFromAPI"
        >
        </DashboardAuditorsGraphMdsForecast>
        <div v-else>Cargando información...</div>
    </div>
</template>
