<script setup>
// import GraphNormProportion from './GraphNormProportion.vue';
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { onMounted, ref } from "vue";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const dataLoaded = ref(false);
const clientsCount = ref(null);
const certificationsPerClientCount = ref({});
const clients = ref([]);
const data = ref({
    labels: [],
    datasets: [
        {
            backgroundColor: [],
            data: [],
            borderWidth: 0,
        },
    ],
});

onMounted(() => {
    axios
        .get("dashboard/certifications-per-client")
        .then((response) => {
            dataLoaded.value = true;
            clients.value = response.data.clients;
            clientsCount.value = response.data.clientsCount;
            certificationsPerClientCount.value =
                response.data.certificationsPerClientCount;

            var colors = [
                "#F24B43",
                "#3245D3",
                "#07BAE8",
                "#4AEFCA",
                "#9FD356",
            ];

            var labels = [];
            var dataTemp = [];

            for (const [key, value] of Object.entries(
                certificationsPerClientCount.value
            )) {
                let percentage = Math.round((value * 100) / clientsCount.value);
                labels.push(`Clientes con ${key} cert (${percentage}%)`);
                dataTemp.push(`${value}`);
            }

            data.value.labels = labels;
            data.value.datasets[0].backgroundColor = colors;
            data.value.datasets[0].data = dataTemp;
        })
        .catch((error) => console.log(error.response));
});

const chartOptions = ref({
    tooltips: {
        enabled: true,
    },
    hover: {
        animationDuration: 0,
    },
    height: 100,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
        },
    },

    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 1,
    },
    plugins: {
        datalabels: {
            color: "#333333",
            anchor: "end",
            align: "end",
            display: "auto",
            formatter: function (value, context) {
                return context.chart.data.labels[context.dataIndex];
                // +
                // " (" +
                // value +
                // ")"
            },
        },
        legend: {
            display: true,
            position: "bottom",
            align: "center",
        },
    },
});

const badgeColor = function (value) {
    if (value == 0) {
        return "bg-tuvred text-white";
    }
    if (value == 1) {
        return "bg-tuvblue text-white";
    }
    if (value == 2) {
        return "bg-tuvbluemed text-black";
    }
    if (value == 3) {
        return "bg-tuvbluelight text-tuvblack";
    }
    if (value == 4) {
        return "bg-tuvgreen text-tuvblack";
    }
};
</script>

<template>
    <div
        class="bg-slate-100 py-8 px-4 rounded-xl h-96 shadow-2xl shadow-slate-500/25"
    >
        <h2 class="text-lg font-bold text-slate-600">
            Certificaciones por cliente
        </h2>
        <div class="flex">
            <div class="flex-1 overflow-y-scroll h-72">
                <div
                    v-for="client in clients"
                    :key="client.id"
                    class="flex gap-2 my-2"
                >
                    <span class="max-w-[60%]">{{ client.account_name }}</span
                    ><span
                        class="py-1 px-2"
                        :class="badgeColor(client.certifications_count)"
                        >{{ client.certifications_count }} certificaciones</span
                    >
                </div>
            </div>
            <div class="flex-1" v-if="dataLoaded">
                <Pie
                    :options="chartOptions"
                    :data="data"
                    :height="300"
                    ref="barChartCertPerClient"
                    style="height: 300px"
                />
            </div>
        </div>
    </div>
</template>
