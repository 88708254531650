<template>
    <div class="h-12 bg-white flex justify-between lg:justify-end py-2 px-4">
        <AppLogo @click="$emit('openMenu')" class="lg:hidden" />
        <button
            class="flex flex-row items-center gap-1 text-sm"
            @click="onLogout"
        >
            <font-awesome-icon :icon="['fal', 'arrow-right-from-bracket']" />
            <span>Salir</span>
        </button>
    </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useUserStore } from "../stores/user";
import axios from "axios";
import AppLogo from "./AppLogo.vue";

const user = useUserStore();

const router = useRouter();

defineEmits(["openMenu"]);
const onLogout = () => {
    axios
        .post(
            "logout",
            {},
            {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            }
        )
        .then((response) => {
            user.$reset();
            router.push({
                name: "login",
            });
        })
        .catch((error) => {
            console.log(error.response);
        });
};
</script>
