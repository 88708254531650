<template>
    <div
        class="rounded p-2 text-xs block text-center text-white"
        :class="phaseStyles"
    >
        {{ phase.phase_name }}
    </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
    phase: {
        type: Object,
        required: true,
    },
});

const phaseStyles = computed(() => {
    switch (props.phase.id) {
        case 1:
            return "bg-blue-500";
            break;
        case 2:
            return "bg-indigo-500";
            break;
        case 3:
            return "bg-sky-500";
            break;
        case 4:
            return "bg-violet-500";
            break;
        default:
            return "bg-blue-500";
            break;
    }
});
</script>
