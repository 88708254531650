<script setup>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AppButton from "../components/AppButton.vue";
import AppAlert from "../components/AppAlert.vue";
import { mapState, mapActions } from "vuex";
import PageLoginLogos from "../components/PageLoginLogos.vue";
import axios from "axios";
import { useUserStore } from "../stores/user";
import { useRouter } from "vue-router";

const user = useUserStore();

const alert = ref({
    type: "",
    message: "",
});

const loginStatus = ref("");

const loginFormData = ref({
    username: "",
    password: "",
});

const rules = {
    username: { required },
    password: { required },
};
const $v = useVuelidate(rules, loginFormData);

const onLogin = async function () {
    loginStatus.value = "Cargando información";
    const validation = await $v.value.$validate();
    alert.type = "";
    alert.message = "";

    if (validation) {
        await axios
            .post("login", {
                username: loginFormData.value.username,
                password: loginFormData.value.password,
            })
            .then((response) => {
                const loginResponse = response.data;
                user.token = loginResponse.access_token;
                onUserData();
            })
            .catch((error) => {
                if ("401" == error.response.status) {
                    alert.value.type = "error";
                    alert.value.message =
                        "Las credenciales proporcionadas no son correctas";
                }
            })
            .finally(() => (loginStatus.value = ""));
    } else {
        alert.value.type = "warning";
        alert.value.message = "Por favor corrija los errores a continuación";
        loginStatus.value = "";
    }
};

const router = useRouter();

const onUserData = async function () {
    await axios
        .get("me", {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        })
        .then((response) => {
            const userResponse = response.data;
            user.$patch({
                id: userResponse.id,
                email: userResponse.email,
                documents_check: userResponse.documents_check,
                first_name: userResponse.persona.first_name,
                last_name: userResponse.persona.last_name,
                roles: userResponse.roles,
                norms: userResponse.userNorms,
                isLoggedIn: true,
            });
            router.push({
                name: "home",
            });
        })
        .catch((error) => {
            alert.value.type = "error";
            alert.value.message =
                "Hubo un error intentando recuperar los datos.";
            user.$reset();
        });
};
//         //                     switch (
//         //                         this.$store.getters["user/getUserRoleByName"]
//         //                     ) {
//         //                         case "operador":
//         //                             this.$router.push({
//         //                                 name: "OperadorInicio",
//         //                             });
//         //                             break;
//         //                         case "auditor":
//         //                             this.$router.push({
//         //                                 name: "AuditorInicio",
//         //                             });
//         //                             break;
//         //                         default:
//         //                             this.alert.message =
//         //                                 "Hubo un error al intentar recuperar tus datos. Por favor contactate con el administrador.";
//         //                             this.alert.type = "bg-rose-500 text-white";
//         //                             break;
//         //                     }
//         //                 })
//         //                 .catch((error) => {
//         //                     console.log(error);
//         //                     this.alert.message =
//         //                         "Hubo un error al intentar recuperar tus datos. Por favor contactate con el administrador.";
//         //                     this.alert.type = "bg-rose-500 text-white";
//         //                     this.loginStatus = "";
//         //                 });
//         //         })
//         //         .catch((error) => {
//         //             if (error.response.status >= 500) {
//         //                 this.alert.message =
//         //                     "Hubo un error en la aplicación. Por favor vuela a intentar en unos minutos";
//         //                 this.alert.type = "bg-rose-500 text-white";
//         //                 this.loginStatus = "";
//         //             } else {
//         //                 this.alert.message =
//         //                     "El usuario o contraseña no son correctos";
//         //                 this.alert.type = "bg-rose-500 text-white";
//         //                 this.loginStatus = "";
//         //             }
//         //         });
//         // },
//     },
// };
</script>
<template>
    <div class="relative max-w-md mx-auto mt-8 lg:my-0 p-8 bg-tuvlightgray">
        <PageLoginLogos />
        <AppAlert v-if="alert.type" :type="alert.type">{{
            alert.message
        }}</AppAlert>

        <form
            @submit.prevent="onLogin"
            id="loginForm"
            class="vertical-form login__form"
        >
            <div class="email-input__container relative">
                <input
                    class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray pl-10 self-center w-full mb-0 outline-none my-2 autofill:bg-tuvgray"
                    v-model="loginFormData.username"
                    type="text"
                    placeholder="Usuario"
                    autofocus
                    autocomplete="username"
                    ref="input_username"
                />
                <font-awesome-icon
                    icon="fa-duotone fa-user"
                    class="text-lg absolute left-3 top-5 text-tuvdarkblue"
                />
                <span class="text-tuvred pt-1 block" v-if="$v.username.$error"
                    >El usuario es requerido</span
                >
            </div>
            <div class="password-input__container relative">
                <input
                    class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray pl-10 self-center w-full mb-0 outline-none focus:outline-none my-2 autofill:bg-tuvgray"
                    v-model="loginFormData.password"
                    id="passwordInput"
                    type="password"
                    placeholder="Contraseña"
                    autocomplete="current-password"
                />
                <font-awesome-icon
                    icon="fa-duotone fa-lock"
                    class="text-lg absolute left-3 top-5 text-slate-800"
                />
                <span class="text-tuvred pt-1 block" v-if="$v.password.$error"
                    >La contraseña es requerida</span
                >
            </div>

            <AppButton
                class="bg-tuvblue hover:bg-tuvdarkblue transition-colors duration-300 text-white"
            >
                <span
                    v-if="loginStatus"
                    class="flex flex-row items-center justify-center"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-spinner-third"
                        class="fa-spin w-8 text-xl text-white mr-2"
                    />
                    {{ loginStatus }}
                </span>
                <span
                    class="flex flex-row w-full items-center justify-center"
                    v-else
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-right-to-bracket"
                        class="text-xl text-white mr-2"
                    />
                    Ingresar
                </span>
            </AppButton>
        </form>
        <div class="text-center mt-8">
            <p>
                ¿Olvidó su usuario o contraseña?<br />Por favor póngase en
                contacto con el administrador del sitio Matías Maceratesi
            </p>
            <div class="flex flex-row justify-evenly my-8">
                <a
                    href="tel:+5491169968769"
                    class="text-tuvblue uppercase no-underline"
                    >Llamar</a
                >
                <a
                    href="mailto:mmaceratesi@tuv-nord.com"
                    class="text-tuvblue uppercase no-underline"
                    >Escribir</a
                >
            </div>
        </div>
    </div>
</template>
