import axios from "axios";
import { keyBy } from "lodash";


const state = {
	eacs: [],
};

const getters = {
	
};

const mutations = {
    storeEacs(state, eacs){
        state.eacs = eacs
    },
    storeEac(state, payload){
        state.eacs.push(payload);

    },
    updateEac(state, payload){
        const eacIndex = state.eacs.findIndex(eac => eac.id == payload.id);
        state.eacs.splice(eacIndex, 1, payload);
        
    },
    deleteEac(state, payload){
        const eacIndex = state.eacs.findIndex(eac => eac.id == payload.id);
        state.eacs.splice(eacIndex, 1);
    },
};

const actions = {
    storeEacs({ commit, rootGetters }){
        axios.get('eacs', {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
            })
            .then((response) => {
                commit('storeEacs', response.data.data);
                
            })
            .catch((error) => {
                console.log('error en storeEacs (store): ', error.response)
            })
    },
    storeEac({ commit, rootGetters }, payload) {
        axios.post('eacs', {
            eac: payload.eac,
            description: payload.description,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('storeEac', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Alcance ${payload.eac} creado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            var errorsList = [];
            let errors = {...error.response.data.errors}

            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    updateEac({ commit, rootGetters }, payload) {
        axios.put('eacs/' + payload.id, {
            eac: payload.eac,
            description: payload.description,
        }, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('updateEac', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Alcance ${payload.eac} actualizado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    deleteEac({ commit, rootGetters }, payload) {
        axios.delete('eacs/' + payload.id, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('deleteEac', response.data.data);

            let popupPayload = {
                appears: true,
                message: `Alcance ${payload.eac} eliminado correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}