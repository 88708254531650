export function useNumberOfUsersByRole(users, roleName) {
    let number = 0;
    if (users?.value) {
        number =
            users.value.filter(
                (user) =>
                    user.roles.some((role) => role.role == roleName) == true
            ).length || 0;
    }

    return number;
}
