<script>
import { ref, reactive, watch, computed, watchEffect } from "vue";
import { useUserStore } from "../stores/user";
import { useUsersStore } from "../stores/users";
import { useRolesStore } from "../stores/roles";
import { useNormsStore } from "../stores/norms";

import AppModal from "../components/AppModal.vue";
import AppButton from "../components/AppButton.vue";
import { useNorms } from "../composables/norm";

import AuditorsCalendar from "../components/AuditorsCalendar.vue";

import { mapState, mapActions, mapGetters, Store } from "vuex";

import Modal from "../../views/spa/_elements/Modal.vue";

import ModalNewService from "../../views/spa/ModalNewService.vue";
import OperationsCalendar from "../../views/spa/OperationsCalendar.vue";
import axios from "axios";
import PageHomeHeader from "../components/PageHomeHeader.vue";
import { useRole } from "../composables/role";
import moment from "moment";
import { useToast } from "vue-toastification";

export default {
    setup() {
        const toast = useToast();
        const usersStore = useUsersStore();

        const storeServiceCounter = ref(0);

        const normsStore = useNormsStore();
        normsStore.getNorms();

        usersStore.getUsers();
        const searchText = ref("");
        const QueryAvailabilitiesModal = ref(null);

        const userStore = useUserStore();
        const isOperator = userStore.isOperator;

        const roleStore = useRolesStore();

        const queryAvailabilitiesForm = ref({
            norm_id: "",
            accreditation_id: "",
            eac_id: "",
            errors: {
                exists: false,
                errors: [],
                type: "",
            },
        });

        const QueryAvailabilitiesModalOpen = () => {
            QueryAvailabilitiesModal.value.openModal();
        };

        const { norms, norm } = useNorms(queryAvailabilitiesForm.value.norm_id);

        // STORE SERVICE

        const clients = ref([]);
        const client = ref(null);

        const StoreServiceModal = ref(null);
        const storeServiceStep = ref(1);

        const storeServiceData = ref({
            client_id: "",
            certification_id: "",
            phase_id: "",
            user_id: "",
            name: "",
            date: "",
        });

        function clearStoreServiceData() {
            storeServiceData.client_id = "";
            storeServiceData.certification_id = "";
            storeServiceData.phase_id = "";
            storeServiceData.user_id = "";
            storeServiceData.name = "";
            storeServiceData.date = "";
        }

        const storeServiceInfoForUser = reactive({
            client: "",
            certification: "",
            phase: "",
        });

        const generateServiceName = () => {
            let auditor = auditors.value.available.filter(
                (auditor) => auditor.user_id == storeServiceData.value.user_id
            )[0].user.persona;
            return `${client.value.account_name} - ${
                certification.value?.current_phase.phase.phase
            } - 1MD - ${auditor.first_name.substring(
                0,
                1
            )}${auditor.last_name.substring(0, 1)}`;
        };

        async function storeService() {
            // storeServiceData.name = `${client.value.account_name} - ${certification.value?.current_phase.phase.phase} - 1MD`;
            await axios
                .post("v2/services", {
                    name: generateServiceName().toUpperCase(),
                    service_type_id: 1,
                    phase_id: phaseID.value,
                    started_at: storeServiceData.value.date,
                    finished_at: storeServiceData.value.date,
                    user_id: storeServiceData.value.user_id,
                })
                .then((response) => {
                    toast.success("Servicio creado");
                    StoreServiceModal.value.closeModal();
                    storeServiceCounter.value++;
                    clearStoreServiceData();
                })
                .catch((error) => {
                    console.log("error", error.response);
                });
        }

        watchEffect(async () => {
            await axios
                .get(
                    `clients/${storeServiceData.value.client_id}/certifications`
                )
                .then((response) => {
                    client.value = response.data;
                });
        });

        watch(
            () => storeServiceData.value.certification_id,
            () => {
                if (storeServiceData.value.certification_id) {
                    storeServiceInfoForUser.phase =
                        client.value.certifications[0].current_phase.phase
                            .phase_name || "Sin etapa";
                }
            }
        );

        const certifications = computed(() => {
            return client.value?.certifications;
        });

        const certification = computed(() => {
            return client.value?.certifications?.find(
                (certification) =>
                    certification.id == storeServiceData.value.certification_id
            );
        });

        const phaseID = computed(() => {
            return certification.value?.current_phase.id;
        });

        const auditors = computed(() => {
            let auditors = {
                available: [],
                notAvailable: [],
            };
            certification.value?.eacs_norms.forEach((eac) =>
                eac.auditors.forEach((auditor) => {
                    let isAvailable = auditor.user.availabilities.filter(
                        (availability) =>
                            availability.date == storeServiceData.value.date
                    );
                    if (isAvailable.length > 0) {
                        auditors.notAvailable.push(auditor);
                    } else {
                        auditors.available.push(auditor);
                    }
                })
            );
            return auditors;
        });

        const auditor = computed(() => {
            if (storeServiceData.value.user_id) {
                return auditors.value.available.filter(
                    (auditor) =>
                        auditor.user.id == storeServiceData.value.user_id
                )[0];
            } else {
                return null;
            }
        });

        const selectedDate = computed(() => {
            return moment(storeServiceData.value.date);
        });

        const phases = computed(() => {
            return certifications.value?.phases;
        });

        function openStoreServiceModal() {
            StoreServiceModal.value.openModal();
        }

        return {
            moment,
            searchText,
            queryAvailabilitiesForm,
            QueryAvailabilitiesModal,
            QueryAvailabilitiesModalOpen,
            norm,
            norms,
            isOperator,
            userStore, // mientras migración de option a composition
            roleStore,

            // Service
            certifications,
            certification,
            clients,
            phases,
            client,
            auditors,
            auditor,
            storeServiceData,
            storeServiceStep,
            StoreServiceModal,
            openStoreServiceModal,
            storeServiceCounter,
            storeService,
            storeServiceInfoForUser,
        };
    },
    name: "OperadorInicio",
    components: {
        AppModal,
        AppButton,
        Modal,
        ModalNewService,
        OperationsCalendar,
        AuditorsCalendar,
        PageHomeHeader,
    },
    data() {
        return {
            // search services
            prevSearchText: "",
            filteredServices: [],
            filteredServicesIndex: 0,
        };
    },
    computed: {
        ...mapState("entities/user", {
            userToken: (state) => state.token,
        }),
        isOperator() {
            // migración option a composition
            return this.userStore.isOperator;
        },
        // norms() {
        //     return this.$store.state.norms.norms;
        // },
        accreditations() {
            return this.$store.state.accreditations.accreditations;
        },
        eacs() {
            return this.$store.state.eacs.eacs;
        },

        type() {
            return "alert-" + this.alert.type;
        },
        ...mapGetters({
            servicesTemp: "servicesTemp/getServicesTemp",
            clients: "clients/clients",
        }),
        search() {
            return this.searchText;
        },
    },
    methods: {
        ...mapActions({
            /** OK */
            // getUsers: "users/getUsers",
            storeClients: "clients/storeClients",
            queryAvailabilities: "operationsCalendar/queryAvailabilities",
            loadInvoices: "invoices/loadInvoices",

            /** Chequear */

            storeServiceTemp: "servicesTemp/storeServiceTemp",

            updateServiceTemp: "servicesTemp/updateServiceTemp",
            storeAccreditations: "accreditations/storeAccreditations",
            // 'storeAuditors': 'auditors/storeAuditors',
            storeCertifications: "certifications/storeCertifications",
            storeEacs: "eacs/storeEacs",
            loadRisks: "risks/loadRisks",
        }),

        searchServices() {
            this.$refs.operationsCalendar.searchServices(this.searchText);
        },
        clearSearchServices() {
            this.searchText = "";
            this.$refs.operationsCalendar.clearSearchServices();
        },
        queryAvailabilitiesFn() {
            if (this.queryAvailabilitiesForm.norm_id) {
                var norm_id = this.queryAvailabilitiesForm.norm_id;
            }

            if (this.queryAvailabilitiesForm.accreditation_id) {
                var accreditation_id =
                    this.queryAvailabilitiesForm.accreditation_id;
            }

            var eac_id = this.queryAvailabilitiesForm.eac_id;

            this.queryAvailabilities(this.queryAvailabilitiesForm)
                .then((response) => {
                    this.$refs.queryAvailabilitiesModal.closeModal();
                    this.clearQueryAvailabilitiesForm();
                })
                .catch((error) => {
                    console.log("error component: ", error);
                    this.queryAvailabilitiesForm.errors.exists = true;
                    this.queryAvailabilitiesForm.errors.errors =
                        error.data.errors;
                });
        },
        clearQueryAvailabilitiesForm(errors) {
            if (errors == "errors") {
                this.queryAvailabilitiesForm.errors.exists = false;
                this.queryAvailabilitiesForm.errors.errors = [];
                this.queryAvailabilitiesForm.errors.type = "";
            } else {
                this.queryAvailabilitiesForm.norm_id = "";
                this.queryAvailabilitiesForm.accreditation_id = "";
                this.queryAvailabilitiesForm.eac_id = "";
                this.queryAvailabilitiesForm.errors.exists = false;
                this.queryAvailabilitiesForm.errors.errors = [];
                this.queryAvailabilitiesForm.errors.type = "";
            }
        },
    },
    created() {
        // this.getUsers();
        this.storeAccreditations();
        this.storeCertifications();
        this.storeEacs();
        this.loadRisks();
        axios.get("clients").then((response) => {
            this.clients = response.data.data;
        });

        this.loadInvoices();

        if (this.isOperator) {
            this.roleStore.getRoles();
        }
    },
};
</script>

<template>
    <div v-if="isOperator">
        <div class="mx-8">
            <PageHomeHeader
                @open-search-modal="QueryAvailabilitiesModalOpen"
                v-model:search="searchText"
                @searchServices="searchServices()"
                @clearSearchServices="clearSearchServices()"
                @openStoreServiceModal="openStoreServiceModal"
            />
            <operations-calendar
                :search="searchText"
                ref="operationsCalendar"
                :key="storeServiceCounter"
            ></operations-calendar>
        </div>

        <AppModal ref="StoreServiceModal">
            <template #title>Nuevo servicio</template>
            <template #body>
                <div class="mb-4 min-h-14">
                    <div v-if="client" class="flex gap-4 items-baseline">
                        <span class="text-sm">{{ client.account_name }}</span>
                        <span v-if="certification"
                            >{{ certification.norm.norm }} -
                            {{ certification.accreditation.accreditation }} -
                            {{
                                certification.current_phase.phase.phase_name
                            }}</span
                        >
                    </div>
                    <div v-if="storeServiceData.date">
                        Fecha a agendar:
                        {{ moment(storeServiceData.date).format("DD/MM/YYYY") }}
                    </div>
                    <div v-if="auditor">
                        Auditor seleccionado:
                        {{ auditor.user.persona.first_name }}
                        {{ auditor.user.persona.last_name }}
                    </div>
                </div>

                <div v-if="storeServiceStep == 1">
                    <div class="flex gap-4 w-full">
                        <div class="flex-1">
                            <label for="">Seleccione un cliente</label>
                            <select
                                v-model="storeServiceData.client_id"
                                name=""
                                id=""
                                class="w-full"
                            >
                                <option
                                    :value="client.id"
                                    v-for="client in clients"
                                    :key="client.id"
                                >
                                    {{ client.account_name }}
                                </option>
                            </select>
                        </div>
                        <div class="flex-1">
                            <label for="certification" v-if="certifications">
                                Seleccione una certificación
                            </label>
                            <select
                                v-model="storeServiceData.certification_id"
                                name=""
                                id="certification"
                                class="w-full"
                                v-if="certifications"
                            >
                                <option
                                    v-for="certification in certifications"
                                    :key="certification.id"
                                    :value="certification.id"
                                >
                                    {{ certification.norm.norm }} -
                                    {{
                                        certification.accreditation
                                            .accreditation
                                    }}
                                    -
                                    {{
                                        certification.current_phase?.phase
                                            ?.phase_name
                                    }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="certification" class="flex justify-end gap-1">
                        La etapa en curso para la cual va a generar el servicio
                        es
                        <span class="font-semibold">{{
                            certification.current_phase.phase.phase_name
                        }}</span>
                    </div>
                </div>
                <div v-if="storeServiceStep == 2">
                    <div>
                        La fecha de vencimiento de la etapa en curso es el
                        {{
                            moment(certification.current_phase.expires).format(
                                "DD-MM-YYYY"
                            )
                        }}
                    </div>
                    <div class="flex flex-col gap-2">
                        <label>Seleccione una fecha</label>
                        <input
                            type="date"
                            v-model="storeServiceData.date"
                            class="w-1/2"
                        />
                    </div>
                </div>
                <div v-if="storeServiceStep == 3">
                    <label>Seleccione un auditor</label>
                    <div class="flex w-full gap-4">
                        <div class="flex-1">
                            <div>
                                <select
                                    v-model="storeServiceData.user_id"
                                    name=""
                                    id=""
                                    class="w-full"
                                    v-if="storeServiceData.certification_id"
                                >
                                    <option
                                        v-for="auditor in auditors.available"
                                        :key="auditor.user_id"
                                        :value="auditor.user_id"
                                    >
                                        {{ auditor?.user?.persona?.first_name }}
                                        {{ auditor?.user?.persona?.last_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex-1">
                            <span
                                >Auditores no disponible para la fecha
                                seleccionada:</span
                            >
                            <ul>
                                <li
                                    v-for="auditor in auditors.notAvailable"
                                    :key="auditor.user.id"
                                >
                                    {{ auditor.user.persona.first_name }}
                                    {{ auditor.user.persona.last_name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="storeServiceStep == 4">
                    Revise los datos del servicio a generar antes de guardar.
                </div>

                <div class="flex gap-4 justify-end">
                    <AppButton
                        @click="storeServiceStep--"
                        v-if="storeServiceStep > 1"
                        >Anterior</AppButton
                    >
                    <AppButton
                        class="bg-tuvblue hover:bg-tuvdarkblue transition-colors duration-300 text-white"
                        @click="storeServiceStep++"
                        v-if="storeServiceStep < 4"
                        >Continuar</AppButton
                    >

                    <AppButton
                        class="bg-tuvblue hover:bg-tuvdarkblue transition-colors duration-300 text-white"
                        @click="storeService"
                        v-if="storeServiceStep == 4"
                        >Guardar</AppButton
                    >
                </div>
            </template>
        </AppModal>

        <!-- <AppModal ref="QueryAvailabilitiesModal">
            <template #title>Consultar disponibilidad de auditores</template>
            <template #subtitle> Prueba de title </template>
            <template #body>
                <div class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs">
                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="norm">Norma</label>
                        <select
                            class="appearance-none bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.norm_id"
                            name="norm"
                            id="norm"
                        >
                            <option
                                class="text-slate-400 italic disabled:opacity-50"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione una norma
                            </option>
                            <option
                                v-for="norm in norms"
                                :key="norm.id"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="accreditation"
                            >Acreditación</label
                        >
                        <select
                            class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.accreditation_id"
                            name="accreditation"
                            id="accreditation"
                        >
                            <option
                                class="text-slate-400 italic"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione una acreditación
                            </option>
                            <option
                                v-for="accreditation in accreditations"
                                :key="accreditation.id"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="eac">Alcance</label>
                        <select
                            class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.eac_id"
                            name="eac"
                            id="eac"
                        >
                            <option
                                class="text-slate-400 italic"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione un alcance
                            </option>
                            <option
                                v-for="eac in eacs"
                                :key="eac.id"
                                :value="eac.id"
                            >
                                {{ eac.eac }}
                            </option>
                        </select>
                    </div>
                    {{ norm }}

                    {{ queryAvailabilitiesForm.norm_id }}
                </div>
            </template>
            <template #actions>
                <AppButton
                    class="bg-tuvblue text-white"
                    @@click="queryAvailabilitiesFn()"
                    >Consultar</AppButton
                >
            </template>
            <template #footer></template>
        </AppModal>

        <modal ref="addEventToCalendar">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    Crear auditoría
                </h2>
            </template>

            <template #body>
                <modal-new-service></modal-new-service>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2" @click="$refs.queryAvailabilitiesModal.closeModal(); clearQueryAvailabilitiesForm();">
                    <font-awesome-icon icon="fa-duotone fa-xmark" class="text-xl text-slate-600 md:mr-2" />
                    <span class="hidden md:inline-block">Cancelar</span>
                </button>
                    <button
                        class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="nextStep()"
                    >
                        <font-awesome-icon icon="fa-duotone fa-magnifying-glass" class="text-xl text-white-600 md:mr-2" />
                        <span class="hidden md:inline-block">Siguiente</span>
                    </button>
                </div>
            </template>
        </modal> -->
    </div>

    <div v-else>
        <AuditorsCalendar></AuditorsCalendar>
    </div>
</template>
