<script>

export default {
    name: 'AlertPopup',
    computed: {
        alertAppears(){
            return this.$store.state.miscelaneous.alertPopup.appears;
        },
        alertMessage(){
            return this.$store.state.miscelaneous.alertPopup.message;
        },
        alertType(){
            let aType = this.$store.state.miscelaneous.alertPopup.type;
            var type = aType;
            
            switch(aType){
                case 'success':
                    var bgColor = 'bg-green-300';
                    break;
                case 'error':
                    var bgColor = 'bg-rose-400';
                    break;
                case 'warning':
                    var bgColor = 'bg-amber-400';
                    break;
                case 'info':
                    var bgColor = 'bg-slate-200';
                    break;
                default:
                    var bgColor = 'bg-white';
                    break;
            }

            return {bgColor, type};
        },
    }
}

</script>



<template>
    <div>
        
        <div class="z-50 h-16 w-80 bg-green-300 py-2 px-8 shadow-xl shadow-slate-500/50 transition-all ease-in-out duration-500 fixed top-8 rounded-xl flex flex-row gap-2" :class="[ alertAppears ? 'right-4' : '-right-full', alertType.bgColor ]">
            <!-- <div class="h-4 w-12 text-slate-700 text-lg cursor-pointer">
                <font-awesome-icon icon="fa-duotone fa-xmark" />
            </div> -->
            <div class="flex flex-row items-center gap-2">
                <span v-if="alertType.type == 'success'">
                    <font-awesome-icon icon="fa-duotone fa-check" class="w-8 text-3xl text-green-500" />
                </span>
                <span v-if="alertType.type == 'error'">
                    <font-awesome-icon icon="fa-duotone fa-xmark" class="w-8 text-3xl text-rose-600" />
                </span>
                <span v-if="alertType.type == 'info'">
                    <font-awesome-icon icon="fa-duotone fa-circle-info" class="w-8 text-3xl text-slate-600" />
                </span>
                <span v-if="alertType.type == 'warning'">
                    <font-awesome-icon icon="fa-duotone fa-circle-exclamation" class="w-8 text-3xl text-amber-800" />
                </span>
                <span v-if="alertType.type == 'loading'">
                    <font-awesome-icon icon="fa-duotone fa-spinner-third" class="fa-spin w-8 text-3xl text-slate-600" />
                </span>
                <p class="text-slate-800">{{ alertMessage }}</p>
            </div>
            
        </div>
    </div>
</template>