import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useRolesStore = defineStore("roles", () => {
    const roles = ref([]);

    async function getRoles() {
        axios.get("roles").then((response) => {
            roles.value = response.data.data.map((role) => ({
                id: role.id,
                role: role.role,
            }));
        });
    }

    return { roles, getRoles };
});
