<script setup>
import { ref, watchEffect, computed, onMounted } from "vue";
import axios from "axios";
import PageDocumentsHeader from "../components/PageDocumentsHeader.vue";
import DocumentsItem from "../components/DocumentsItem.vue";
import { useUserStore } from "../stores/user";
import AppModal from "../components/AppModal.vue";
import AppButton from "../components/AppButton.vue";

const user = useUserStore();
const documentsCheck = ref(null);

const documents = ref([]);
const directory = ref(0);
const breadcrumbs = ref({});

watchEffect(async () => {
    await axios
        .get(`documents?directory=${directory.value}`)
        .then((response) => {
            documents.value = response.data;
        })
        .catch((error) => console.log(error.response));
});

const changeDirectory = function (targetDirectory) {
    directory.value = targetDirectory ? targetDirectory.id : 0;
    breadcrumbs.value = targetDirectory;
};

const userCanView = function (document) {
    if (user.roles.some((role) => role.role == "auditor") && document.norm_id) {
        return user.norms.some((norm) => norm.id == document.norm_id);
    }
    return true;
};

const userChecksDocuments = function () {
    axios
        .put("users/" + user.id, {
            documents_check: true,
        })
        .then((response) => {
            user.$patch({
                documents_check: true,
            });
            documentsCheck.value.closeModal();
        })
        .catch((error) => {
            console.log(error.response);
        });
};

// onMounted(() => {
//     if (!user.documents_check) {
//         documentsCheck.value.openModal();
//     }
// });
</script>

<template>
    <div class="mb-16 mx-8">
        <PageDocumentsHeader
            @change-directory="changeDirectory"
            :breadcrumbs="breadcrumbs"
        />
        <div class="mb-4 text-sm">
            <span
                @click="changeDirectory(0)"
                class="underline cursor-pointer text-tuvblue"
                >Documentos</span
            >
            <span class="capitalize" v-if="breadcrumbs"
                ><font-awesome-icon
                    icon="fa-duotone fa-chevron-right"
                    class="mx-2"
                />
                {{ breadcrumbs.name }}</span
            >
        </div>

        <div class="grid grid-cols-2 lg:grid-cols-6 gap-8">
            <template v-for="document in documents" :key="document.id">
                <DocumentsItem
                    :document="document"
                    @change-directory="changeDirectory"
                    v-if="userCanView(document)"
                />
            </template>
        </div>

        <!-- <AppModal ref="documentsCheck">
            <template #title>Importante</template>
            <template #body>
                <p>
                    No está permitida la reproducción total o parcial de ninguno
                    de los archivos y documentos de esta sección por cualquier
                    medio, incluyendo fotocopiado y microfilmación
                </p>
            </template>
            <template #footer>
                <div class="flex flex-row justify-end gap-4">
                    <AppButton
                        @click="userChecksDocuments"
                        class="bg-tuvblue text-tuvlightgray"
                        >Estoy de acuerdo</AppButton
                    >
                </div>
            </template>
        </AppModal> -->
    </div>
</template>
