<template>
    <div
        class="fixed overflow-x-hidden overflow-y-auto top-0 bottom-0 right-0 left-0 z-20"
        v-if="displayModal"
    >
        <div class="fixed bg-black/80 top-0 bottom-0 right-0 left-0 z-40" />
        <div
            class="relative bg-tuvlightgray w-4/6 mx-auto mb-0 mt-8 flex flex-col z-50"
            ref="target"
        >
            <div class="px-6 pt-6">
                <div class="flex justify-between">
                    <h3 class="text-lg text-zinc-800">
                        <slot name="title"></slot>
                    </h3>
                    <button type="button" class="" @click="closeModal()">
                        <font-awesome-icon
                            :icon="['fal', 'xmark-large']"
                            class="text-lg"
                        />
                    </button>
                </div>

                <h4 class="text-sm text-zinc-500">
                    <slot name="subtitle"></slot>
                </h4>
            </div>

            <div class="px-6 py-6 overflow-auto">
                <slot name="body" />
            </div>
            <div class="flex flex-row justify-end gap-4 px-6">
                <slot name="actions" />
            </div>

            <div class="px-6 pb-6">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const displayModal = ref(false);

const openModal = () => {
    displayModal.value = true;
};

const closeModal = () => {
    displayModal.value = false;
};

const target = ref(false);

onClickOutside(target, () => closeModal());

defineExpose({
    openModal,
    closeModal,
});
</script>
