<script setup>
import { ref } from "vue";

import _debounce from "lodash/debounce";
import BadgePhase from "./BadgePhase.vue";
import AppModal from "./AppModal.vue";
import axios from "axios";

const bonificar = ref(false);
const eliminarBonificacion = ref(false);
const invoiceData = ref({
    invoice_number: null,
    invoice_id: null,
});

defineEmits(["bonificarServicio", "noBonificarServicio", "updateInvoice"]);

defineProps({
    phase: {
        type: Object,
        required: true,
    },
    invoices: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <tr
        class="hover:bg-zinc-50 rounded-lg px-4 py-2 text-zinc-950 select-none"
        tabindex="0"
        v-if="phase.certification"
    >
        <td class="table-cell border-none text-sm p-2 align-middle">
            <span v-if="phase.certification && phase.certification.client">{{
                phase.certification.client.account_name
            }}</span>
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-zinc-500"
        >
            <span v-if="phase.certification && phase.certification.norm">{{
                phase.certification.norm.norm
            }}</span>
            -
            <span
                v-if="phase.certification && phase.certification.accreditation"
                >{{ phase.certification.accreditation.accreditation }}</span
            >
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
        >
            <BadgePhase :phase="phase.phase" />
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
        >
            <div
                class="text-xs bg-green-300 px-2 py-2 rounded border border-green-400 text-green-600 w-fit"
                v-if="phase.nofee"
            >
                <Transition mode="out-in">
                    <span v-if="eliminarBonificacion">
                        ¿Eliminar bonif?
                        <span
                            class="underline px-1 cursor-pointer"
                            @click="
                                $emit(
                                    'updateInvoice',
                                    phase,
                                    null,
                                    null,
                                    'false'
                                );
                                eliminarBonificacion = false;
                            "
                            >Si</span
                        >
                        <span
                            class="underline px-1 cursor-pointer"
                            @click="eliminarBonificacion = false"
                            >No</span
                        >
                    </span>
                    <span v-else>
                        Bonificado
                        <font-awesome-icon
                            icon="fa-light fa-xmark-large"
                            class="text-zinc-800 text-xs ml-2 cursor-pointer"
                            @click="eliminarBonificacion = true"
                    /></span>
                </Transition>
            </div>

            <span class="select-all" v-else-if="phase.invoice_id">{{
                phase.invoice.invoice
            }}</span>
            <span class="cursor-pointer text-zinc-400 italic text-xs" v-else
                >Sin factura</span
            >
        </td>

        <td class="border-none p-2 align-middle text-slate-600 flex gap-2">
            <button
                class="py-1 px-2 bg-zinc-500/25 border border-zinc-400 text-zinc-500 rounded min-w-[40px]"
            >
                <font-awesome-icon
                    icon="fa-duotone fa-file-circle-plus"
                    class="text-lg"
                    @click="$refs.addInvoiceToService.openModal()"
                />
            </button>

            <button
                class="py-1 px-2 bg-zinc-500/25 border border-zinc-400 text-zinc-500 rounded min-w-[40px]"
            >
                <Transition mode="out-in">
                    <span class="text-xs" v-if="bonificar"
                        >¿Bonificar?
                        <span
                            class="underline px-1"
                            @click="
                                $emit(
                                    'updateInvoice',
                                    phase,
                                    null,
                                    null,
                                    'true'
                                );
                                bonificar = false;
                            "
                            >Si</span
                        >
                        <span class="underline px-1" @click="bonificar = false"
                            >No</span
                        ></span
                    >
                    <font-awesome-icon
                        icon="fa-duotone fa-gift"
                        class="text-lg"
                        @click="bonificar = true"
                        v-else
                    />
                </Transition>
            </button>
        </td>

        <AppModal ref="addInvoiceToService">
            <template #title>Factura de servicio</template>
            <template #subtitle
                ><span class="text-zinc-800">{{
                    phase.certification.client.account_name
                }}</span
                ><br />
                {{ phase.certification.norm.norm }} -
                {{ phase.certification.accreditation.accreditation }}
            </template>

            <template #body>
                <div class="flex gap-4 w-full">
                    <div
                        class="flex flex-col w-1/2 border border-zinc-300 rounded p-4"
                    >
                        <p class="mb-2">Crear nueva factura</p>
                        <p class="text-xs text-zinc-500 mb-2">
                            Puede crear una nueva factura en el sistema
                            ingresando su número en el siguiente campo
                        </p>
                        <input
                            class="text-sm py-2 px-4 h-10 border-zinc-300 rounded placeholder:text-zinc-400 placeholder:italic placeholder:text-xs"
                            type="text"
                            placeholder="Ingresar número de factura"
                            v-model="invoiceData.invoice_number"
                        />
                    </div>
                    <div
                        class="flex flex-col w-1/2 border border-zinc-300 rounded p-4"
                    >
                        <p class="mb-2">Elegir factura existente</p>
                        <p class="text-xs text-zinc-500 mb-2">
                            Si la factura fue creada con anterioridad, puede
                            seleccionarla en el siguiente campo
                        </p>

                        <select
                            class="text-sm py-2 px-4 h-10 border-zinc-300 rounded placeholder:text-zinc-400 placeholder:italic placeholder:text-xs disabled:text-xs disabled:italic"
                            name="existingInvoice"
                            id="existingInvoice"
                            v-model="invoiceData.invoice_id"
                        >
                            <option value="0" disabled selected>
                                Seleccionar factura
                            </option>
                            <option
                                v-for="invoice in invoices"
                                :key="invoice.id"
                                :value="invoice.id"
                                :selected="invoice.id == phase.invoice_id"
                            >
                                {{ invoice.invoice }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-zinc-400 cursor-pointer px-4 py-2 uppercase rounded tracking-wide text-xs text-white mr-2"
                        @click="$refs.addInvoiceToService.closeModal()"
                    >
                        Cancelar
                    </button>
                    <button
                        class="bg-rose-500 mr-2 cursor-pointer px-4 py-2 uppercase rounded tracking-wide text-xs text-white"
                        @click="
                            $emit('updateInvoice', phase, null, null, 'false');
                            $refs.addInvoiceToService.closeModal();
                        "
                    >
                        Eliminar factura
                    </button>
                    <button
                        class="bg-blue-600 cursor-pointer px-4 py-2 uppercase rounded tracking-wide text-xs text-white"
                        @click="
                            $emit(
                                'updateInvoice',
                                phase,
                                invoiceData.invoice_id,
                                invoiceData.invoice_number,
                                'false'
                            );
                            $refs.addInvoiceToService.closeModal();
                        "
                    >
                        Guardar factura
                    </button>
                </div>
            </template>
        </AppModal>
    </tr>
</template>

<style lang="scss" scoped>
select,
input[type="date"] {
    height: 36px;
    width: 100%;
    margin-bottom: 0;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.05s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
