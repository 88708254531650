import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useUserStore } from "@/stores/user";

export const useNormsStore = defineStore("norms", () => {
    const user = useUserStore();
    const norms = ref([]);

    async function getNorms() {
        axios
            .get("norms", {
                // headers: {
                //     Authorization: "Bearer " + user.getToken,
                // },
            })
            .then((response) => {
                norms.value = response.data.data;
            });
    }

    return { norms, getNorms };
});
