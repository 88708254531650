<template>
    <header class="flex flex-row justify-between w-full">
        <div
            class="flex flex-col justify-start items-baseline my-2 w-full lg:w-1/5"
        >
            <h1 class="text-xl text-tuvdarkblue">
                <slot name="title" />
            </h1>
            <p class="text-tuvdarkblue md:ml-0">
                <slot name="subtitle" />
            </p>
        </div>
        <div class="my-2 relative w-3/5">
            <slot name="headerCenter" />
        </div>
        <div class="my-2 w-1/5 flex justify-end">
            <slot name="headerRight" />
        </div>
    </header>
</template>
