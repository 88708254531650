<script>
import { ref } from "vue";
import moment from "moment";
import DashboardCardMini from "../components/DashboardCardMini.vue";
import DashboardLogEmails from "../components/DashboardLogEmails.vue";
import DashboardCardsContainer from "../components/DashboardCardsContainer.vue";
import DashboardCardsMiniContainer from "../components/DashboardCardsMiniContainer.vue";
import DashboardClientsWithoutCertificates from "../components/DashboardClientsWithoutCertificates.vue";
import DashboardClientsWithoutCertifications from "../components/DashboardClientsWithoutCertifications.vue";
import DashboardClientsWithoutSchedule from "../components/DashboardClientsWithoutSchedule.vue";
import DashboardCertificationsWithoutData from "../components/DashboardCertificationsWithoutData.vue";
import DashboardCertificationsWithoutDocs from "../components/DashboardCertificationsWithoutDocs.vue";
import DashboardCertificationsWithoutVeto from "../components/DashboardCertificationsWithoutVeto.vue";
import DashboardCertificationsWithoutAuditors from "../components/DashboardCertificationsWithoutAuditors.vue";
import DashboardCertificationsUnfinished from "../components/DashboardCertificationsUnfinished.vue";

import axios from "axios";
import DashboardCertificationsWithOneAuditor from "../components/DashboardCertificationsWithOneAuditor.vue";
import { useUserStore } from "../stores/user";
import { defaultDocument } from "@vueuse/core";
import { filter } from "lodash";

export default {
    name: "DashboardOperations",
    components: {
        DashboardCardMini,
        DashboardLogEmails,
        DashboardCardsContainer,
        DashboardCardsMiniContainer,
        DashboardClientsWithoutCertificates,
        DashboardClientsWithoutCertifications,
        DashboardClientsWithoutSchedule,
        DashboardCertificationsWithoutData,
        DashboardCertificationsWithoutDocs,
        DashboardCertificationsWithoutVeto,
        DashboardCertificationsWithoutAuditors,
        DashboardCertificationsWithOneAuditor,
        DashboardCertificationsUnfinished,
    },

    setup() {
        const user = useUserStore();

        const certsWithoutDocs = ref(null);
        const certsWithoutVeto = ref(null);
        const clientsWithoutCerts = ref(null);
        const phasesWithoutInv = ref(null);

        return {
            user,
            certsWithoutVeto,
            certsWithoutDocs,
            clientsWithoutCerts,
            phasesWithoutInv,
        };
    },
    data() {
        return {
            delay: {
                cgcre: 30,
                dakks: 60,
                docs: 2,
                veto: 20,
            },
            clientsWithoutCertifications: {},
            clientsWithoutCertificationsLoading: false,
            certificationsWithoutData: {},
            certificationsWithoutDataLoading: false,
            certificationsWithoutAuditors: {},
            certificationsWithOneAuditor: [],
            phasesWithoutInvoices: [],
            certificationsWithoutDocs: [],
            certificationsWithoutVeto: [],
            clientsWithoutCertificate: [],
        };
    },

    created() {
        this.clientsWithoutCertificationsLoading = true;
        this.certificationsWithoutDataLoading = true;

        let endpoints = [
            "clients?certs=no",
            "certification-services",
            "dashboard-certifications-without-auditors",
            "dashboard/auditors/certifications-with-one-auditor",
            "certifications-phases/invoices?filter=without",
            "v2/dashboard/operations/certifications-without-docs",
            "v2/dashboard/operations/certifications-without-veto",
            "v2/dashboard/operations/certifications-without-certificate",
        ];

        axios
            .all(
                endpoints.map((endpoint) =>
                    axios.get(endpoint, {
                        headers: {
                            Authorization: "Bearer " + this.user.token,
                        },
                    })
                )
            )
            .then((response) => {
                this.clientsWithoutCertifications = response[0].data.data;
                this.certificationsWithoutData = response[1].data.data;
                this.certificationsWithoutAuditors = response[2].data.data;
                this.certificationsWithOneAuditor = response[3].data.data;
                this.phasesWithoutInvoices = response[4].data;
                this.certificationsWithoutDocs = response[5].data;
                this.certificationsWithoutVeto = response[6].data;
                this.clientsWithoutCertificate = response[7].data;
            })
            .catch((error) => {
                console.log("axios all error: ", error.response);
            })
            .finally(() => {
                this.clientsWithoutCertificationsLoading = false;
                this.certificationsWithoutDataLoading = false;
            });
    },
    computed: {
        countClientsWithoutCertificates() {
            return this.clientsWithoutCertificate.length;
        },
        countClientsWithoutCertifications() {
            return this.clientsWithoutCertifications.length;
        },

        clientsWithoutSchedule() {
            let certifications =
                this.$store.state.certifications.certifications;
            let today = moment();
            let nintyDays = moment().add(90, "days");

            let filteredCertifications = certifications.filter(
                (cert) =>
                    cert.first_opened_certification_phase &&
                    !cert.first_opened_certification_phase.scheduled_at &&
                    moment(
                        cert.first_opened_certification_phase.expires
                    ).isSameOrBefore(nintyDays)
            );

            filteredCertifications.sort((a, b) =>
                a.first_opened_certification_phase.expires >
                b.first_opened_certification_phase.expires
                    ? 1
                    : a.first_opened_certification_phase.expires <
                      b.first_opened_certification_phase.expires
                    ? -1
                    : 0
            );

            return filteredCertifications;
        },
        countClientsWithoutSchedule() {
            return this.clientsWithoutSchedule.length;
        },
        countCertificationsWithoutData() {
            return Object.keys(this.certificationsWithoutData).length;
        },

        countCertificationsWithoutDocs() {
            return this.certificationsWithoutDocs.length;
        },

        countCertificationsWithoutVeto() {
            return this.certificationsWithoutVeto.length;
        },
    },
    methods: {
        updateCertificationsWithoutDocs() {
            axios
                .get("v2/dashboard/operations/certifications-without-docs", {
                    headers: {
                        Authorization: "Bearer " + this.user.token,
                    },
                })
                .then((response) => {
                    this.certificationsWithoutDocs = response.data;
                })
                .catch((error) => {
                    console.log("axios without-docs: ", error.response);
                });
        },

        updateCertificationsWithoutVeto() {
            axios
                .get("v2/dashboard/operations/certifications-without-veto", {
                    headers: {
                        Authorization: "Bearer " + this.user.token,
                    },
                })
                .then((response) => {
                    this.certificationsWithoutVeto = response.data;
                })
                .catch((error) => {
                    console.log("axios without-veto: ", error.response);
                });
        },

        updateClientsWithoutCertificate() {
            axios
                .get(
                    "v2/dashboard/operations/certifications-without-certificate",
                    {
                        headers: {
                            Authorization: "Bearer " + this.user.token,
                        },
                    }
                )
                .then((response) => {
                    this.clientsWithoutCertificate = response.data;
                })
                .catch((error) => {
                    console.log("axios without-certificate: ", error.response);
                });
        },
    },
};
</script>

<template>
    <div>
        <DashboardCardsMiniContainer>
            <DashboardCardMini ref="certsWithoutDocs" class="cursor-pointer">
                <template #counter>
                    {{ countCertificationsWithoutDocs }}
                </template>

                <template #title>Certificaciones sin documentos</template>
            </DashboardCardMini>
            <v-dialog :activator="certsWithoutDocs" max-width="600">
                <template v-slot:default="{ isActive }">
                    <v-card class="text-sm p-4">
                        <v-card-title
                            >Certificationes sin documentación</v-card-title
                        >
                        <v-card-text>
                            <template
                                v-for="certification in certificationsWithoutDocs"
                                :key="certification.id"
                                class="my-2"
                            >
                                <div class="flex items-baseline">
                                    <span class="text-base">{{
                                        certification.client.account_name
                                    }}</span>
                                    <span class="mx-1 text-sm text-slate-500">{{
                                        certification.norm.norm
                                    }}</span>
                                    <span class="text-slate-500">{{
                                        certification.accreditation
                                            .accreditation
                                    }}</span>
                                </div>
                            </template>
                        </v-card-text>
                        <!-- <template v-slot:actions>
                            <v-btn
                                class="ml-auto"
                                text="Close"
                                @click="isActive.value = false"
                            ></v-btn>
                        </template> -->
                    </v-card>
                </template>
            </v-dialog>

            <DashboardCardMini ref="certsWithoutVeto" class="cursor-pointer">
                <template #counter>
                    {{ countCertificationsWithoutVeto }}
                </template>

                <template #title>Certificaciones sin veto</template>
            </DashboardCardMini>
            <v-dialog :activator="certsWithoutVeto" max-width="600">
                <template v-slot:default="{ isActive }">
                    <v-card class="text-sm p-4">
                        <v-card-title>Certificationes sin veto</v-card-title>
                        <v-card-text>
                            <template
                                v-for="certification in certificationsWithoutVeto"
                                :key="certification.id"
                                class="my-2"
                            >
                                <div class="flex items-baseline">
                                    <span class="text-base">{{
                                        certification.client.account_name
                                    }}</span>
                                    <span class="mx-1 text-sm text-slate-500">{{
                                        certification.norm.norm
                                    }}</span>
                                    <span class="text-slate-500">{{
                                        certification.accreditation
                                            .accreditation
                                    }}</span>
                                </div>
                            </template>
                        </v-card-text>
                        <!-- <template v-slot:actions>
                            <v-btn
                                class="ml-auto"
                                text="Close"
                                @click="isActive.value = false"
                            ></v-btn>
                        </template> -->
                    </v-card>
                </template>
            </v-dialog>

            <DashboardCardMini ref="clientsWithoutCerts" class="cursor-pointer">
                <template #counter>
                    {{ countClientsWithoutCertificates }}
                </template>

                <template #title>Clientes sin certificados</template>
            </DashboardCardMini>
            <v-dialog :activator="clientsWithoutCerts" max-width="600">
                <template v-slot:default="{ isActive }">
                    <v-card class="text-sm p-4">
                        <v-card-title
                            >Clientes sin certificaciones</v-card-title
                        >
                        <v-card-text>
                            <template
                                v-for="client in clientsWithoutCertificates"
                                :key="client.id"
                                class="my-2"
                            >
                                <div class="flex items-baseline">
                                    <span class="text-base">{{
                                        client.client.account_name
                                    }}</span>
                                    <span class="mx-1 text-sm text-slate-500">{{
                                        client.norm.norm
                                    }}</span
                                    >-
                                    <span class="text-sm text-slate-500">{{
                                        client.accreditation.accreditation
                                    }}</span>
                                </div>
                            </template>
                        </v-card-text>
                        <!-- <template v-slot:actions>
                            <v-btn
                                class="ml-auto"
                                text="Close"
                                @click="isActive.value = false"
                            ></v-btn>
                        </template> -->
                    </v-card>
                </template>
            </v-dialog>

            <DashboardCardMini ref="phasesWithoutInv" class="cursor-pointer">
                <template #counter>
                    {{ phasesWithoutInvoices.length }}
                </template>

                <template #title>Etapas sin facturar</template>
            </DashboardCardMini>
            <v-dialog :activator="phasesWithoutInv" max-width="600">
                <template v-slot:default="{ isActive }">
                    <v-card class="text-sm p-4">
                        <v-card-title>Etapas sin facturar</v-card-title>
                        <v-card-text>
                            <template
                                v-for="phase in phasesWithoutInvoices"
                                :key="phase.id"
                                class="my-2"
                            >
                                <div class="flex items-baseline">
                                    <span class="text-base">{{
                                        phase.certification.client.account_name
                                    }}</span>
                                    <span class="mx-1 text-sm text-slate-500">{{
                                        phase.certification.norm.norm
                                    }}</span>
                                    <span class="text-slate-500 text-sm">{{
                                        phase.certification.accreditation
                                            .accreditation
                                    }}</span>
                                </div>
                            </template>
                        </v-card-text>
                        <!-- <template v-slot:actions>
                            <v-btn
                                class="ml-auto"
                                text="Close"
                                @click="isActive.value = false"
                            ></v-btn>
                        </template> -->
                    </v-card>
                </template>
            </v-dialog>

            <!-- <DashboardCardMini>
                <template #counter v-if="clientsWithoutCertificationsLoading">
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-notch fa-spin"
                    />
                </template>
                <template v-else #counter>
                    {{ countClientsWithoutCertifications }}
                </template>

                <template #title>Clientes sin certificaciones</template>
            </DashboardCardMini> -->

            <!-- <DashboardCardMini>
                <template #counter>
                    {{ countClientsWithoutSchedule }}
                </template>

                <template #title>Clientes sin agenda</template>
            </DashboardCardMini> -->

            <!-- <DashboardCardMini>
                <template #counter v-if="certificationsWithoutDataLoading">
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-notch fa-spin"
                    />
                </template>
                <template v-else #counter>
                    {{ countCertificationsWithoutData }}
                </template>

                <template #title>Certificationes sin información</template>
            </DashboardCardMini> -->
        </DashboardCardsMiniContainer>
        <h3 class="text-slate-600 text-lg my-4 font-semibold">
            Indicadores de Cóndor
        </h3>
        <DashboardCardsContainer>
            <DashboardLogEmails />
            <DashboardClientsWithoutCertifications
                :clientsWithoutCertifications="clientsWithoutCertifications"
                v-if="clientsWithoutCertifications"
            />

            <DashboardCertificationsWithoutData
                :certificationsWithoutData="certificationsWithoutData"
                v-if="certificationsWithoutData"
            />
        </DashboardCardsContainer>
        <h3 class="text-slate-600 text-lg my-4 font-semibold">
            Indicadores de Auditorías en curso
        </h3>
        <DashboardCardsContainer>
            <DashboardCertificationsWithoutDocs
                :certificationsWithoutDocs="certificationsWithoutDocs"
                v-if="certificationsWithoutDocs"
                @update-certifications-without-docs="
                    updateCertificationsWithoutDocs
                "
            />
            <DashboardCertificationsWithoutVeto
                :certificationsWithoutVeto="certificationsWithoutVeto"
                v-if="certificationsWithoutVeto"
                @update-certifications-without-veto="
                    updateCertificationsWithoutVeto
                "
            />
            <DashboardClientsWithoutCertificates
                :clientsWithoutCertificate="clientsWithoutCertificate"
                v-if="clientsWithoutCertificate"
                @update-clients-without-certificate="
                    updateClientsWithoutCertificate
                "
            />
        </DashboardCardsContainer>
        <h3 class="text-slate-600 text-lg my-4 font-semibold">
            Información general
        </h3>
        <DashboardCardsContainer>
            <DashboardCertificationsWithoutAuditors
                :certificationsWithoutAuditors="certificationsWithoutAuditors"
                v-if="certificationsWithoutAuditors"
            />
            <!-- <DashboardClientsWithoutSchedule
                :clientsWithoutSchedule="clientsWithoutSchedule"
                v-if="clientsWithoutSchedule"
            /> -->
            <DashboardCertificationsWithOneAuditor
                :certificationsWithOneAuditor="certificationsWithOneAuditor"
            />
            <DashboardCertificationsUnfinished />
        </DashboardCardsContainer>
    </div>
</template>
