import axios from "axios";

const state = {
	invoices: [],
};

const getters = {
	
};

const mutations = {
    loadInvoices(state, payload){
        state.invoices = payload;
    },
    storeInvoice(state, payload){
        state.invoices.push(payload)
    },
};

const actions = {
    loadInvoices({ commit, rootGetters }){
        axios.get('invoices', {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('loadInvoices', response.data.data)
        })
        .catch((error) => {
            console.log('error en loadInvoices (store): ', error.response)
        })
    },
    storeInvoice({ commit, rootGetters }, payload){
        axios.post('invoices', {
            invoice: payload,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('storeInvoice', response.data.data)
        })
        .catch((error) => {
            console.log('error en storeInvoice (store): ', error.response)
        })
    },
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}