<style lang="scss" scoped>
.service__add-to-calendar {
    @apply grid grid-cols-2 gap-4;

    .button {
        @apply no-underline flex flex-col md:flex-row;
        svg {
            width: 100% !important;
            text-align: center !important;
            @media (min-width: 768px) {
                width: auto !important;
            }
        }

        span {
            width: 100% !important;
            text-align: center !important;
            font-size: 0.875rem !important;
            @media (min-width: 768px) {
                width: auto !important;
                font-size: 1rem !important;
            }
        }
    }
}
:deep(.fc.fc-daygrid-day-frame) {
    @apply bg-emerald-500;
}
</style>

<template>
    <div>
        <service-modal ref="ServiceModal">
            <template v-slot:header>
                <h2>
                    <i class="far fa-file-search"></i>
                    {{ serviceData.event.title }}
                </h2>
            </template>

            <template v-slot:body>
                <add-to-calendar
                    :title="serviceData.event.title"
                    :start="serviceData.event.start"
                    :end="serviceData.event.start"
                    inline-template
                >
                    <div class="service__add-to-calendar">
                        <google-calendar
                            id="google-calendar"
                            class="button button-sm blue-button"
                        >
                            <i class="fa fa-google"></i>
                            <span>Agregar a Google Calendar</span>
                        </google-calendar>

                        <!-- <office365-calendar id="office365-calendar" class="button button-sm blue-button">
                    <i class="fa fa-windows"></i> <span>Agregar a Outlook</span>
                    </office365-calendar> -->
                    </div>
                </add-to-calendar>
            </template>
        </service-modal>
        <!-- <h1 class="page__title">Mi Calendario</h1> -->
        <div class="cards__container">
            <div class="card card-double" id="UserCalendar">
                <div class="alert" v-bind:class="type">{{ alert.message }}</div>
                <div
                    class="flex flex-row justify-center p-4 relative mx-auto lg:top-12"
                >
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvgreen"
                    ></div>
                    <div class="leading-4 align-middle">Disponible</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvred"
                    ></div>
                    <div class="leading-4 align-middle">No disponilbe</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvyellow"
                    ></div>
                    <div class="leading-4 align-middle">Fecha reservada</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvblue"
                    ></div>
                    <div class="leading-4 align-middle">
                        Servicio confirmado
                    </div>
                </div>
                <FullCalendar ref="calendar" :options="config" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import axios from "axios";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import AddToCalendar from "vue-add-to-calendar";

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import ServiceModal from "../../views/spa/ServiceModal.vue";
import { useUserStore } from "../stores/user";
import { Calendar } from "@fullcalendar/core";

export default {
    setup() {
        const userStore = useUserStore();
        return {
            userStore,
        };
    },
    name: "AuditorCalendar",
    components: {
        FullCalendar,
        ServiceModal,
        AddToCalendar,
    },
    data() {
        return {
            alert: {
                message: "",
                type: "",
            },
            serviceData: null,
            services: [],
        };
    },
    computed: {
        type() {
            return "alert-" + this.alert.type;
        },
        ...mapGetters({
            availabilities: "availabilities/availabilities",
            holidays: "holidays/holidays",
            auditss: "auditss/auditss",
        }),

        ...mapState(useUserStore, ["token"]),

        config() {
            return {
                ...this.configOptions,
                ...this.eventHandlers,
            };
        },

        configOptions() {
            return {
                selectable: true,
                firstDay: 0,
                longPressDelay: 500,
                locale: esLocale,
                eventSources: [
                    {
                        events: this.availabilities,
                        display: "background",
                        backgroundColor: "#FA3747",
                        classNames: ["auditss-audit-confirmed"],
                    },
                    // {
                    //     events: this.holidays,
                    //     display: "background",
                    //     backgroundColor: "#F04262",
                    // },
                    {
                        events: this.auditss,
                        display: "background",
                        backgroundColor: "#001FD2",
                        textColor: "#ffffff",
                    },
                    {
                        events: this.services,
                        display: "background",
                    },
                ],
                // eventSources: [
                //     {
                //         events: this.availabilities,
                //         display: "background",
                //         backgroundColor: "#FA3747",
                //     },
                //     // {
                //     //     events: this.holidays,
                //     //     display: "background",
                //     //     backgroundColor: "#F04262",
                //     // },
                //     // {
                //     //     events: this.auditss,
                //     //     display: "background",
                //     //     backgroundColor: "#001FD2",
                //     //     textColor: "#ffffff",
                //     // },
                // ],
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                contentHeight: "auto",
                selectOverlap: false,
                progressiveEventRendering: true,
            };
        },
        eventHandlers() {
            return {
                dateClick: this.handleDateCLick,
                eventClick: this.handleEventClick,
            };
        },
    },
    methods: {
        ...mapActions({
            deleteAvailability: "availabilities/deleteAvailability",
            storeAvailabilities: "availabilities/storeAvailabilities",
            // storeHolidaysBatch: "holidays/storeHolidaysBatch",
            storeAvailabilitiesBatch: "availabilities/storeAvailabilitiesBatch",
            storeAuditsBatch: "auditss/storeAuditsBatch",
        }),
        handleDateCLick: function (date) {
            var sources = this.$refs.calendar.getApi().getEventSources();
            if (
                this.$store.getters["availabilities/getIfAvailabilityExists"](
                    date.dateStr
                )
            ) {
                this.deleteAvailability(date.dateStr)
                    .then((response) => {
                        sources.forEach((source) => source.refetch());
                        this.alert.message =
                            "Se marcó el día " +
                            moment(date.dateStr).format("D/M/YYYY") +
                            " como disponible.";
                        this.alert.type = "success";
                    })
                    .catch((error) => {
                        this.alert.message =
                            "Hubo un inconveniente al intentar marcar el día " +
                            moment(date.dateStr).format("D/M/YYYY") +
                            " como disponible.";
                        this.alert.type = "danger";
                    });
            } else {
                this.storeAvailabilities([date.dateStr])
                    .then((response) => {
                        this.alert.message =
                            "Se marcó el día " +
                            moment(date.dateStr).format("D/M/YYYY") +
                            " como no disponible.";
                        this.alert.type = "success";
                        sources.forEach((source) => source.refetch());
                    })
                    .catch((error) => {
                        this.alert.message = error.response.data.data;
                        this.alert.type = "danger";
                    });
            }
        },
        handleEventClick(data) {
            this.serviceData = data;
            // this.$refs.ServiceModal.openModal();
        },
        setColorsForService(service) {
            var bgColor, txtColor;

            if (service.done) {
                bgColor = "#001FD2";
                txtColor = "#FFFFFF";
            } else {
                if (service.agenda) {
                    bgColor = "#001FD2";
                    txtColor = "#FFFFFF";
                } else {
                    if (service.confirmed) {
                        bgColor = "#001FD2";
                        txtColor = "#FFFFFF";
                    } else {
                        bgColor = "#FECF56";
                        txtColor = "#000045";
                    }
                }
            }

            return [bgColor, txtColor];
        },
    },
    async created() {
        await axios.get("v2/users/8/services").then(
            (res) =>
                (this.services = res.data.schedules.map((schedule) => {
                    let status = "";
                    if (schedule.service.done) {
                        status = "realizado";
                    } else {
                        if (schedule.service.agenda) {
                            status = "con-agenda";
                        } else {
                            if (schedule.service.confirmed) {
                                status = "confirmado";
                            } else {
                                status = "reservado";
                            }
                        }
                    }
                    return {
                        ...schedule.service,
                        id: schedule.service.uuid,
                        title: schedule.service.name,
                        start: moment(schedule.started_at)
                            .add(3, "hours")
                            .format("YYYY-MM-DD"),

                        status: status,
                        backgroundColor: this.setColorsForService(
                            schedule.service
                        )[0],
                        borderColor: this.setColorsForService(
                            schedule.service
                        )[0],
                        textColor: this.setColorsForService(
                            schedule.service
                        )[1],
                        className: schedule.service.confirmed
                            ? "auditss-audit-confirmed"
                            : "",
                    };
                }))
        );
        console.log("servicios auditor", this.services);

        // this.$axios
        //     .get("holidays", {
        //         headers: {
        //             Authorization:
        //                 "Bearer " + this.$store.getters["user/getToken"],
        //         },
        //     })
        //     .then((responseHolidyas) => {
        //         this.storeHolidaysBatch(responseHolidyas.data.data);
        //         const id = JSON.parse(
        //             atob(this.$store.getters["user/getToken"].split(".")[1])
        //         ).sub;
        const id = this.userStore.id;
        this.$axios
            .get("users/" + id + "/availabilities", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((responseAvailabilities) => {
                this.storeAvailabilitiesBatch(responseAvailabilities.data.data);
                this.$axios
                    .get("users/" + id + "/auditss", {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    })
                    .then((responseAudits) => {
                        this.storeAuditsBatch(responseAudits.data.data);
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            })
            .catch((error) => {
                console.log("responseAvailiabilites" + error);
            });
        // })
        // .catch((error) => {
        //     console.log("responseHolidays" + error);
        // });
    },
};
</script>
