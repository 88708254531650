<script>
import PageSubMenuItem from './PageSubMenuItem.vue'

export default {
    name: 'PageSubMenu',
    components: {
        PageSubMenuItem,
    },
    props: {
        menuItems: {
            required: true,
            type: Object,
        }
    }
}
</script>

<template>
    <ul class="flex flex-row justify-start items-center gap-4">
        <PageSubMenuItem
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            :menuItem="menuItem"
        >
        </PageSubMenuItem>
    </ul>
</template>