<template>
    <div>
        <div
            class="flex flex-col items-center justify-center w-full h-full gap-2 bg-tuvgray/60 select-none rounded min-h-[140px] p-4 cursor-pointer relative hover:bg-tuvblack/10 transition-all duration-100"
            v-click="open"
        >
            <font-awesome-icon
                icon="fa-light fa-cloud-arrow-down"
                class="font-bold text-xl text-tuvblack absolute top-0 right-0 p-2"
                @click="toggleMenuContextual"
                v-if="document.document_type_id != 2"
            />
            <ul
                class="absolute bg-tuvlightgray top-2 right-2 shadow-lg flex flex-col text-right z-30 rounded"
                v-if="menuContextual"
                @mouseleave="toggleMenuContextual"
            >
                <!-- <span class="hover:bg-tuvgray p-2">Renombrar</span> -->
                <li
                    class="hover:bg-tuvgray p-2"
                    @click="open"
                    v-if="document.document_type_id == 1"
                >
                    <span v-if="isExternalLink">Visitar</span
                    ><span v-else>Descargar</span>
                </li>
                <li
                    class="hover:bg-tuvgray p-2"
                    @click="open"
                    v-if="document.document_type_id == 3"
                >
                    <span>Ver video</span>
                </li>
            </ul>
            <div class="absolute top-0 left-0 roudned flex flex-col gap-1">
                <span
                    v-if="document?.norm"
                    class="bg-tuvblue text-white px-2 py-1 rounded w-fit"
                >
                    {{ document.norm.norm }}
                </span>
                <span
                    v-if="documentExtension"
                    class="bg-tuvbluemed text-white px-2 py-1 rounded w-fit"
                    >{{ documentExtension }}</span
                >
            </div>

            <font-awesome-icon
                :icon="`${documentIcon}`"
                class="text-6xl text-tuvblack"
            />
            <span class="text-center break-all">{{ document.name }}</span>
        </div>
        <AppModal ref="documentsCheck" class="z-50">
            <template #title class="font-bold">Importante</template>
            <template #body>
                <p class="text-base">
                    No está permitida la reproducción total o parcial de ninguno
                    de los archivos y documentos de esta sección por cualquier
                    medio, incluyendo fotocopiado y microfilmación
                </p>
            </template>
            <template #footer>
                <div class="flex flex-row justify-end gap-4">
                    <AppButton
                        @click="download"
                        class="bg-tuvblue text-tuvlightgray"
                        >Estoy de acuerdo</AppButton
                    >
                </div>
            </template>
        </AppModal>
        <AppModal ref="videoContainer">
            <template #title>{{ document.name }}</template>
            <template #body>
                <video ref="video" autoplay controls />
            </template>
        </AppModal>
    </div>
</template>

<script setup>
import axios from "axios";
import { ref, computed } from "vue";
import AppModal from "../components/AppModal.vue";
import AppButton from "../components/AppButton.vue";
import { useMediaControls } from "@vueuse/core";

const menuContextual = ref(false);
const documentsCheck = ref(null);

const video = ref(null);
const videoContainer = ref(null);

const props = defineProps({
    document: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["changeDirectory"]);

const isExternalLink = computed(() => {
    return props.document.path.includes("http");
});

const open = function () {
    if (props.document.document_type_id == 2) {
        emit("changeDirectory", props.document);
        return;
    } else if (props.document.document_type_id == 3) {
        toggleMenuContextual();
        videoContainer.value.openModal();
        const { playing, currentTime, duration, volume } = useMediaControls(
            video,
            {
                src: props.document.path,
            }
        );
    } else if (props.document.path.includes("http")) {
        window.open(props.document.path);
    } else {
        if (menuContextual.value) {
            documentsCheck.value.openModal();
        } else {
            toggleMenuContextual();
        }

        return;
    }
};

const download = function () {
    documentsCheck.value.closeModal();
    axios
        .get("documents/download/" + props.document.id, {
            responseType: "blob",
        })
        .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
                "download",
                props.document.path.split("/").slice(-1)
            );
            document.body.appendChild(fileLink);

            fileLink.click();
        })
        .catch((error) => {
            console.log(error.response);
        });
};

const toggleMenuContextual = function () {
    menuContextual.value = !menuContextual.value;
};

const documentExtension = computed(() => {
    if (props.document.path.includes("youtube")) {
        return "youtube";
    }
    if (props.document.document_type_id == 2) {
        return "";
    }
    return props.document.path.split(".").pop();
});

const documentIcon = computed(() => {
    let icon = "";

    if (props.document.path.includes("youtube")) {
        icon = "fa-brands fa-youtube";
    } else if (props.document.document_type_id == 2) {
        icon = "fa-duotone fa-folder";
    } else if (props.document.document_type_id == 3) {
        icon = "fa-duotone fa-film";
    } else {
        switch (documentExtension.value) {
            case "svg":
                icon = "fa-duotone fa-file-image";
                break;
            case "jpg":
                icon = "fa-duotone fa-file-image";
                break;
            case "jpeg":
                icon = "fa-duotone fa-file-image";
                break;
            case "png":
                icon = "fa-duotone fa-file-image";
                break;
            case "pdf":
                icon = "fa-duotone fa-file-pdf";
                break;
            case "docx":
                icon = "fa-duotone fa-file-word";
                break;
            case "doc":
                icon = "fa-duotone fa-file-word";
                break;
            case "xlsx":
                icon = "fa-duotone fa-file-excel";
                break;
            case "xls":
                icon = "fa-duotone fa-file-excel";
                break;
            case "pptx":
                icon = "fa-duotone fa-file-powerpoint";
                break;
            case "ppt":
                icon = "fa-duotone fa-file-powerpoint";
                break;
            default:
                icon = "fa-duotone fa-folder";
                break;
        }
    }

    return icon;
});
</script>
