import axios from "axios";

const state = {
    certifications: [],
    certificationAlert: {
        action: "",
        status: "",
        message: "",
        errors: [],
    },
};

const getters = {
    certificationAlert: (state) => state.certificationAlert,
    getCertifications: (state) => state.certifications,
    certification: (state) => (id) => {
        return state.certifications.find((cert) => cert.id == id);
    },
};

const mutations = {
    // DEFINITIVOS
    changeCertificationAlert(state, payload) {
        state.certificationAlert.action = payload.action;
        state.certificationAlert.status = payload.status;
        state.certificationAlert.message = payload.message;
        state.certificationAlert.errors = payload.errors;
    },
    clearCertificationAlert(state) {
        state.certificationAlert.action = "";
        state.certificationAlert.status = "";
        state.certificationAlert.message = "";
        state.certificationAlert.errors = [];
    },
    storeCertification(state, payload) {
        state.certifications.push(payload);
    },
    updateCertification(state, payload) {
        const certificationIndex = state.certifications.findIndex(
            (certIndex) => certIndex.id == payload.id
        );
        state.certifications.splice(certificationIndex, 1, payload);
    },
    deleteCertification(state, certification_id) {
        let certificationId = state.certifications.findIndex(
            (certification) => certification.id == certification_id
        );
        state.certifications.splice(certificationId, 1);
    },
    updateClientsInsideCertifications(
        state,
        { clientID, fieldKey, fieldValue }
    ) {
        console.log(clientID, fieldKey, fieldValue);
        state.certifications.forEach((certification) => {
            if (certification.client.id == clientID) {
                certification.client[fieldKey] = fieldValue;
            }
        });
    },

    // deleteCertificationService(state, payload){
    // 	const certificationIndex = state.certifications.findIndex(certindex => certIndex.id == payload.id);
    // 	state.certifications.splice(certificationIndex, 1, payload);
    // },
    // A REVISAR
    storeCertifications(state, certifications) {
        state.certifications = certifications;
    },

    storeCertificationServices(state, payload) {
        let certificationPayloadId = payload[0].certification_id;

        let certificationId = state.certifications.findIndex(
            (certState) => certState.id == certificationPayloadId
        );
        state.certifications[certificationId]["certification_phases"] = payload;
    },

    // updateCertification(state, certification){

    //     var certificationId = state.certifications.findIndex(certState => certState.id == certification.id)

    //     state.certifications[certificationId] = certification
    // },
    addCertificationEac(state, eac) {
        let certificationId = state.certifications.findIndex(
            (certState) => certState.id == eac.certification_id
        );

        state.certifications[certificationId].eacs.push(eac.eac);
    },
    deleteCertificationEac(state, eac) {
        let certificationId = state.certifications.findIndex(
            (certState) => certState.id == eac.certification_id
        );

        let eacId = state.certifications[certificationId].eacs.findIndex(
            (eacState) => eacState.id == eac.eac_id
        );

        state.certifications[certificationId].eacs.splice(eacId, 1);
    },

    updateCertificationMD(state, md) {
        const certificationId = state.certifications.findIndex(
            (certState) => certState.id == md.certification_id
        );

        const lastService =
            state.certifications[certificationId].certification_phases[
                state.certifications[certificationId].certification_phases
                    .length - 1
            ];

        const lastServiceId = state.certifications[
            certificationId
        ].certification_phases.findIndex(
            (certServ) => certServ.id == lastService.id
        );

        state.certifications[certificationId].certification_phases[
            lastServiceId
        ].man_days = md.man_days;
    },
    updateCertificationPhaseTwo(state, phase_two) {
        const certificationId = state.certifications.findIndex(
            (certState) => certState.id == phase_two.id
        );

        state.certifications[certificationId].phase_two = phase_two.phase_two;
    },
    storeCertificationService(state, service) {
        const certificationId = state.certifications.findIndex(
            (certState) => certState.id == service.certification_id
        );

        const actualService = state.certifications[
            certificationId
        ].certification_phases.find((certServ) => certServ.id == service.id);

        const actualServiceId = state.certifications[
            certificationId
        ].certification_phases.findIndex(
            (certServ) => certServ.id == service.id
        );

        if (actualServiceId == -1) {
            state.certifications[certificationId].certification_phases.push(
                service
            );
        } else if (service.id != actualService.id) {
            state.certifications[certificationId].certification_phases.push(
                service
            );
        } else {
            state.certifications[certificationId].certification_phases[
                actualServiceId
            ].events.push(service.events[service.events.length - 1]);
        }
    },
    updateCertificationData(state, certificationData) {
        const certificationId = state.certifications.findIndex(
            (certState) => certState.id == certificationData.certification_id
        );
        const certification = state.certifications.find(
            (certState) => certState.id == certificationData.certification_id
        );
        certification.certification_data = certificationData;
    },

    updateCertificationData(state, payload) {
        const certificationId = state.certifications.findIndex(
            (certIndex) => certIndex.id == payload.certification_id
        );
        state.certifications[certificationId]["certification_data"] = payload;
    },
    storeCertificationData(state, payload) {
        const certificationId = state.certifications.findIndex(
            (certIndex) => certIndex.id == payload.certification_id
        );
        state.certifications[certificationId]["certification_data"] = payload;
    },
};

const actions = {
    // DEFINITIVOS
    storeCertification({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "certifications",
                    {
                        client_id: payload.client_id,
                        norm_id: payload.norm_id,
                        accreditation_id: payload.accreditation_id,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("storeCertification", response.data.data);
                    let popupPayload = {
                        appears: true,
                        message: "Certificación creada correctamente",
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve(response.data.data);
                })
                .catch((error) => {
                    let popupPayload = {
                        appears: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });

                    reject(error.response);
                });
        });
    },
    updateCertification({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    "certifications/" + payload.id,
                    {
                        client_id: payload.client_id,
                        norm_id: payload.norm_id,
                        accreditation_id: payload.accreditation_id,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("updateCertification", response.data.data);
                    let popupPayload = {
                        appears: true,
                        message: `Certificación actualizada correctamente`,
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve(response.data.data);
                })
                .catch((error) => {
                    let popupPayload = {
                        appears: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    reject(error.response);
                });
        });
    },
    deleteCertification({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    "certifications/" + payload.id,
                    {},
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("deleteCertification", payload.id);
                    let popupPayload = {
                        appears: true,
                        message: `Certificación ${payload.norm.norm} ${payload.accreditation.accreditation} eliminada correctamente`,
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve(response.data.data);
                })
                .catch((error) => {
                    let popupPayload = {
                        appears: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });

                    reject(error.response);
                });
        });
    },
    updateCertificationService({ commit, rootGetters }, payload) {
        axios
            .put(
                "certifications/" +
                    payload.certification_id +
                    "/phases/" +
                    payload.id,
                {
                    phase_id: payload.phase_id,
                    expires: payload.expires,
                    man_days: payload.man_days,
                    has_cert: payload.has_cert,
                    emit_cert: payload.emit_cert,
                    scheduled_at: payload.scheduled_at,
                    docs_expiration: payload.docs_expiration,
                    docs: payload.docs,
                    veto: payload.veto,
                    completed: payload.completed,
                    invoice_id: payload.invoice_id,
                    comments: payload.comments,
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("updateCertification", response.data.data);
            })
            .catch((error) => {
                console.log("error: ", error);
            });
    },
    updateEacNormInCertification({ commit, rootGetters }, payload) {
        axios
            .put(
                "certifications/" +
                    payload.certification_id +
                    "/eacs/" +
                    payload.eac_norm_id,
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("updateCertification", response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },

    //A REVISAR

    storeCertifications({ commit, rootGetters }) {
        axios
            .get("certifications", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((response) => {
                commit("storeCertifications", response.data.data);
            })
            .catch((error) => {
                console.log(
                    "error en storeCertifications (state): ",
                    error.response
                );
            });
    },

    // ELIMINAR
    // updateCertification({commit, rootGetters}, certification){
    //     axios.put('certifications/' + certification.id, {
    //         norm_id: certification.norm,
    //         accreditation_id: certification.accreditation,
    //         certification_step_id: certification.certstep,
    //         phase_two: certification.fase_two,
    //         emite: certification.emite,
    //         tiene: certification.tiene,
    //         next_step_id: certification.nextstep,
    //     }, {
    // 		headers: {
    // 			Authorization: 'Bearer ' + rootGetters['user/getToken']
    // 			}
    // 	})
    //     .then((response) => {
    //         console.log('updateCert action response', response.data.data)
    //         commit('updateCertification', response.data.data)
    //     })
    //     .catch((error) => {
    //         console.log(error.response)
    //     })
    // },

    // updateCertificationData({commit, rootGetters}, payload) {
    // 	axios.put('certifications/' + payload.certification_id + '/certificationdata/' + payload.id, {
    // 		initial_date: payload.initial_date,
    // 		initial_md: payload.initial_md,
    // 		m12_date: payload.m12_date,
    // 		m12_md: payload.m12_md,
    // 		m24_date: payload.m24_date,
    // 		m24_md: payload.m24_md,
    // 		recert_date: payload.recert_date,
    // 		recert_md: payload.recert_md,
    // 	}, {
    // 		headers: { Authorization: 'Bearer ' + rootGetters['user/getToken'] }
    // 	})
    //     .then((response) => {
    //         commit('updateCertificationData', response.data.data)
    //     })
    //     .catch((error) => {
    //         console.log(error.response)
    //     })
    // },
    // storeCertificationData({commit, rootGetters}, payload) {
    // 	console.log('action store cert data: ', payload)
    // 	axios.post('certifications/' + payload.certification_id + '/certificationdata/', {
    // 		initial_date: payload.initial_date,
    // 		initial_md: payload.initial_md,
    // 		// m12_date: payload.m12_date,
    // 		m12_md: payload.m12_md,
    // 		// m24_date: payload.m24_date,
    // 		m24_md: payload.m24_md,
    // 		// recert_date: payload.recert_date,
    // 		recert_md: payload.recert_md,
    // 	}, {
    // 		headers: { Authorization: 'Bearer ' + rootGetters['user/getToken'] }
    // 	})
    //     .then((response) => {
    //         commit('storeCertificationData', response.data.data)
    //     })
    //     .catch((error) => {
    //         console.log(error.response)
    //     })
    // },
    editInitialDate({ commit, rootGetters }, { newDate, certification }) {
        axios
            .put(
                "certifications/" +
                    certification.id +
                    "/certificationdata/" +
                    certification.certification_data.id,
                {
                    initial_date: newDate,
                }
            )
            .then((response) => {
                commit("editInitialDate", response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },

    // updateClient({commit, rootGetters}, zoho_id){
    // 	axios.post('clients', {
    // 		zoho_id: zoho_id
    // 	}, {
    // 		headers: {
    // 			Authorization: 'Bearer ' + rootGetters['user/getToken']
    // 			}
    // 	})
    // 	.then((response) => {
    // 		commit('updateClient', response.data.data)
    // 	})
    // 	.catch((error) => {
    // 		console.log(error.response)
    // 	})
    // },
    // storeNewCert({commit, rootGetters}, certification ){

    // 	axios.post('certifications', {
    // 		client_id: certification.client_id,
    // 		norm_id: certification.norm,
    // 		accreditation_id: certification.accreditation,
    // 		certification_step_id: certification.certstep,
    // 		fase_two: certification.fase_two,
    // 		emite: certification.emite,
    // 		tiene: certification.tiene,
    // 		next_step_id: certification.nextstep,
    // 	}, {
    // 		headers: {
    // 			Authorization: 'Bearer ' + rootGetters['user/getToken']
    // 			}
    // 	})
    // 	.then((response) => {

    // 		commit('storeNewCert', response.data.data)

    // 	})
    // 	.catch((error) => {
    // 		console.log('storeNewCert error', error)
    // 	})
    // },
    // editCertification({commit, rootGetters}, certification) {
    // 	axios.put('certifications/' + certification.id, {
    // 		client_id: certification.client_id,
    // 		norm_id: certification.norm,
    // 		accreditation_id: certification.accreditation,
    // 		certification_step_id: certification.certstep,
    // 		fase_two: certification.fase_two,
    // 		emite: certification.emite,
    // 		tiene: certification.tiene,
    // 		next_step_id: certification.nextstep,
    // 	}, {
    // 		headers: {
    // 			Authorization: 'Bearer ' + rootGetters['user/getToken']
    // 			}
    // 	})
    // 	.then((response) => {
    // 		commit('editCertification', response.data.data)
    // 	})
    // 	.catch((error) => {
    // 		console.log('editCertification action: ', error.response)
    // 	})
    // }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
