<script setup>
import { ref, reactive, onMounted } from "vue";
import DashboardCard from "./DashboardCard.vue";
import axios from "axios";
import { useUserStore } from "../stores/user";
import { update } from "lodash";

const certifications = ref([]);
const user = useUserStore();

const canUndo = ref(false);
const completedPhase = reactive({
    certificationId: null,
    phaseId: null,
    finished: null,
});

async function loadUnfinishedPhases() {
    await axios
        .get("v2/dashboard/certifications/unfinished", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.token,
            },
        })
        .then((res) => {
            certifications.value = res.data;
        });
}

onMounted(() => {
    loadUnfinishedPhases();
});

async function updateCompletedPhase(certificationId, phaseId, completed) {
    console.log("bla");
    await axios
        .put(
            `/v2/certifications/${certificationId}/phases/${phaseId}`,
            {
                completed: completed,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.token,
                },
            }
        )
        .then((response) => {
            if (completed === 1) {
                completedPhase.certificationId = certificationId;
                completedPhase.phaseId = phaseId;
                completedPhase.completed = completed;
                canUndo.value = true;
                loadUnfinishedPhases();
                setTimeout(() => {
                    canUndo.value = false;
                    completedPhase.certificationId = null;
                    completedPhase.phaseId = null;
                    completedPhase.completed = null;
                }, 6000);
            }
            if (completed === 0) {
                canUndo.value = false;
                loadUnfinishedPhases();
            }
        })
        .catch((error) => {
            console.error(error);
        });
}
</script>
<template>
    <DashboardCard>
        <template #header> Certificaciones sin finalizar</template>

        <template #subheader
            >Certificaciones cuyo servicio no se dió por finalizado en el
            sistema, tendiendo las condiciones para hacerlo.</template
        >

        <template #body>
            <ul class="h-60 overflow-y-auto" v-if="certifications.length > 0">
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="(certification, index) in certifications"
                    :key="index"
                >
                    <div class="col-span-2" v-if="certification.client">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                            v-if="certification.client"
                        >
                            {{ certification.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            <span
                                class="font-normal"
                                v-if="
                                    certification.norm &&
                                    certification.accreditation
                                "
                            >
                                <span v-if="certification.norm">{{
                                    certification.norm.norm
                                }}</span>
                                <span v-if="certification.accreditation">
                                    -
                                    {{
                                        certification.accreditation
                                            .accreditation
                                    }}</span
                                >
                            </span>
                        </p>
                    </div>
                    <div class="col-span-1 flex justify-center">
                        <div
                            class="flex flex-col justify-center items-center w-1/4"
                        >
                            <button
                                class="hover:bg-tuvgreen p-2 rounded-lg bg-tuvgreen/20"
                                @click="
                                    updateCompletedPhase(
                                        certification.id,
                                        certification
                                            .first_opened_certification_phase
                                            .id,
                                        1
                                    )
                                "
                            >
                                Etapa finalizada
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
            <div
                class="bg-tuvyellow/40 p-2 rounded-lg relative"
                :class="{ hidden: !canUndo }"
            >
                <div
                    class="absolute h-full rounded-lg top-0 left-0 bg-tuvyellow z-0"
                    :class="{ 'expand-animation': canUndo }"
                ></div>
                <div class="relative flex gap-2 z-10">
                    <span>Etapa finalizada.</span>
                    <button
                        class="underline"
                        @click="
                            updateCompletedPhase(
                                completedPhase.certificationId,
                                completedPhase.phaseId,
                                0
                            )
                        "
                    >
                        Deshacer
                    </button>
                </div>
            </div>
        </template>
    </DashboardCard>
</template>

<style scoped>
@keyframes expandWidth {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.expand-animation {
    animation: expandWidth 5s linear;
}
</style>
