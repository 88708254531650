<template>
    <div class="bg-tuvblue py-8 px-4 xl:px-8 cursor-default rounded">
        <span class="w-100 text-4xl text-center text-slate-50 font-black block">
            <slot name="counter"></slot>
        </span>
        <h2 class="text-center uppercase text-slate-50 text-xs">
            <slot name="title"></slot>
        </h2>
    </div>
</template>
