<script>
import axios from "axios";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import { mapGetters, mapActions } from "vuex";
import Modal from "../../views/spa/_elements/Modal.vue";
import BlueButton from "../components/BlueButton.vue";
import moment from "moment";

export default {
    props: ["user"],
    components: {
        FullCalendar,
        Modal,
        BlueButton,
    },
    data() {
        return {
            isLoading: false,
            serviceData: {
                id: null,
                title: null,
                date: null,
                confirmed: null,
            },
            alert: {
                message: "",
                type: "",
            },
        };
    },
    computed: {
        type() {
            return "alert-" + this.alert.type;
        },
        ...mapGetters({
            auditss: "auditss/audits",
            availabilities: "availabilities/availabilities",
            getAuditByDate: "auditss/getAuditByDate",
            checkIfDayIsNotAvailableToSchedule:
                "availabilities/checkIfDayIsNotAvailableToSchedule",
        }),
        service() {
            console.log(this.serviceData);
            return this.serviceData;
        },
        // availabilitiesBk(){
        //     return this.user.availabilities.map(function(availability){
        //         return { 'start': availability.date, 'display': 'background'}
        //     })
        // },
        // audits(){
        //     return this.user.audits.map(function(audit) {
        //         console.log(audit)
        //         return { 'start': audit.date, 'title': audit.title }
        //     })
        // },
        config() {
            return {
                ...this.configOptions,
                ...this.eventHandlers,
            };
        },
        configOptions() {
            console.log("audis", this.confirmedAudits);
            return {
                firstDay: 0,

                locale: esLocale,
                // eventSources: [
                //     this.availabilitiesBk,
                //     this.audits,
                // ],
                eventSources: [
                    {
                        events: this.availabilities,
                        display: "background",
                        backgroundColor: "#FA3747",
                    },
                    {
                        events: this.confirmedAudits,
                        display: "background",
                        classNames: ["auditss-audit-confirmed"],
                    },
                    {
                        events: this.tempAudits,
                        display: "background",
                        backgroundColor: "#FEEB02",
                        classNames: ["temp-audit"],
                    },
                ],
                // events: this.availabilitiesBk,
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                contentHeight: "auto",
                selectOverlap: false,
                progressiveEventRendering: true,
            };
        },
        eventHandlers() {
            return {
                dateClick: this.handleDateCLick,
                select: this.handleSelect,
                loading: this.handleLoading,
                eventClick: this.handleEventClick,
            };
        },
        confirmedAudits() {
            return this.$store.state.auditss.auditss.filter(
                (audit) => audit.confirmed == true
            );
        },
        tempAudits() {
            return this.$store.state.auditss.auditss.filter(
                (audit) => audit.confirmed == false || audit.confirmed == null
            );
        },
    },
    methods: {
        ...mapActions({
            storeAudits: "auditss/storeAudits",
            deleteAudits: "auditss/deleteAudits",
            deleteAvailabilites: "availabilities/deleteAvailabilities",
            // 'storeHolidaysBatch': 'holidays/storeHolidaysBatch',
            storeAvailabilitiesBatch: "availabilities/storeAvailabilitiesBatch",
            storeAuditsBatch: "auditss/storeAuditsBatch",
            deleteAudit: "auditss/deleteAudit",
            updateAudit: "auditss/updateAudit",
        }),
        handleLoading() {
            this.isLoading = !this.isLoading;
        },
        handleDateCLick(date) {
            if (this.checkIfDayIsNotAvailableToSchedule(date.dateStr)) {
                let popupPayload = {
                    appears: true,
                    message:
                        "No se pueden crear servicios en días marcados como no disponible.",
                    type: "info",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                return;
            }

            if (
                moment(date.dateStr).isoWeekday() == 6 ||
                moment(date.dateStr).isoWeekday() == 7
            ) {
                let popupPayload = {
                    appears: true,
                    message:
                        "No se pueden crear servicios los fines de semana.",
                    type: "info",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                return;
            }

            if (!this.getAuditByDate(date.dateStr)) {
                this.auditTitle = prompt("Crear servicio");

                if (this.auditTitle === "" || this.auditTitle === null) {
                    return;
                }

                // this.auditIsTemp = confirm("¿Desea reservar la fecha?");

                const audits = [
                    {
                        title: this.auditTitle,
                        date: date.dateStr,
                        user_id: this.user.id,
                        confirmed: 0,
                    },
                ];

                let popupPayload = {
                    appears: true,
                    message: "Creando el servicio como reservado.",
                    type: "loading",
                };
                this.$store.commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });

                this.storeAudits(audits)

                    .then((response) => {
                        let popupPayload = {
                            appears: true,
                            message:
                                "Se creó el servicio correctamente. Recuerde confirmarlo para notificar al auditor",
                            type: "success",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    })
                    .catch((error) => {
                        let popupPayload = {
                            appears: true,
                            message:
                                "Hubo un error al intentar crear el servicio.",
                            type: "error",
                        };
                        this.$store.commit(
                            "miscelaneous/changePopup",
                            popupPayload,
                            { root: true }
                        );
                    });
            }
        },
        handleSelect(args) {
            console.log(args);
        },
        handleEventClick(event) {
            if (this.checkIfDayIsNotAvailableToSchedule(event.event.startStr)) {
                return;
            }
            this.serviceData.id = event.event.id;
            (this.serviceData.title = event.event.title),
                (this.serviceData.date = event.event.startStr),
                (this.serviceData.confirmed = event.event.extendedProps
                    .confirmed
                    ? 1
                    : 0),
                this.$refs.serviceUpdateModal.openModal();
        },
        deleteAuditBtn(audit) {
            this.deleteAudit(audit.id)
                .then((response) => {
                    this.alert.message =
                        "Se eliminó el servicio " +
                        audit.title +
                        " correctamente.";
                    this.alert.type = "success";
                    this.$refs.serviceUpdateModal.closeModal();
                })
                .catch((error) => {
                    this.alert.message =
                        "Hubo un error al intentar eliminar el servicio.";
                    this.alert.type = "danger";
                    this.$refs.serviceUpdateModal.closeModal();
                });
        },
        updateAuditBtn(audit) {
            let formData = new FormData(
                document.getElementById("updateAuditForm")
            );

            if (audit.confirmed) {
                this.alert.message =
                    "Modificando el servicio y notificando al auditor.";
            } else {
                this.alert.message = "Modificando el servicio.";
            }

            this.alert.type = "warning";
            this.$refs.serviceUpdateModal.closeModal();
            this.updateAudit({ audit, formData })
                .then((response) => {
                    this.$refs.fullCalendar.$emit("rerender-events");
                    this.alert.message =
                        "Se modificó el servicio " +
                        audit.title +
                        " correctamente.";
                    this.alert.type = "success";
                })
                .catch((error) => {
                    this.alert.message =
                        "Hubo un error al intentar modificar el servicio.";
                    this.alert.type = "danger";
                });
        },
    },
    created() {
        this.deleteAudits;
        this.deleteAvailabilites;
        // axios.get('holidays',{
        //     headers: {
        //         Authorization: 'Bearer ' + this.$store.getters['user/getToken']
        //     }
        // })
        // .then((responseHolidyas) => {
        //     this.storeHolidaysBatch(responseHolidyas.data.data)
        const id = this.$route.params.id;
        axios
            .get("users/" + id + "/availabilities", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((responseAvailabilities) => {
                this.storeAvailabilitiesBatch(responseAvailabilities.data.data);

                axios
                    .get("users/" + id + "/auditss", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    })
                    .then((responseAudits) => {
                        this.storeAuditsBatch(responseAudits.data.data);
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            })
            .catch((error) => {
                console.log(error.response);
            });
        // })
        // .catch((error) => {
        //     console.log(error.response)
        // })
    },
};
</script>

<template>
    <div>
        <div class="mx-2">
            <div id="UserCalendar">
                <div
                    class="flex flex-row justify-center p-4 relative mx-auto lg:top-12"
                >
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvgreen"
                    ></div>
                    <div class="leading-4 align-middle">Disponible</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvred"
                    ></div>
                    <div class="leading-4 align-middle">No disponilbe</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvyellow"
                    ></div>
                    <div class="leading-4 align-middle">Reservado</div>
                    <div
                        class="h-4 w-4 rounded-full my-0 mr-2 ml-2 md:ml-8 bg-tuvblue"
                    ></div>
                    <div class="leading-4 align-middle">Servicio</div>
                </div>

                <FullCalendar ref="fullCalendar" :options="config" />
            </div>
            <!-- <blue-button>BLABLA</blue-button> -->
        </div>

        <modal ref="serviceUpdateModal">
            <template v-slot:header>
                <h2 class="text-xl">{{ service.title }}</h2>
            </template>

            <template v-slot:body>
                <div>
                    <form
                        @submit.prevent
                        id="updateAuditForm"
                        name="updateAuditForm"
                    >
                        <div class="flex flex-row justify-between">
                            <div>
                                <input
                                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                    name="title"
                                    id="title"
                                    type="text"
                                    v-model="serviceData.title"
                                />
                            </div>
                            <div>
                                <input
                                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                    name="date"
                                    id="date"
                                    type="date"
                                    v-model="serviceData.date"
                                />
                            </div>
                            <!-- <input type="checkbox" name="confirmed" value="yes" id="confirmed" v-model="serviceData.confirmed"> -->
                            <div
                                class="flex flex-col justify-center items-center w-1/3"
                            >
                                <span class="font-bold text-slate-500"
                                    >Servicio confirmado</span
                                >
                                <input
                                    type="checkbox"
                                    id="confirmed"
                                    name="confirmed"
                                    v-model="serviceData.confirmed"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="updateAuditBtn(service)"
                    >
                        Guardar
                    </button>
                    <button
                        class="bg-neutral-200 hover:bg-neutral-300 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.serviceUpdateModal.closeModal()"
                    >
                        Cancelar
                    </button>
                    <button
                        class="bg-rose-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="deleteAuditBtn(service)"
                    >
                        Eliminar
                    </button>

                    <!-- <button class="button blue-button">Guardar</button> -->
                </div>
            </template>
        </modal>
    </div>
</template>

<style lang="scss">
.overflow-hidden {
    overflow: hidden;
}

#UserCalendar .fc-bg-event {
    opacity: 1 !important;
}
</style>
