const getDefaultUserState = () => {
	return {
		email: '',
		id_card: '',
		first_name: '',
		last_name: '',
		token: '',
		roles: [],
		isLoggedIn: false
	}	
}

const state = getDefaultUserState()

const getters = {
	getToken(state) {
		return state.token
	},
	getLoggedIn(state){
		return state.isLoggedIn
	},
	getUserRoleByName(state) {
		return state.roles[0]
	}
};

const mutations = {
	storeToken(state, token){
		state.token = token
		state.isLoggedIn = true
	},
	storeUserData(state, userData){
		if(userData){
			state.id = userData.userData.id
            state.email = userData.userData.email
            state.id_card = userData.userData.persona.id_card
            state.first_name = userData.userData.persona.first_name
            state.last_name = userData.userData.persona.last_name

            userData.userData.roles.forEach((role) => {
                state.roles.push(role.role)
            })
		}
	},
	deleteUserState(state){
		Object.assign(state, getDefaultUserState())
	}
};

const actions = {
	storeToken({commit}, token){
		commit('storeToken', token)	
	},
	deleteToken({commit}){
		return new Promise((resolve, reject) => {
			commit('deleteToken')
			resolve()
		})
	},
	storeUserData({commit, state}, userData){

		commit('storeUserData', {userData: userData})
	},
	deleteUserState({commit}){
		commit('deleteUserState')
	}
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}