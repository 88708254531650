import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "./stores/user";

import Login from "./views/PageLogin.vue";
import AppContainer from "./components/AppContainer.vue";
import Home from "./views/PageHome.vue";

// DASHBOARD
import Dashboard from "./views/PageDashboard.vue";
import DashboardOperations from "./views/PageDashboardOperations.vue";
import DashboardAuditors from "./views/PageDashboardAuditors.vue";
import DashboardAdmin from "./views/PageDashboardAdmin.vue";
import DashboardAnalysis from "./views/PageDashboardAnalysis.vue";

// CERTIFICATIONS
import Certifications from "./views/PageCertifications.vue";

// AUDITORS
import Auditors from "./views/PageAuditors.vue";
import AuditorsList from "./views/PageAuditorsList.vue";
import Auditor from "./views/PageAuditor.vue";
import AuditorCalendar from "./views/PageAuditorCalendar.vue";

// INVOICES
import Invoices from "./views/PageInvoices.vue";

// DOCUMENTS
import Documents from "./views/PageDocuments.vue";

// AKADEMIE
import Akademie from "./views/PageAkademie.vue";
import AkademieCourses from "./views/PageAkademieCourses.vue";
import AkademieCertificates from "./views/PageAkademieCertificates.vue";

// USERS
import Users from "./views/PageUsers.vue";
import UsersList from "./views/PageUsersList.vue";
import User from "./views/PageUser.vue";
import UserCalendar from "./views/PageUserCalendar.vue";
import UserEdit from "./views/PageUserEdit.vue";

// SETTINGS
import Settings from "./views/PageSettings.vue";
import SettingsNorms from "./views/PageSettingsNorms.vue";

import SettingsEacs from "./views/PageSettingsEacs.vue";

// import Auditor from "../views/spa/pages/auditor/Contenedor.vue";
// import AuditorInicio from "../views/spa/pages/auditor/Inicio.vue";
// import AuditorMiPerfil from "../views/spa/pages/auditor/Perfil.vue";
// import AuditorCalendario from "../views/spa/pages/auditor/Calendario.vue";

/**
 * Creación de rutas para la aplicación
 */
const routes = [
    {
        path: "/",
        redirect: { name: "login" },
        meta: { requiresAuth: false },
    },
    {
        path: "/ingresar",
        name: "login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/panel",
        name: "panel.container",
        component: AppContainer,
        meta: { requiresAuth: true },
        children: [
            {
                path: "home",
                name: "home",
                component: Home,
                meta: { requiresAuth: true },
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: Dashboard,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: "operaciones",
                        component: DashboardOperations,
                        name: "dashboardoperations",
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "auditores",
                        component: DashboardAuditors,
                        name: "dashboardauditors",
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "administracion",
                        component: DashboardAdmin,
                        name: "dashboardadmin",
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "analisis",
                        component: DashboardAnalysis,
                        name: "dashboardanalysis",
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: "certificaciones",
                name: "certifications",
                component: Certifications,
                meta: { requiresAuth: true },
            },
            {
                path: "auditores",
                component: Auditors,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: "",
                        name: "auditorsList",
                        component: AuditorsList,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: ":id",
                        component: Auditor,
                        meta: { requiresAuth: true },
                        children: [
                            {
                                path: "calendario",
                                name: "auditorcalendar",
                                component: AuditorCalendar,
                                meta: { requiresAuth: true },
                            },
                        ],
                    },
                ],
            },
            {
                path: "facturacion",
                name: "invoices",
                component: Invoices,
                meta: { requiresAuth: true },
            },
            {
                path: "documentos",
                name: "documents",
                component: Documents,
                meta: { requiresAuth: true },
            },
            {
                path: "akademie",
                component: Akademie,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: "",
                        name: "akademie.courses",
                        component: AkademieCourses,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "certificados",
                        name: "akademiecertificates",
                        component: AkademieCertificates,
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: "usuarios",
                component: Users,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: "",
                        name: "userslist",
                        component: UsersList,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: ":id",
                        component: User,
                        meta: { requiresAuth: true },
                        children: [
                            {
                                path: "calendario",
                                name: "usercalendar",
                                component: UserCalendar,
                                meta: { requiresAuth: true },
                            },
                            {
                                path: "editar",
                                name: "useredit",
                                component: UserEdit,
                                meta: { requiresAuth: true },
                            },
                        ],
                    },
                ],
            },
            {
                path: "ajustes",
                name: "settings",
                component: Settings,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: "normas-acreditaciones",
                        name: "settingsnorms",
                        component: SettingsNorms,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "alcances",
                        name: "settingseacs",
                        component: SettingsEacs,
                        meta: { requiresAuth: true },
                    },
                ],
            },
        ],
    },

    /**
     * Rol auditor
     */
    // {
    //     path: "/auditor",
    //     component: Auditor,
    //     meta: { requiresAuth: true },
    //     children: [
    //         {
    //             path: "inicio",
    //             name: "AuditorInicio",
    //             component: AuditorInicio,
    //             meta: { requiresAuth: true },
    //         },
    //         {
    //             path: "mi-perfil",
    //             name: "AuditorMiPerfil",
    //             component: AuditorMiPerfil,
    //             meta: { requiresAuth: true },
    //         },
    //         {
    //             path: "calendario",
    //             name: "AuditorCalendario",
    //             component: AuditorCalendario,
    //             meta: { requiresAuth: true },
    //         },
    //     ],
    // },
];

/**
 * Crear instancia router para la navegación
 */
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

/**
 * Route navigation guard
 * Si la ruta require que el usuario esté logueado o require
 * que el suaurio sea administrador, chequear con info meta
 * en cada ruta
 */
router.beforeEach((to, from) => {
    const user = useUserStore();
    if (to.meta.requiresAuth && !user.isLoggedIn) {
        return {
            path: "/ingresar",
        };
    }
    if (to.meta.isOperator && !user.roles.includes("operador")) {
        return {
            path: "/ingresar",
        };
    }
});

export default router;
