<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUsersStore } from "../stores/users";

import { mapActions } from "vuex";
import Modal from "../../views/spa/_elements/Modal.vue";
import AppModal from "./AppModal.vue";
import AppButton from "./AppButton.vue";
import axios from "axios";

const usersStore = useUsersStore();

const props = defineProps({
    user: {
        required: true,
        type: Object,
    },
    roles: {
        required: true,
    },
    usersFilter: {
        required: true,
    },
});

const route = useRoute();
const isAuditorPage = computed(() => {
    return route.name == "auditorsList";
});

// ACTUALIZAR USUARIO
const updateName = ref(false);
const updateUsername = ref(false);
const updateEmail = ref(false);
const updateRole = ref(false);

const resetUpdateUserForm = function () {
    updateUserForm.value.first_name = "";
    updateUserForm.value.last_name = "";
    updateUserForm.value.username = "";
    updateUserForm.value.email = "";
    updateUserForm.value.password = "";
    updateUserForm.value.role_id = "";
};
const resetUpdateUserToggles = function () {
    updateName.value = false;
    updateUsername.value = false;
    updateEmail.value = false;
    updateRole.value = false;
};
const updateUserForm = ref({
    first_name: props.user.persona.first_name,
    last_name: props.user.persona.last_name,
    username: props.user.username,
    email: props.user.email,
    role_id: props.user?.roles[0]?.id,
});

const updateUser = function () {
    usersStore.updateUser(props.user.id, updateUserForm.value);
    // resetUpdateUserForm();
    resetUpdateUserToggles();
};

const changePasswordModal = ref(null);
const changePassword = function () {
    usersStore.changePassword(props.user.id, updateUserForm.value);
    changePasswordModal.value.closeModal();
};

// FUNCIONES DE RUTEO
const router = useRouter();
const toUserCalendar = function () {
    router.push({
        name: "usercalendar",
        params: { id: props.user.id },
    });
};

const toEditUser = function () {
    router.push({ name: "useredit", params: { id: props.user.id } });
};

// ELIMINAR Y SUSPENDER USUARIO
const suspendUserModal = ref(null);
const suspendUser = function () {
    usersStore.suspendUser(props.user.id);
    suspendUserModal.value.closeModal();
};
const restoreUser = function () {
    usersStore.restoreUser(props.user.id);
};

const deleteUserModal = ref(null);
const deleteUser = function () {
    usersStore.deleteUser(props.user.id);
    deleteUserModal.value.closeModal();
};
// deleteUserFn(id) {
//     this.deleteUser(id);
//     this.$refs.eliminarUsuario.closeModal();
// },

//     async updateUserFn() {
//         if (this.updateUserForm.password) {
//             this.$refs.cambiarPassword.closeModal();
//             let popupPayload = {
//                 appears: true,
//                 message: "Enviando notificación al usuario",
//                 type: "loading",
//             };
//             this.$store.commit("miscelaneous/changePopup", popupPayload, {
//                 root: true,
//             });
//         }

//         await this.updateUsers(this.updateUserForm);

//         // axios
//         //     .put(`users/${this.user.id}`)
//         //     .then((response) => {
//         //         this.user = response.data.data;
//         //         let popupPayload = {
//         //             appears: true,
//         //             message: "Usuario actualizado correctamente",
//         //             type: "success",
//         //         };
//         //         this.$store.commit(
//         //             "miscelaneous/changePopup",
//         //             popupPayload,
//         //             {
//         //                 root: true,
//         //             }
//         //         );
//         //         this.clearUpdateUserFormStatus();
//         //     })
//         //     .catch((error) => {
//         //         console.log("error", error);
//         //         // let popupPayload = {
//         //         //     appears: true,
//         //         //     message: error.responsedata.errors,
//         //         //     type: "error",
//         //         // };
//         //         // this.$store.commit(
//         //         //     "miscelaneous/changePopup",
//         //         //     popupPayload,
//         //         //     { root: true }
//         //         // );
//         //     });

//         // this.updateUser(this.updateUserForm)
//         //     .then((response) => {
//         //         let popupPayload = {
//         //             appears: true,
//         //             message: "Usuario actualizado correctamente",
//         //             type: "success",
//         //         };
//         //         this.$store.commit(
//         //             "miscelaneous/changePopup",
//         //             popupPayload,
//         //             { root: true }
//         //         );
//         //         this.clearUpdateUserFormStatus();
//         //     })
//         //     .catch((error) => {
//         //         let popupPayload = {
//         //             appears: true,
//         //             message: error.responsedata.errors,
//         //             type: "error",
//         //         };
//         //         this.$store.commit(
//         //             "miscelaneous/changePopup",
//         //             popupPayload,
//         //             { root: true }
//         //         );
//         //     });
//     },

//     clearUpdateUserFormStatus() {
//         if (this.updateUserFormStatus.changeUsername)
//             this.updateUserFormStatus.changeUsername = false;
//         if (this.updateUserFormStatus.changeEmail)
//             this.updateUserFormStatus.changeEmail = false;
//         if (this.updateUserFormStatus.changeName)
//             this.updateUserFormStatus.changeName = false;
//         if (this.updateUserFormStatus.changeRole)
//             this.updateUserFormStatus.changeRole = false;
//         this.updateUserFormStatus.errors.status = null;
//         this.updateUserFormStatus.errors.errors = null;
//     },
//     resetUpdateUserForm() {
//         this.updateUserForm.user_id = this.user.id;
//         this.updateUserForm.first_name = this.user.persona.first_name;
//         this.updateUserForm.last_name = this.user.persona.last_name;
//         this.updateUserForm.username = this.user.username;
//         this.updateUserForm.email = this.user.email;
//         this.updateUserForm.password = null;
//         this.updateUserForm.role_id = this.user.roles[0].id;
//     },
// },
// };
</script>

<template>
    <tr class="text-xs px-4 py-2 transition-all duration-500">
        <td class="table-cell border-none p-2 leading-8 capitalize">
            <span v-if="updateName">
                <input
                    class="w-5/12 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl capitalize"
                    type="text"
                    v-model="updateUserForm.last_name"
                    @keyup.esc="
                        updateName = false;
                        resetUpdateUserForm();
                    "
                    @keyup.enter="updateUser"
                />
                <input
                    class="w-5/12 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl capitalize"
                    type="text"
                    v-model="updateUserForm.first_name"
                    @keyup.esc="
                        updateName = false;
                        resetUpdateUserForm();
                    "
                    @keyup.enter="updateUser"
                />
                <font-awesome-icon
                    icon="fa-duotone fa-xmark"
                    class="cursor-pointer"
                    @click="
                        updateName = false;
                        resetUpdateUserForm();
                    "
                />
            </span>
            <span v-else>
                {{ user.persona.last_name }}, {{ user.persona.first_name }}
                <font-awesome-icon
                    icon="fa-duotone fa-pen"
                    class="cursor-pointer mr-4"
                    @click="updateName = true"
                    v-if="!user.deleted_at"
                />
            </span>
        </td>
        <td class="table-cell border-none p-2 leading-8">
            <span v-if="updateUsername">
                <input
                    class="w-10/12 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    type="text"
                    v-model="updateUserForm.username"
                    @keyup.esc="
                        updateUsername = false;
                        resetUpdateUserForm();
                    "
                    @keyup.enter="updateUser"
                />
                <font-awesome-icon
                    icon="fa-duotone fa-xmark"
                    class="cursor-pointer"
                    @click="
                        updateUsername = false;
                        resetUpdateUserForm();
                    "
                />
            </span>
            <span v-else>
                {{ user.username }}
                <font-awesome-icon
                    icon="fa-duotone fa-pen"
                    class="cursor-pointer"
                    @click="updateUsername = true"
                    v-if="!user.deleted_at"
            /></span>
        </td>
        <td class="table-cell border-none p-2 leading-8">
            <span v-if="updateEmail">
                <input
                    class="w-10/12 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    type="text"
                    v-model="updateUserForm.email"
                    @keyup.esc="
                        updateEmail = false;
                        resetUpdateUserForm();
                    "
                    @keyup.enter="updateUser"
                />
                <font-awesome-icon
                    icon="fa-duotone fa-xmark"
                    class="cursor-pointer"
                    @click="
                        updateEmail = false;
                        resetUpdateUserForm();
                    "
                />
            </span>
            <span v-else>
                {{ user.email }}
                <font-awesome-icon
                    icon="fa-duotone fa-pen"
                    class="cursor-pointer"
                    @click="updateEmail = true"
                    v-if="!user.deleted_at"
            /></span>
        </td>

        <td class="table-cell border-none p-2 leading-8">
            <span v-if="updateRole">
                <select
                    class="w-10/12 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl inline-block"
                    v-model="updateUserForm.role_id"
                    @keyup.esc="
                        updateRole = false;
                        resetUpdateUserForm();
                    "
                    @change="updateUser"
                >
                    <option
                        v-for="(role, index) in roles"
                        :key="index"
                        :value="role.id"
                    >
                        {{ role.role }}
                    </option>
                </select>
                <font-awesome-icon
                    icon="fa-duotone fa-xmark"
                    class="cursor-pointer"
                    @click="
                        updateRole = false;
                        resetUpdateUserForm();
                    "
                />
            </span>

            <span v-else>
                <span
                    class="capitalize"
                    v-for="role in user.roles"
                    :key="role.id"
                    >{{ role.role }}</span
                >
                <font-awesome-icon
                    icon="fa-duotone fa-pen"
                    class="cursor-pointer"
                    @click="updateRole = true"
                    v-if="!user.deleted_at && !isAuditorPage"
            /></span>
        </td>

        <td
            class="flex flex-row border-none text-sm p-2"
            v-if="user.deleted_at"
        >
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Restaurar usuario"
                @click="restoreUser"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-person-circle-check']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.6"
                />
            </button>
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Eliminar usuario"
                @click="deleteUserModal.openModal()"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-person-circle-xmark']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.6"
                />
            </button>
        </td>
        <td class="flex flex-row border-none justify-end text-sm p-2" v-else>
            <button
                class="bg-tuvgray hover:bg-tuvlightgray cursor-pointer px-5 py-3 uppercase tracking-wide text-sm text-tuvblack ml-2 text-right"
                v-title
                title="Editar usuario"
                @click="toEditUser(user.id)"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-list']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.3"
                />
            </button>
            <button
                class="bg-tuvgray hover:bg-tuvlightgray cursor-pointer px-5 py-3 uppercase tracking-wide text-sm text-tuvblack ml-2 text-right"
                v-title
                title="Ver agenda del usuario"
                @click="toUserCalendar(user.id)"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-calendar-lines']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.3"
                />
            </button>
            <button
                class="bg-tuvgray hover:bg-tuvlightgray cursor-pointer px-5 py-3 uppercase tracking-wide text-sm text-tuvblack ml-2 text-right"
                v-title
                title="Modificar contraseña"
                @click="changePasswordModal.openModal()"
                v-if="!isAuditorPage"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-lock-keyhole']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.3"
                />
            </button>
            <button
                class="bg-tuvred hover:bg-rose-600 cursor-pointer px-5 py-3 uppercase tracking-wide text-sm text-white ml-2 text-right"
                @click="suspendUserModal.openModal()"
                v-title
                title="Suspender usuario"
                v-if="!isAuditorPage"
            >
                <font-awesome-icon
                    :icon="['fad', 'fa-person-circle-minus']"
                    class="text-xl"
                    style="--fa-secondary-opacity: 0.7"
                />
            </button>
        </td>

        <modal ref="suspendUserModal">
            <template v-slot:header>
                <h2 class="text-xl">Suspender usuario</h2>
                <p class="text-neutral-500">
                    <span class="uppercase"
                        >{{ user.persona.last_name }},
                        {{ user.persona.first_name }}</span
                    >
                </p>
            </template>

            <template v-slot:body>
                <div>¿Está seguro que quiere suspender a este usuario?</div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-neutral-200 hover:bg-neutral-300 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow transition-shadow ease-out mr-2"
                        @click="suspendUserModal.closeModal()"
                    >
                        No, cancelar
                    </button>
                    <button
                        class="bg-rose-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="suspendUser"
                    >
                        Si, suspender
                    </button>
                </div>
            </template>
        </modal>

        <modal ref="deleteUserModal">
            <template v-slot:header>
                <h2 class="text-xl">Eliminar usuario</h2>
                <p class="text-neutral-500">
                    <span class="uppercase"
                        >{{ user.persona.last_name }},
                        {{ user.persona.first_name }}</span
                    >
                </p>
            </template>

            <template v-slot:body>
                <div>
                    ¿Está seguro que quiere eliminar definitivamente a este
                    usuario?
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-neutral-200 hover:bg-neutral-300 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow transition-shadow ease-out mr-2"
                        @click="deleteUserModal.closeModal()"
                    >
                        No, cancelar
                    </button>
                    <button
                        class="bg-rose-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="deleteUser"
                    >
                        Si, eliminar
                    </button>
                </div>
            </template>
        </modal>

        <app-modal ref="changePasswordModal">
            <template #title> Cambiar contraseña </template>

            <template #subtitle>
                <span
                    >Ingrese una nueva contraseña para el usuario
                    {{ user.persona.last_name }},
                    {{ user.persona.first_name }}</span
                >
            </template>

            <template #body>
                <form @submit.prevent>
                    <label for="password">Nueva Contraseña</label>
                    <input
                        v-model="updateUserForm.password"
                        id="password"
                        name="password"
                        type="password"
                        class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray self-center w-full mb-0 outline-none focus:outline-none my-2 autofill:bg-tuvgray"
                    />
                </form>
            </template>

            <template #actions>
                <div class="flex flex-row justify-end gap-4">
                    <AppButton
                        class="bg-tuvgray text-tuvblack"
                        @click="changePasswordModal.closeModal()"
                        >Cancelar</AppButton
                    >
                    <AppButton
                        class="bg-tuvblue text-white"
                        @click="changePassword"
                        >Cambiar contraseña</AppButton
                    >
                </div>
            </template>
        </app-modal>
    </tr>
</template>
