<script lang="ts" setup>
import { computed } from "vue";

const type = defineProps<{
    type?: string;
}>();

const alertType = computed(() => {
    console.log(type);
    switch (type.type) {
        case "error":
            return "bg-tuvred text-white";
            break;
        case "warning":
            return "bg-tuvyellow text-tuvblack";
            break;
        default:
            return "bg-tuvlightgray text-tuvblack";
    }
});
</script>
<template>
    <div
        class="p-4 mt-2 flex flex-row items-center justify-between"
        :class="alertType"
    >
        <slot></slot>
    </div>
</template>
