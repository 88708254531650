<script>
export default {
    name: "Modal",
    data() {
        return {
            show: false
        };
    },
    methods: {
        closeModal() {
            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
        },
        openModal() {
            this.show = true;
            document.querySelector("body").classList.add("overflow-hidden");
        }
    }
};
</script>

<template>
    <Transition name="fade">
        <div class="fixed overflow-x-hidden overflow-y-auto top-0 bottom-0 right-0 left-0 z-20" v-if="show">
            <div class="fixed bg-slate-800/90 top-0 bottom-0 right-0 left-0 z-40" @click="closeModal()"/>
                <div class="rounded-xl relative bg-slate-200 w-11/12 mx-auto lg:w-1/2 mb-0 mt-8 flex flex-col z-50">
                    <button type="button" class="rounded-full shadow shadow-neutral-500/25 cursor-pointer bg-slate-300 absolute -right-3 -top-3" @click="closeModal()">
                        <i class="fa-duotone fa-circle-xmark text-3xl fa-swap-opacity shadow-lg rounded-full" style="--fa-primary-color: #475569; --fa-secondary-color: #CBD5E1; --fa-secondary-opacity: 1.0; --fa-primary-opacity: 1.0;"></i>
                    </button>
                    <div class="px-6 pt-6">
                        <slot name="header"/>
                        
                    </div>

                    <div class="px-6 py-6 overflow-auto">
                        <slot name="body"/>
                    </div>

                    <div class="px-6 pb-6">
                        <slot name="footer"/>
                    </div>
                </div>
            </div>
      </Transition>
</template>



<style lang="scss" scoped>

.fade-enter-active {
    transition: opacity 0.4s;
}
.fade-leave-active {
    transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>