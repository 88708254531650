<template>
    <Bar :data="dataModif" :options="options" />
</template>

<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    components: {
        ChartDataLabels,
        Bar,
    },
    data() {
        return {
            labels: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
            graphColors: ["#3245D3", "#00A5CF", "#73CBA3"],
        };
    },
    props: {
        options: {
            type: Object,
        },
        selectedData: {
            type: Object,
        },
    },
    computed: {
        dataModif() {
            var data = [];
            if (Object.keys(this.selectedData).length > 0) {
                var colorIndex = 0;
                for (let [key, value] of Object.entries(this.selectedData)) {
                    var yearTotal = Object.values(value).reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue,
                        0
                    );
                    data.push({
                        label: key + " (total: " + yearTotal + ")",
                        backgroundColor: this.graphColors[colorIndex],
                        data: Object.values(value),
                    });
                    colorIndex++;
                }
            }
            return {
                labels: this.labels,
                datasets: data,
            };
        },
    },
    methods: {
        completeWithMonths(elements) {
            let completed = [];

            let year = elements[0].year;

            for (let i = 1; i <= 12; i++) {
                var result = elements.find((element) => element.month == i);

                if (result === undefined) {
                    completed.push({ man_days: 0, year: year, month: i });
                } else {
                    completed.push(result);
                }
            }
            return completed;
        },
        // renderBarChart() {
        //     this.renderChart(
        //         {
        //             labels: this.labels,
        //             datasets: this.datasets,
        //         },
        //         this.options
        //     );
        // },
    },
    // watch: {
    //     selectedData() {
    //         this.renderBarChart();
    //     },
    // },
    // mounted() {
    //     this.renderBarChart();
    // },
};
</script>
