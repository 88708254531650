<script>
import axios from "axios";
import UsersCertificationsItem from "../components/UsersCertificationsItem.vue";

export default {
    components: {
        UsersCertificationsItem,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // user norm order
            normOrderDown: true,
            accreditationOrderDown: true,
            eacOrderDown: true,
            statusOrderDown: true,

            certification: {
                norm_id: null,
                eacNorm_id: null,
                accreditation_id: null,
                status_id: null,
            },
            openFiltersMenu: false,
            filters: {
                norm: "",
                accreditation: "",
                eac: "",
                status: "",
            },

            addUserCertification: false,
            // wantToEdit: null,
            loadingUserCertifications: false,
            userNorms: [],
            norms: [],
            accreditations: [],
            eacs: [],
            status: [],
        };
    },
    created() {
        axios
            .all([
                this.getNorms(),
                this.getAccreditations(),
                // this.getEacs(),
                this.getStatus(),
                this.loadUserNorms(),
            ])
            .then(
                axios.spread((...results) => {
                    this.norms = results[0].data.data;
                    this.accreditations = results[1].data.data;
                    // this.eacs = results[2].data.data;
                    this.status = results[2].data.data;
                    this.userNorms = results[3].data.data;
                })
            )
            .catch((errors) => {
                console.log(errors);
            })
            .finally(() => {
                this.loadingUserCertifications = false;
            });
    },
    computed: {
        filteredCertifications() {
            let userNorms = this.userNorms;

            userNorms.sort((a, b) => {
                if (
                    this.normOrderDown &&
                    this.accreditationOrderDown &&
                    this.eacOrderDown
                ) {
                    return (
                        a.eac_norm.norm.norm.localeCompare(
                            b.eac_norm.norm.norm
                        ) ||
                        a.accreditation.accreditation.localeCompare(
                            b.accreditation.accreditation
                        ) ||
                        a.eac_norm.eac.eac - b.eac_norm.eac.eac
                    );
                } else if (
                    this.normOrderDown &&
                    !this.accreditationOrderDown &&
                    this.eacOrderDown
                ) {
                    return (
                        a.eac_norm.norm.norm.localeCompare(
                            b.eac_norm.norm.norm
                        ) ||
                        b.accreditation.accreditation.localeCompare(
                            a.accreditation.accreditation
                        ) ||
                        a.eac_norm.eac.eac - b.eac_norm.eac.eac
                    );
                } else if (
                    !this.normOrderDown &&
                    this.accreditationOrderDown &&
                    this.eacOrderDown
                ) {
                    return (
                        b.eac_norm.norm.norm.localeCompare(
                            a.eac_norm.norm.norm
                        ) ||
                        a.accreditation.accreditation.localeCompare(
                            b.accreditation.accreditation
                        ) ||
                        a.eac_norm.eac.eac - b.eac_norm.eac.eac
                    );
                } else if (
                    !this.normOrderDown &&
                    !this.accreditationOrderDown &&
                    this.eacOrderDown
                ) {
                    return (
                        b.eac_norm.norm.norm.localeCompare(
                            a.eac_norm.norm.norm
                        ) ||
                        b.accreditation.accreditation.localeCompare(
                            a.accreditation.accreditation
                        ) ||
                        a.eac_norm.eac.eac - b.eac_norm.eac.eac
                    );
                } else if (
                    this.normOrderDown &&
                    this.accreditationOrderDown &&
                    !this.eacOrderDown
                ) {
                    return (
                        a.eac_norm.norm.norm.localeCompare(
                            b.eac_norm.norm.norm
                        ) ||
                        a.accreditation.accreditation.localeCompare(
                            b.accreditation.accreditation
                        ) ||
                        b.eac_norm.eac.eac - a.eac_norm.eac.eac
                    );
                } else if (
                    this.normOrderDown &&
                    !this.accreditationOrderDown &&
                    !this.eacOrderDown
                ) {
                    return (
                        a.eac_norm.norm.norm.localeCompare(
                            b.eac_norm.norm.norm
                        ) ||
                        b.accreditation.accreditation.localeCompare(
                            a.accreditation.accreditation
                        ) ||
                        b.eac_norm.eac.eac - a.eac_norm.eac.eac
                    );
                } else if (
                    !this.normOrderDown &&
                    this.accreditationOrderDown &&
                    !this.eacOrderDown
                ) {
                    return (
                        b.eac_norm.norm.norm.localeCompare(
                            a.eac_norm.norm.norm
                        ) ||
                        a.accreditation.accreditation.localeCompare(
                            b.accreditation.accreditation
                        ) ||
                        b.eac_norm.eac.eac - a.eac_norm.eac.eac
                    );
                } else if (
                    !this.normOrderDown &&
                    !this.accreditationOrderDown &&
                    !this.eacOrderDown
                ) {
                    return (
                        b.eac_norm.norm.norm.localeCompare(
                            a.eac_norm.norm.norm
                        ) ||
                        b.accreditation.accreditation.localeCompare(
                            a.accreditation.accreditation
                        ) ||
                        b.eac_norm.eac.eac - a.eac_norm.eac.eac
                    );
                }
            });

            if (this.filters.norm) {
                userNorms = userNorms.filter(
                    (cert) =>
                        cert.eac_norm &&
                        cert.eac_norm.norm_id == this.filters.norm
                );
            }

            if (this.filters.accreditation) {
                userNorms = userNorms.filter(
                    (cert) =>
                        cert.accreditation_id == this.filters.accreditation
                );
            }
            if (this.filters.eac) {
                userNorms = userNorms.filter(
                    (cert) => cert.eac_norm.id == this.filters.eac
                );
            }
            if (this.filters.status) {
                userNorms = userNorms.filter(
                    (cert) => cert.status_id == this.filters.status
                );
            }

            return userNorms;
        },
        normEacs() {
            if (this.certification.norm_id || this.filters.norm) {
                let results = this.norms.filter(
                    (norm) =>
                        norm.id === this.certification.norm_id ||
                        norm.id === this.filters.norm
                )[0].eacs_norms;

                results.sort((a, b) =>
                    a.eac.eac.localeCompare(b.eac.eac, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    })
                );

                return results;
            }
        },
    },
    methods: {
        getNorms() {
            return axios.get("norms");
        },
        getAccreditations() {
            return axios.get("accreditations");
        },
        // getEacs() {
        //     return axios.get("eacss");
        // },
        getStatus() {
            return axios.get("status");
        },
        loadUserNorms() {
            this.loadingUserCertifications = true;
            return axios.get("users/" + this.$route.params.id + "/norms");
        },
        toggleEditForm(certification) {
            this.wantToEdit = certification.id;
            this.certification.norm_id = certification.norm_id;
            this.certification.accreditation_id =
                certification.accreditation_id;
            this.certification.status_id = certification.status_id;
            this.certification.eacNorm_id = certification.eacNorm_id;
        },
        closeEdifForm() {
            this.wantToEdit = null;
            this.certification.norm_id = null;
            this.certification.accreditation_id = null;
            this.certification.status_id = null;
            this.certification.eacNorm_id = null;
        },
        storeUserCertification() {
            axios
                .post("users/" + this.$route.params.id + "/norms", {
                    user_id: this.user.id,
                    accreditation_id: this.certification.accreditation_id,
                    status_id: this.certification.status_id,
                    eac_norm_id: this.certification.eacNorm_id,
                })
                .then((response) => {
                    this.userNorms.push(response.data.data);
                    this.certification.norm_id = null;
                    this.certification.accreditation_id = null;
                    this.certification.eacNorm_id = null;
                    this.certification.status_id = null;
                })
                .catch((error) => {
                    console.log(error.response);
                })
                .finally(() => {
                    this.addUserCertification = false;
                });
        },
        deleteUserCertification(id) {
            axios
                .delete("users/" + this.$route.params.id + "/norms/" + id, {})
                .then((response) => {
                    this.userNorms.splice(
                        this.userNorms.findIndex(
                            (eac_norm) => eac_norm.id == response.data.data.id
                        ),
                        1
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateUserCertification(payload, id) {
            axios
                .put(
                    "users/" + this.$route.params.id + "/norms/" + id,
                    {
                        norm_id: payload.norm_id,
                        accreditation_id: payload.accreditation_id,
                        eac_norm_id: payload.eac_norm_id,
                        status_id: payload.status_id,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    const updatedData = response.data.data;
                    const certificationIndex = this.userNorms.findIndex(
                        (id) => id.id == response.data.data.id
                    );
                    this.userNorms.splice(certificationIndex, 1, updatedData);
                })
                .catch((error) => {
                    console.log("error updateUserCertification: ", error);
                });
        },
        onAddCertification() {
            axios
                .post("auditors", {
                    user_id: this.$route.params.id,
                    eac_id: this.auditors.eac_id,
                    accreditation_id: this.auditors.accreditation_id,
                    norm_id: this.auditors.norm_id,
                    status_id: this.auditors.status_id,
                })
                .then((response) => {
                    var newCertification = response.data.data;
                    this.$emit("updateUserCertifications", newCertification);
                    // this.changeAddCertStatus();
                    this.auditors.norm_id = null;
                    this.auditors.status_id = null;
                    this.auditors.accreditation_id = null;
                    this.auditors.eac_id = null;
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        clearFilters() {
            this.filters.norm = "";
            this.filters.accreditation = "";
            this.filters.eac = "";
            this.filters.status = "";
        },
    },
};
</script>
<template>
    <div>
        <div class="mb-4 flex flex-row items-center w-1/2">
            <h2 class="text-xl font-bold inline-block">Certificaciones</h2>

            <div class="w-ful my-2 md:col-span-4 xl:col-span-3 md:ml-2">
                <button
                    v-title
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                    @click="addUserCertification = true"
                    title="Agregar certificación"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-file-circle-plus"
                        class="text-xl text-slate-600"
                    />
                </button>

                <button
                    v-title
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer disabled:cursor-not-allowed px-6 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none disabled:shadow transition-shadow ease-out duration-100 mr-2 text-slate-600 disabled:text-slate-400"
                    title="Filtrar certificaciones"
                    @click="openFiltersMenu = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-filter-list"
                        class="text-xl"
                    />
                </button>
            </div>
        </div>

        <div
            class="flex flex-row justify-start items-center bg-slate-300 p-4 rounded-xl text-neutral-800 transition-all ease-in-out"
            v-if="openFiltersMenu"
        >
            <div class="flex flex-col justify-start mr-4">
                <span class="mr-4 font-bold">Norma: </span>
                <select
                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2 h-9"
                    v-model="filters.norm"
                >
                    <option
                        v-for="norm in norms"
                        :key="norm.id"
                        :value="norm.id"
                    >
                        {{ norm.norm }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col justify-start mr-4">
                <span class="mr-4 font-bold">Acreditación: </span>
                <select
                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2 h-9"
                    v-model="filters.accreditation"
                >
                    <option
                        v-for="accreditation in accreditations"
                        :key="accreditation.id"
                        :value="accreditation.id"
                    >
                        {{ accreditation.accreditation }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col justify-start mr-4">
                <span class="mr-4 font-bold">EAC: </span>
                <select
                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2 h-9"
                    v-model="filters.eac"
                >
                    <option
                        v-for="normEac in normEacs"
                        :key="normEac.id"
                        :value="normEac.id"
                    >
                        <span v-if="normEac.eac">{{ normEac.eac.eac }}</span>
                        <span v-if="normEac.risk">{{ normEac.risk.risk }}</span>
                    </option>
                </select>
            </div>
            <div class="flex flex-col justify-start mr-4">
                <span class="mr-4 font-bold">Status: </span>
                <select
                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2 h-9"
                    v-model="filters.status"
                >
                    <option
                        v-for="status in status"
                        :key="status.id"
                        :value="status.id"
                    >
                        {{ status.status }}
                    </option>
                </select>
            </div>
            <div class="flex flex-row justify-center items-center ml-4">
                <span
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer disabled:cursor-not-allowed px-6 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none disabled:shadow transition-shadow ease-out duration-100 mr-2 text-slate-600 disabled:text-slate-400"
                    @click="
                        clearFilters();
                        openFiltersMenu = false;
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-filter-slash"
                        class="text-xl text-slate-600"
                    />
                </span>
            </div>
        </div>

        <table class="table w-full border-collapse border-none rounded text-sm">
            <thead class="rounded-t table-row-group">
                <tr class="table-row">
                    <th
                        class="table-cell font-bold w-1/5 border-none text-gray-800 text-left p-4"
                    >
                        <span class="uppercase">Norma</span>
                        <button
                            class="appearance-none ml-2"
                            @click="normOrderDown = !normOrderDown"
                        >
                            <template v-if="normOrderDown">
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-down-a-z fa-lg"
                                    ></i>
                                </span>
                            </template>
                            <template v-else>
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-up-z-a fa-lg"
                                    ></i>
                                </span>
                            </template>
                        </button>
                    </th>
                    <th
                        class="table-cell font-bold w-1/5 border-none text-gray-800 text-left p-4"
                    >
                        <span class="uppsercase">Acreditación</span>
                        <button
                            class="appearance-none ml-2"
                            @click="
                                accreditationOrderDown = !accreditationOrderDown
                            "
                        >
                            <template v-if="accreditationOrderDown">
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-down-a-z fa-lg"
                                    ></i>
                                </span>
                            </template>
                            <template v-else>
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-up-z-a fa-lg"
                                    ></i>
                                </span>
                            </template>
                        </button>
                    </th>
                    <th
                        class="table-cell font-bold w-1/5 border-none text-gray-800 text-left p-4"
                    >
                        <span class="uppercase">Eac</span>
                        <button
                            class="appearance-none ml-2"
                            @click="eacOrderDown = !eacOrderDown"
                        >
                            <template v-if="eacOrderDown">
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-down-a-z fa-lg"
                                    ></i>
                                </span>
                            </template>
                            <template v-else>
                                <span>
                                    <i
                                        class="fa-duotone fa-arrow-up-z-a fa-lg"
                                    ></i>
                                </span>
                            </template>
                        </button>
                    </th>
                    <th
                        class="table-cell font-bold w-1/5 border-none text-gray-800 text-left p-4"
                    >
                        <span class="uppercase">Categoría</span>
                    </th>
                    <th
                        class="table-cell font-bold w-1/5 border-none text-gray-800 text-left p-4"
                    >
                        Acciones
                    </th>
                </tr>
            </thead>

            <tbody v-if="loadingUserCertifications">
                Cargando certificaciones...
            </tbody>

            <tbody v-else>
                <tr class="max-w-full" v-if="addUserCertification">
                    <td class="table-cell border-none text-sm p-2">
                        <select
                            class="h-8 w-full py-1"
                            name="norm_id"
                            id="norm"
                            v-model="certification.norm_id"
                        >
                            <option
                                v-for="norm in norms"
                                :key="norm.id"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                    </td>
                    <td class="table-cell border-none text-sm p-2">
                        <select
                            class="h-8 w-full py-1"
                            name="accreditation_id"
                            id="accreditation"
                            v-model="certification.accreditation_id"
                        >
                            <option
                                v-for="accreditation in accreditations"
                                :key="accreditation.id"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                    </td>
                    <td class="table-cell border-none text-sm p-2">
                        <select
                            class="h-8 w-full py-1"
                            name="eac_id"
                            id="eac"
                            v-model="certification.eacNorm_id"
                        >
                            <option
                                v-for="normEac in normEacs"
                                :key="normEac.id"
                                :value="normEac.id"
                            >
                                <span v-if="normEac.eac && normEac.eac.eac"
                                    >{{ normEac.eac.eac }}
                                    <span v-if="normEac.risk">
                                        {{ normEac.risk.risk }}</span
                                    ></span
                                >
                            </option>
                        </select>
                    </td>
                    <td class="table-cell border-none text-sm p-2">
                        <select
                            class="h-8 w-full py-1"
                            name="status_id"
                            id="status"
                            v-model="certification.status_id"
                        >
                            <option
                                v-for="stat in status"
                                :key="stat.id"
                                :value="stat.id"
                            >
                                {{ stat.status }}
                            </option>
                        </select>
                    </td>
                    <td class="table-cell border-none text-sm p-2">
                        <span
                            class="cursor-pointer h-full mr-4 p-2 hover:bg-gray-200 rounded"
                            @click="storeUserCertification()"
                        >
                            <i class="fal fa-save card__item-action__icon"></i
                            ><span class="card__item-action__text">
                                Guardar</span
                            >
                        </span>
                        <span
                            class="cursor-pointer h-full mr-4 p-2 hover:bg-gray-200 rounded"
                            @click="addUserCertification = false"
                        >
                            <i
                                class="fal fa-times-circle card__item-action__icon"
                            ></i
                            ><span class="card__item-action__text">
                                Cancelar</span
                            >
                        </span>
                    </td>
                </tr>
                <div v-if="filteredCertifications.length == 0">
                    No se encontraron certificaciones...
                </div>

                <users-certifications-item
                    v-for="certification in filteredCertifications"
                    :key="certification.id"
                    :certification="certification"
                    :norms="norms"
                    :accreditations="accreditations"
                    :eacs="eacs"
                    :status="status"
                    @delete-certification="
                        deleteUserCertification(certification.id)
                    "
                    @update-certification="updateUserCertification"
                ></users-certifications-item>
            </tbody>
        </table>
    </div>
</template>
