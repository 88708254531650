import axios from "axios";

const state = {
	accreditations: [],
};

const getters = {
	
};

const mutations = {
    storeAccreditations(state, accreditations){
        state.accreditations = accreditations;
    },
    storeAccreditation(state, payload){
        state.accreditations.push(payload);

    },
    updateAccreditation(state, payload){
        const accreditationIndex = state.accreditations.findIndex(accreditation => accreditation.id == payload.id);
        state.accreditations.splice(accreditationIndex, 1, payload);
        
    },
    deleteAccreditation(state, payload){
        const accreditationIndex = state.accreditations.findIndex(accreditation => accreditation.id == payload.id);
        state.accreditations.splice(accreditationIndex, 1);
        
    },
};

const actions = {
    storeAccreditations({ commit, rootGetters }){
        axios.get('accreditations', {
                headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
            })
            .then((response) => {
                commit('storeAccreditations', response.data.data)
            })
            .catch((error) => {
                console.log('error en storeAccreditations (store): ', error.response)
            })
    },
    storeAccreditation({ commit, rootGetters }, payload) {
        axios.post('accreditations', {
            accreditation: payload.accreditation,
            description: payload.description,
        }, {
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('storeAccreditation', response.data.data);
            Accreditation.insert({
                data: response.data.data
            });
            let popupPayload = {
                appears: true,
                message: `Acreditación ${payload.accreditation} creada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response)
            let popupPayload = {
                appears: true,
                message: `No pudo crearse la acreditación ${payload.accreditation}`,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    updateAccreditation({ commit, rootGetters }, payload) {
        axios.put('accreditations/' + payload.id, {
            accreditation: payload.accreditation,
            description: payload.description,
        }, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('updateAccreditation', response.data.data);
            Accreditation.update({
                where: payload.id,
                data: response.data.data,
            });
            let popupPayload = {
                appears: true,
                message: `Acreditación ${payload.accreditation} actualizada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
    deleteAccreditation({ commit, rootGetters }, payload) {
        axios.delete('accreditations/' + payload.id, { 
            headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
        })
        .then((response) => {
            commit('deleteAccreditation', response.data.data);
            Accreditation.delete(payload.id)
            let popupPayload = {
                appears: true,
                message: `Acreditación ${payload.accreditation} eliminada correctamente`,
                type: 'success',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
        .catch((error) => {
            console.log(error.response.data)
            let errors = {...error.response.data.errors}
            let popupPayload = {
                appears: true,
                message: errors,
                type: 'error',
            }
            commit('miscelaneous/changePopup', popupPayload, {root: true} )
        })
    },
};

export default {
    namespaced: true,
	state,
	actions,
	mutations,
	getters
}