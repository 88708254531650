<script>
import DashboardCardsContainer from "../components/DashboardCardsContainer.vue";
import CardMdPerMonthYear from "../components/CardMDPerMonthYear.vue";
import CardGraphNormsProportion from "../components/CardGraphNormsProportion.vue";
import CardGraphCertificationsPerClient from "../components/CardGraphCertificationsPerClient.vue";

export default {
    name: "DashboardAnalysis",
    components: {
        DashboardCardsContainer,
        CardMdPerMonthYear,
        CardGraphNormsProportion,
        CardGraphCertificationsPerClient,
    },
};
</script>

<template>
    <DashboardCardsContainer>
        <CardMdPerMonthYear class="col-span-3" />
        <card-graph-norms-proportion
            class="col-span-3 row-span-2"
        ></card-graph-norms-proportion>
        <CardGraphCertificationsPerClient class="col-span-3 row-span-2" />
    </DashboardCardsContainer>
</template>
