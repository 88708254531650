import { useUserStore } from "../stores/user";

export function useRole() {
    const { roles } = useUserStore();

    if (roles.find((role) => role.role == "operador")) {
        return "operador";
    }

    return "auditor";
}
