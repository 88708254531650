<script>
import PageDashboardHeader from "../components/PageDashboardHeader.vue";
import PageSubMenu from "../components/PageSubMenu.vue";

export default {
    name: "DashboardContainer",
    components: {
        PageSubMenu,
        PageDashboardHeader,
    },
    data() {
        return {
            menuItems: {
                dashboard: {
                    name: "dashboardoperations",
                    item: "Operaciones",
                },
                auditors: {
                    name: "dashboardauditors",
                    item: "Auditores",
                },
                analisis: {
                    name: "dashboardanalysis",
                    item: "Análisis",
                },
                admin: {
                    name: "dashboardadmin",
                    item: "Administración",
                },
            },
        };
    },
};
</script>

<template>
    <div class="mx-8">
        <PageDashboardHeader />

        <PageSubMenu class="mb-4" :menuItems="menuItems"></PageSubMenu>

        <router-view class="mb-12 mx-2"></router-view>
    </div>
</template>
