<script>
export default {
    name: "PageSubMenuItem",
    props: {
        menuItem: {
            type: Object,
            required: true,
        },
    },
};
</script>

<template>
    <li
        class="rounded-md flex justify-center cursor-pointer transition-all duration-300"
    >
        <router-link
            :to="{ name: menuItem.name }"
            class="no-underline text-slate-400 px-4 py-2 text-sm transition-all duration-300"
            v-if="menuItem"
            >{{ menuItem.item }}</router-link
        >
    </li>
</template>

<style scoped lang="scss">
li:has(.router-link-exact-active) {
    a {
        text-decoration: underline;
        text-decoration-thickness: 0.2rem;
        text-underline-offset: 0.4rem;
        color: #2563eb;
    }
}
</style>
