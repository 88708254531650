<template>
    <div
        class="bg-slate-50 w-full my-2 p-4 grid grid-cols-12 content-center items-stretch h-16 gap-4 animate-pulse"
    >
        <!-- <span
            class="px-4 py-2 flex flex-col col-span-3 self-start bg-slate-200 h-8"
        >
        </span>
        <span
            class="px-4 py-2 col-span-1 self-start flex flex-col bg-slate-200 h-full"
        >
        </span>
        <span
            class="px-4 py-2 col-span-1 self-start flex flex-col bg-slate-200 h-full"
        >
        </span>
        <span
            class="px-4 py-2 col-span-2 self-start flex flex-col bg-slate-200 h-full"
        >
        </span>
        <span
            class="px-4 py-2 col-span-2 self-start flex flex-col bg-slate-200 h-full"
        >
        </span>
        <span class="col-span-3"></span> -->
    </div>
</template>
