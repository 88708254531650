import axios from "axios";
const state = {
    auditss: [],
};

const getters = {
    // auditss: state => state.auditss,
    auditss(state) {
        return state.auditss.map(function (audit) {
            let bgColor,
                txtColor = "";

            let classList = [];

            if (audit.confirmed == 1) {
                bgColor = "#001FD2";
                txtColor = "#FFFFFF";
                classList = ["auditss-audit-confirmed"];
            } else {
                bgColor = "#FEEB02";
                txtColor = "#000045";
            }

            return {
                id: audit.id,
                start: audit.start,
                status: audit.confirmed,
                title: audit.title,
                backgroundColor: bgColor,
                borderColor: bgColor,
                color: txtColor,
                display: "background",
                classNames: classList,
            };
        });
    },
    getAuditByDate: (state) => (date) => {
        return state.auditss.find((audit) => audit.start === date);
    },
};

const actions = {
    storeAuditsBatch({ commit }, auditss) {
        commit("deleteAudits");
        commit("storeAuditsBatch", auditss);
    },
    storeAudits({ commit, rootGetters }, auditss) {
        return axios
            .post(
                "auditss",
                {
                    auditss: JSON.stringify(auditss),
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                console.log("store auditos: ", response.data.data);
                commit("storeAudits", response.data.data);
                let popupPayload = {
                    appears: true,
                    message: "Servicio creado correctamente",
                    type: "success",
                };
                commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
            })
            .catch((error) => {
                let popupPayload = {
                    appears: true,
                    message: error.response.data.message,
                    type: "error",
                };
                commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                console.log(error);
                console.log("error de action storeAudits");
            });
    },
    deleteAudit({ commit, rootGetters }, audit) {
        return new Promise((resolve, reject) => {
            axios
                .delete("auditss/" + audit, {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                })
                .then((response) => {
                    commit("deleteAudit", audit);
                    let popupPayload = {
                        appears: true,
                        message: "Servicio eliminado correctamente",
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve();
                })
                .catch((error) => {
                    let popupPayload = {
                        appears: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    reject();
                });
        });
    },
    getUserAudits({ commit, rootGetters }, userId) {
        axios
            .get("users/" + userId + "/auditss", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((responseAudits) => {
                commit("deleteAudits");
                commit("storeAuditsBatch", responseAudits.data.data);
            })
            .catch((error) => {
                console.log("error getUserAudits action");
                let popupPayload = {
                    appears: true,
                    message: error.response.data.message,
                    type: "error",
                };
                commit("miscelaneous/changePopup", popupPayload, {
                    root: true,
                });
                console.log(error.response);
            });
    },
    updateAudit({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    "auditss/" + payload.audit.id,
                    {
                        title: payload.formData.get("title"),
                        date: payload.formData.get("date"),
                        confirmed: payload.formData.get("confirmed"),
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("updateAudit", payload);
                    let popupPayload = {
                        appears: true,
                        message: "Servicio actualizado correctamente",
                        type: "success",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    resolve();
                })
                .catch((error) => {
                    console.log(error.response);
                    let popupPayload = {
                        appears: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("miscelaneous/changePopup", popupPayload, {
                        root: true,
                    });
                    reject();
                });
        });
    },
};

const mutations = {
    storeAuditsBatch(state, auditss) {
        auditss.forEach((audit) =>
            state.auditss.push({
                id: audit.id,
                start: audit.date,
                title: audit.title,
                confirmed: audit.confirmed,
            })
        );
    },
    storeAudits(state, auditss) {
        auditss.forEach((audit) =>
            state.auditss.push({
                id: audit.id,
                start: audit.date,
                title: audit.title,
                confirmed: audit.confirmed,
            })
        );
    },
    deleteAudits(state) {
        state.auditss = [];
    },
    deleteAudit(state, audit_id) {
        let auditToDelete = state.auditss.findIndex(
            (audit) => audit.id == audit_id
        );
        state.auditss.splice(auditToDelete, 1);
    },
    updateAudit(state, payload) {
        let auditData = payload;

        // Eliminar la auditoría del store y volver a cargarla
        let auditToDelete = state.auditss.findIndex(
            (audit) => audit.id == auditData.audit.id
        );
        state.auditss.splice(auditToDelete, 1);

        state.auditss.push({
            id: auditData.audit.id,
            start: auditData.audit.date,
            title: auditData.audit.title,
            confirmed: auditData.audit.confirmed,
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
