import { defineStore } from "pinia";

export const useClientsStore = defineStore("clients", {
    state: () => {
        return {
            clients: [],
        };
    },
    actions: {
        storeClients(clients) {
            this.clients = clients;
        },
    },
});
