import axios from "axios";

const state = {
    servicesTemp: [],
};

const getters = {
    getServicesTemp(state) {
        return state.servicesTemp.map(function (serviceTemp) {
            let bgColor, txtColor;
            if (serviceTemp.status == "sin-agenda") {
                bgColor = "#4AEFCA";
                txtColor = "#000045";
            } else if (serviceTemp.status == "con-agenda") {
                bgColor = "#07BAE8";
                txtColor = "#000045";
            } else {
                bgColor = "#9FD356";
                txtColor = "#000045";
            }
            return {
                id: serviceTemp.id,
                start: serviceTemp.start,
                status: serviceTemp.status,
                title: serviceTemp.title,
                backgroundColor: bgColor,
                borderColor: bgColor,
                textColor: txtColor,
            };
        });
    },
    // audits: state => state.audits,
    // getAuditByDate: (state) => (date) => {
    // 	return state.audits.find(audit => audit.start === date)
    // }
};

const actions = {
    async storeServicesTempBatch({ commit, rootGetters }) {
        commit("deleteServicesTemp");
        await axios
            .get("services-temps", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((response) => {
                commit("storeServicesTempBatch", response.data.data);
            })
            .catch((error) => {
                console.log("error de action storeServicesTemp");
                console.log(error.response);
            });
    },
    // storeAuditsBatch({commit}, audits){
    //     commit('deleteAudits')
    //     commit('storeAuditsBatch', audits)
    // },
    storeServiceTemp({ commit, rootGetters }, serviceTemp) {
        return axios
            .post(
                "services-temps",
                {
                    date: serviceTemp[0].date,
                    title: serviceTemp[0].title,
                    status: serviceTemp[0].status,
                },
                {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                }
            )
            .then((response) => {
                commit("storeServiceTemp", response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("error de action storeServiceTemp");
            });
    },
    deleteServiceTemp({ commit, rootGetters }, serviceTemp) {
        return new Promise((resolve, reject) => {
            axios
                .delete("services-temps/" + serviceTemp, {
                    headers: {
                        Authorization: "Bearer " + rootGetters["user/getToken"],
                    },
                })
                .then((response) => {
                    commit("deleteServiceTemp", serviceTemp);
                    resolve();
                })
                .catch((error) => {
                    console.log(error.response);
                    reject();
                });
        });
    },
    updateServiceTemp({ commit, rootGetters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    "services-temps/" + payload.serviceData.id,
                    {
                        title: payload.formData.get("title"),
                        status: payload.formData.get("status"),
                        date: payload.formData.get("date"),
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then(() => {
                    commit("updateServiceTemp", payload);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

const mutations = {
    storeServicesTempBatch(state, servicesTemp) {
        servicesTemp.forEach((serviceTemp) =>
            state.servicesTemp.push({
                id: serviceTemp.id,
                start: serviceTemp.date,
                title: serviceTemp.title,
                status: serviceTemp.status,
            })
        );
    },
    storeServiceTemp(state, serviceTemp) {
        state.servicesTemp.push({
            id: serviceTemp.id,
            start: serviceTemp.date,
            title: serviceTemp.title,
            status: serviceTemp.status,
        });
    },
    deleteServicesTemp(state) {
        state.servicesTemp = [];
    },
    deleteServiceTemp(state, serviceTempId) {
        let serviceTempToDelete = state.servicesTemp.findIndex(
            (serviceTemp) => serviceTemp.id == serviceTempId
        );
        state.servicesTemp.splice(serviceTempToDelete, 1);
    },
    updateServiceTemp(state, payload) {
        let serviceTempData = payload;

        // Eliminar la auditoría del store y volver a cargarla
        let serviceTempToUpdate = state.servicesTemp.findIndex(
            (serviceTemp) => serviceTemp.id == serviceTempData.serviceData.id
        );
        state.servicesTemp.splice(serviceTempToUpdate, 1);

        state.servicesTemp.push({
            id: serviceTempData.serviceData.id,
            start: serviceTempData.serviceData.date,
            title: serviceTempData.serviceData.title,
            status: serviceTempData.serviceData.status,
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
