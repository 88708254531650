import axios from "axios";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useNumberOfUsersByRole } from "../composables/users";
import { useToast } from "vue-toastification";

const toast = useToast();

export const useUsersStore = defineStore("users", () => {
    const users = ref([]);

    async function getUsers() {
        await axios
            .get("users")
            .then((response) => {
                users.value = response.data.data;
            })
            .catch((error) => {
                console.log("error getUsers", error);
            });
    }

    async function storeUser(userData) {
        await axios
            .post("users", {
                first_name: userData.first_name,
                last_name: userData.last_name,
                username: userData.username,
                email: userData.email,
                password: userData.password,
                role_id: userData.role_id,
            })
            .then((response) => {
                users.value.push(response.data);
                toast.success("Usuario creado");
            })
            .catch((error) => {
                toast.error(
                    `Hubo un inconveniente al crear el usuario: ${error.response.data.message}`
                );
            });
    }

    async function updateUser(userId, userData) {
        await axios
            .put(`users/${userId}`, {
                first_name: userData.first_name,
                last_name: userData.last_name,
                username: userData.username,
                email: userData.email,
                role_id: userData.role_id,
            })
            .then((response) => {
                const userIndex = this.users.findIndex(
                    (user) => user.id == userId
                );
                this.users.splice(userIndex, 1, response.data.data);
                toast.success("Usuario actualizado correctamente");
            })
            .catch((error) => {
                console.log(error.response);
                toast.error("Hubo un inconveniente actualizando el usuario");
            });
    }

    async function suspendUser(userId) {
        await axios
            .delete(`users/${userId}`)
            .then((response) => {
                const userIndex = this.users.findIndex(
                    (user) => user.id == userId
                );
                this.users.splice(userIndex, 1, response.data.data);
                toast.success("Usuario suspendido en el sistema");
            })
            .catch((error) => {
                toast.error(
                    "Hubo un inconveniente intentando suspender al usuario"
                );
            });
    }

    async function restoreUser(userId) {
        await axios
            .put(`users/${userId}/restore`)
            .then((response) => {
                const userIndex = this.users.findIndex(
                    (user) => user.id == userId
                );
                this.users.splice(userIndex, 1, response.data.data);
                toast.success("Usuario restablecido en el sistema");
            })
            .catch((error) => {
                toast.error(
                    "Hubo un inconveniente intentando restablecer al usuario"
                );
            });
    }

    async function deleteUser(userId) {
        await axios
            .put(`users/${userId}/delete`)
            .then((response) => {
                const userIndex = this.users.findIndex(
                    (user) => user.id == userId
                );
                this.users.splice(userIndex, 1);
                toast.success("Usuario eliminado definitivamente del sistema");
            })
            .catch((error) => {
                toast.error(
                    "Hubo un inconveniente intentando eliminar al usuario"
                );
            });
    }

    async function changePassword(userId, userData) {
        await axios
            .put(`users/${userId}`, {
                password: userData.password,
            })
            .then((response) => {
                const userIndex = this.users.findIndex(
                    (user) => user.id == userId
                );
                this.users.splice(userIndex, 1, response.data.data);
                toast.success("Contraseña actualizada");
            })
            .catch((error) => {
                toast.error("Hubo un inconveniente actualizando la contraseña");
            });
    }

    const numberOfOperators = computed(() => {
        return useNumberOfUsersByRole(users, "operador");
    });

    const numberOfAuditors = computed(() => {
        return useNumberOfUsersByRole(users, "auditor");
    });

    return {
        users,
        getUsers,
        storeUser,
        updateUser,
        numberOfOperators,
        numberOfAuditors,
        suspendUser,
        restoreUser,
        deleteUser,
        changePassword,
    };
});
