import axios from "axios";
import { useUserStore } from "../../../stores/user";

const state = {
    availabilities: [],
};

const getters = {
    availabilities: (state) => state.availabilities,
    checkIfDayIsNotAvailableToSchedule: (state) => (date) => {
        if (
            state.availabilities.find(
                (availability) => availability.start == date
            )
        ) {
            return true;
        }
        return false;
    },

    getIfAvailabilityExists: (state) => (date) => {
        if (
            state.availabilities.find(
                (availability) => availability.start === date
            )
        ) {
            return true;
        }
        return false;
    },
    getAvailability: (state) => (date) => {
        return state.availabilities.find(
            (availability) => availability.start === date
        );
    },
};

const mutations = {
    storeAvailabilitiesBatch(state, availabilities) {
        availabilities.forEach((availability) =>
            state.availabilities.push({
                id: availability.id,
                start: availability.date,
                // title: 'No disponible',
                display: "background",
            })
        );
    },
    storeAvailabilities(state, availabilities) {
        availabilities.forEach((availability) =>
            state.availabilities.push({
                id: availability.id,
                start: availability.date,
                // title: 'No disponible',
                display: "background",
            })
        );
    },
    deleteAvailability(state, date) {
        const availability_index = state.availabilities.findIndex(
            (availability) => availability.start === date
        );
        state.availabilities.splice(availability_index, 1);
    },
    deleteAvailabilities(state) {
        state.availabilities = [];
    },
};

const actions = {
    /**
     * Cargar los días no disponibles al ingresar
     *
     */
    storeAvailabilitiesBatch({ commit }, availabilities) {
        return new Promise((resolve, reject) => {
            commit("deleteAvailabilities");
            commit("storeAvailabilitiesBatch", availabilities);
            resolve();
        });
    },
    storeAvailabilities({ commit, rootGetters }, availabilities) {
        const user = useUserStore();
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "availabilities",
                    {
                        dates: availabilities,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + user.token,
                            // "Bearer " + rootGetters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    commit("storeAvailabilities", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response);
                    reject(error);
                });
        });
    },
    deleteAvailability({ commit, getters, rootGetters }, date) {
        const availability_to_delete = getters.getAvailability(date);

        axios
            .delete("availabilities/" + availability_to_delete.id, {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then(() => {
                commit("deleteAvailability", availability_to_delete.start);
            })
            .catch((error) => {
                console.log("error de action deleteAvailability");
                console.log(error.response);
            });
    },
    deleteAvailabilities({ commit }) {
        commit("deleteAvailabilities");
    },
    getUserAvailabilites({ commit, rootGetters }, userId) {
        axios
            .get("users/" + userId + "/availabilities", {
                headers: {
                    Authorization: "Bearer " + rootGetters["user/getToken"],
                },
            })
            .then((responseAvailabilities) => {
                commit("deleteAvailabilities");
                commit(
                    "storeAvailabilitiesBatch",
                    responseAvailabilities.data.data
                );
            })
            .catch((error) => {});
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
