<script>
export default {
    props: {
        certification: {
            type: Object,
            required: true,
        },
        norms: {
            type: Array,
            required: true,
        },
        accreditations: {
            type: Array,
            required: true,
        },
        eacs: {
            type: Array,
            required: true,
        },
        status: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            // ok
            normEacs: null,

            // a revisar
            wantToEdit: false,
            updateCertificationData: {
                norm_id: this.certification.eac_norm.norm_id,
                eac_norm_id: this.certification.eac_norm_id,
                accreditation_id: this.certification.accreditation_id,
                status_id: this.certification.status_id,
            },
        };
    },
    methods: {
        updateCertification() {
            this.$emit(
                "update-certification",
                this.updateCertificationData,
                this.certification.id
            );
            this.wantToEdit = false;
            this.clearUpdateCertificationForm();
        },
        clearUpdateCertificationForm() {
            this.updateCertificationData.norm_id = this.certification.norm_id;
            this.updateCertificationData.eac_norm_id =
                this.certification.eac_norm_id;
            this.updateCertificationData.accreditation_id =
                this.certification.accreditation_id;
            this.updateCertificationData.status_id =
                this.certification.status_id;
        },
        updateEacsSelect() {
            this.normEacs = this.norms.filter(
                (norm) => norm.id == this.updateCertificationData.norm_id
            )[0].eacs_norms;

            this.normEacs.sort(function (a, b) {
                if (a.eac.eac < b.eac.eac) {
                    return -1;
                }
                if (a.eac.eac > b.eac.eac) {
                    return 1;
                }
                return 0;
            });
        },
    },
    computed: {
        bgBadgeService() {
            let bgColor = null;
            switch (this.certification.status.id) {
                case 1:
                    bgColor = "bg-fuchsia-500 text-white";
                    break;
                case 2:
                    bgColor = "bg-purple-500 text-white";
                    break;
                case 3:
                    bgColor = "bg-indigo-500 text-white";
                    break;
                case 4:
                    bgColor = "bg-sky-500 text-white";
                    break;
                default:
                    bgColor = "bg-blue-500 text-white";
                    break;
            }
            return bgColor;
        },
    },
    mounted() {
        this.updateEacsSelect();
    },
};
</script>
<template>
    <tr
        class="hover:bg-white hover:shadow-lg hover:shadow-slate-200/50 px-4 py-2"
    >
        <td class="table-cell border-none text-sm p-4">
            <select
                class="py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl w-full"
                name="norm_id"
                id="norm"
                v-model="updateCertificationData.norm_id"
                v-if="wantToEdit"
                @change="updateEacsSelect()"
            >
                <option
                    v-for="norm in norms"
                    :key="norm.id"
                    :value="norm.id"
                    :selected="norm.id == certification.eac_norm.norm_id"
                >
                    {{ norm.norm }}
                </option>
            </select>
            <span v-else>{{
                certification.eac_norm != null && certification.eac_norm.norm
                    ? certification.eac_norm.norm.norm
                    : null
            }}</span>
        </td>
        <td class="table-cell border-none text-sm p-4">
            <select
                class="py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl w-full"
                name="accreditation_id"
                id="accreditation"
                v-model="updateCertificationData.accreditation_id"
                v-if="wantToEdit"
            >
                <option
                    v-for="accreditation in accreditations"
                    :key="accreditation.id"
                    :value="accreditation.id"
                >
                    {{ accreditation.accreditation }}
                </option>
            </select>
            <span v-else>{{
                certification.accreditation != null
                    ? certification.accreditation.accreditation
                    : null
            }}</span>
        </td>
        <td class="table-cell border-none text-sm p-4">
            <select
                class="py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl w-full"
                name="eac_id"
                id="eac"
                v-model="updateCertificationData.eac_norm_id"
                v-if="wantToEdit"
            >
                <option
                    v-for="normEac in normEacs"
                    :key="normEac.id"
                    :value="normEac.id"
                >
                    <span v-if="normEac && normEac.eac">{{
                        normEac.eac.eac
                    }}</span>
                    <span v-if="normEac.risk"> {{ normEac.risk.risk }}</span>
                </option>
            </select>

            <span v-else>
                {{
                    certification.eac_norm && certification.eac_norm.eac
                        ? certification.eac_norm.eac.eac
                        : null
                }}
                {{
                    certification.eac_norm && certification.eac_norm.risk
                        ? certification.eac_norm.risk.risk
                        : null
                }}
            </span>
        </td>
        <td class="table-cell border-none text-sm p-4">
            <select
                class="py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl w-full"
                name="status_id"
                id="status"
                v-model="updateCertificationData.status_id"
                v-if="wantToEdit"
            >
                <option v-for="stat in status" :key="stat.id" :value="stat.id">
                    {{ stat.status }}
                </option>
            </select>

            <span
                v-else
                class="rounded-xl p-2 text-xs block text-center"
                :class="[bgBadgeService]"
                >{{
                    certification.status != null
                        ? certification.status.status
                        : null
                }}</span
            >
        </td>
        <td class="table-cell border-none text-sm p-4">
            <div v-if="wantToEdit">
                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Guardar"
                    @click="updateCertification()"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-save"
                        class="text-lg text-slate-600"
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Cancelar"
                    @click="
                        wantToEdit = false;
                        clearUpdateCertificationForm();
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-xmark"
                        class="text-lg text-slate-600"
                    />
                </button>
            </div>
            <div v-else>
                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Editar"
                    @click="wantToEdit = true"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-edit"
                        class="text-lg text-slate-600"
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Eliminar"
                    @click="$emit('delete-certification')"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-trash-can"
                        class="text-lg text-slate-600"
                    />
                </button>
            </div>
        </td>
    </tr>
</template>
