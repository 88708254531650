export default {
    methods: {
        sortNormEacsByName(eacs = []){ // Parámetro eacs = Array de eac_norm de una norma
            return eacs.sort(function (a, b) {
                if(!a.eac) return -1;
                if(!b.eac) return 1;

                return a.eac.eac.localeCompare(b.eac.eac, undefined, {numeric: true, sensitivity: 'base'});
            });
        },
        deleteEac(eac = {}){ // Parámetro eac = Objeto eac
            this.$axios.delete('eacs/' + eac.id, { 
                headers: { Authorization: 'Bearer ' + rootGetters['user/getToken']}
            })
            .then((response) => {
                commit('deleteEac', response.data.data);
    
                let popupPayload = {
                    appears: true,
                    message: `Alcance ${payload.eac} eliminado correctamente`,
                    type: 'success',
                }
                commit('miscelaneous/changePopup', popupPayload, {root: true} )
            })
            .catch((error) => {
                let errors = {...error.response.data.errors}
                let popupPayload = {
                    appears: true,
                    message: errors,
                    type: 'error',
                }
                commit('miscelaneous/changePopup', popupPayload, {root: true} )
            })
        }
    }
}