<script>
import { computed, ref } from "vue";
import { mapActions } from "vuex";
import axios from "axios";

import Modal from "../../views/spa/_elements/Modal.vue";
import AppModal from "../components/AppModal.vue";
import AppButton from "../components/AppButton.vue";
import User from "../components/UsersItem.vue";
import AppInput from "../components/AppInput.vue";

export default {
    setup() {
        const users = ref([]);
        const loadingUsers = ref(false);
        const norms = ref([]);
        const usersFilter = ref({
            norm: "",
            accreditation: "",
            eac: "",
            status: "",
        });

        async function getUsers() {
            loadingUsers.value = true;
            await axios
                .get("users?role=auditor")
                .then((res) => {
                    users.value = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    loadingUsers.value = false;
                });
        }
        getUsers();

        async function getNorms() {
            await axios.get("norms").then((response) => {
                norms.value = response.data.data;
            });
        }

        getNorms();

        const normsEacs = computed(() => {
            if (usersFilter.value.norm) {
                /****** revisar por que explota cuando no hay eac.eac */
                let results = norms.value.filter(
                    (norm) => norm.id === usersFilter.value.norm
                )[0].eacs_norms;

                return results;
            }
        });

        const totalOperators = computed(() => {
            return users.value.length || 0;
        });
        const totalAuditors = computed(() => {
            return users.value.length || 0;
        });

        const filterUsersByCertification = function (user) {
            if (
                !usersFilter.value.norm &&
                !usersFilter.value.accreditation &&
                !usersFilter.value.eac
            ) {
                return user;
            }

            if (
                usersFilter.value.norm &&
                !usersFilter.value.accreditation &&
                !usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.eac_norm &&
                            norm.eac_norm.norm_id == usersFilter.value.norm
                    )
                ) {
                    return user;
                }
            }

            if (
                !usersFilter.value.norm &&
                usersFilter.value.accreditation &&
                !usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.accreditation_id &&
                            norm.accreditation_id ==
                                usersFilter.value.accreditation
                    )
                ) {
                    return user;
                }
            }

            if (
                !usersFilter.value.norm &&
                !usersFilter.value.accreditation &&
                usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.eac_norm_id &&
                            norm.eac_norm_id == usersFilter.value.eac
                    )
                ) {
                    return user;
                }
            }

            if (
                usersFilter.value.norm &&
                usersFilter.value.accreditation &&
                !usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.eac_norm &&
                            norm.eac_norm.norm_id == usersFilter.value.norm &&
                            norm.accreditation_id &&
                            norm.accreditation_id ==
                                usersFilter.value.accreditation
                    )
                ) {
                    return user;
                }
            }

            if (
                usersFilter.value.norm &&
                !usersFilter.value.accreditation &&
                usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.eac_norm &&
                            norm.eac_norm.norm_id == usersFilter.value.norm &&
                            norm.eac_norm_id &&
                            norm.eac_norm_id == usersFilter.value.eac
                    )
                ) {
                    return user;
                }
            }

            if (
                !usersFilter.value.norm &&
                usersFilter.value.accreditation &&
                usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.accreditation_id &&
                            norm.accreditation_id ==
                                usersFilter.value.accreditation &&
                            norm.eac_norm_id &&
                            norm.eac_norm_id == usersFilter.value.eac
                    )
                ) {
                    return user;
                }
            }

            if (
                usersFilter.value.norm &&
                usersFilter.value.accreditation &&
                usersFilter.value.eac
            ) {
                if (
                    user.norms.some(
                        (norm) =>
                            norm.eac_norm &&
                            norm.eac_norm.norm_id == usersFilter.value.norm &&
                            norm.accreditation_id &&
                            norm.accreditation_id ==
                                usersFilter.value.accreditation &&
                            norm.eac_norm_id &&
                            norm.eac_norm_id == usersFilter.value.eac
                    )
                ) {
                    return user;
                }
            }

            return false;
        };

        return {
            users,
            totalOperators,
            totalAuditors,
            loadingUsers,
            norms,
            normsEacs,
            usersFilter,
            filterUsersByCertification,
        };
    },
    components: {
        Modal,
        User,
        AppModal,
        AppButton,
        AppInput,
    },
    data() {
        return {
            // OK
            // users: this.$store.state.users.users,
            deletedUsers: {},
            roles: null,
            openFiltersMenu: false,
            // usersFilter: {
            //     norm: "",
            //     accreditation: "",
            //     eac: "",
            //     status: "",
            // },
            storeUserForm: {
                first_name: "",
                last_name: "",
                username: "",
                email: "",
                password: "",
                role_id: "",
            },
            storeUserFormStatus: {
                errors: null,
                status: null,
                message: null,
            },
            userData: {},
            alert: {
                message: "",
                type: "",
            },
            searchAuditors: "",
        };
    },
    computed: {
        type() {
            return "alert-" + this.alert.type;
        },
        filteredUsers() {
            var users = Object.values(this.users);

            users = users.filter(this.filterUsersByCertification);

            if (this.searchAuditors) {
                users = users.filter((user) => {
                    return user.persona.last_name
                        .toLowerCase()
                        .includes(this.searchAuditors.toLowerCase());
                });
            }
            return users.sort((a, b) =>
                a.persona.last_name.localeCompare(b.persona.last_name)
            );
        },
    },
    methods: {
        ...mapActions({
            storeUser: "users/storeUser",
        }),
        toggleOpenFiltersMenu() {
            this.openFiltersMenu = !this.openFiltersMenu;
        },
        updateUserBtn() {
            this.$refs.modificarUsuario.closeModal();
            this.alert.message = "Actualizando la información del usuario.";
            this.alert.type = "warning";
            axios
                .put(
                    "users/" + this.userData.id,
                    {
                        username: this.userData.username,
                        email: this.userData.email,
                        password: this.userData.password,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.getters["user/getToken"],
                        },
                    }
                )
                .then((response) => {
                    this.alert.message =
                        "Se modificó el usuario correctamente.";
                    this.alert.type = "success";
                })
                .catch((error) => {
                    this.alert.message =
                        "Hubo un inconveniente al modificar el usuario.";
                    this.alert.type = "danger";
                });
        },
        storeUserFn() {
            this.storeUser(this.storeUserForm)
                .then((response) => {
                    this.storeUserFormStatus.status = response.status;
                    this.storeUserFormStatus.errors = null;
                    this.storeUserFormStatus.message =
                        "Usuario creado correctamente";
                    this.$refs.nuevoUsuario.closeModal();
                    this.clearStoreUserForm();
                })
                .catch((error) => {
                    console.log(error.response);
                    console.log(error.data);
                    this.storeUserFormStatus.status = error.response.status;
                    this.storeUserFormStatus.errors =
                        error.response.data.errors;
                    if (error.response.status == 422) {
                        this.storeUserFormStatus.message =
                            "Debe corregir los campos marcados con error";
                    }
                });
        },
        clearSearchField() {
            this.searchAuditors = "";
        },
        clearAuditorFilters() {
            this.usersFilter.norm = "";
            this.usersFilter.accreditation = "";
            this.usersFilter.eac = "";
        },
        clearStoreUserForm() {
            this.storeUserForm.first_name = "";
            this.storeUserForm.last_name = "";
            this.storeUserForm.username = "";
            this.storeUserForm.email = "";
            this.storeUserForm.password = "";
            this.storeUserForm.role_id = "";
        },
    },
    created() {
        axios
            .get("roles", {
                headers: {
                    Authorization: "Bearer " + this.$store.state.user.token,
                },
            })
            .then((response) => {
                this.roles = response.data.data;
            })
            .catch((error) => {
                console.log("error en get roles");
            });
    },
};
</script>
<template>
    <div>
        <div class="mx-8">
            <header class="flex flex-row justify-between w-full">
                <div
                    class="flex flex-col justify-start items-baseline my-2 w-1/5"
                >
                    <h1 class="text-xl text-tuvdarkblue">Auditores</h1>
                    <p class="text-tuvdarkblue ml-6 md:ml-0">
                        Administración de auditores
                    </p>
                </div>
                <div class="my-2 relative w-3/5">
                    <input
                        class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                        type="text"
                        v-model="searchAuditors"
                        id="searchCertification"
                        placeholder="Buscar auditor por nombre..."
                        @keyup.esc="clearSearchField"
                        tabindex="0"
                    />
                    <i
                        class="fa-duotone fa-magnifying-glass text-lg absolute left-3 top-4 text-slate-800"
                    ></i>
                </div>
                <div class="my-2 w-1/5 flex justify-end">
                    <!-- <button
                        class="bg-tuvblue cursor-pointer px-4 py-1 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                        v-title
                        title="Agregar usuario"
                        @click="$refs.nuevoUsuario.openModal()"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'fa-person-circle-plus']"
                            class="text-xl"
                            style="--fa-secondary-opacity: 0.6"
                        />
                    </button> -->
                    <button
                        class="bg-tuvblue cursor-pointer px-4 py-1 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                        v-title
                        title="Filtrar usuarios"
                        @click="toggleOpenFiltersMenu()"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'fa-filter-list']"
                            class="text-xl"
                            style="--fa-secondary-opacity: 0.6"
                        />
                    </button>
                </div>
            </header>

            <div
                class="flex flex-row justify-start items-center bg-tuvgray py-2 px-4 text-neutral-800 transition-all ease-in-out h-fit"
                v-if="openFiltersMenu"
            >
                <div class="flex flex-row items-center justify-start">
                    <div class="flex flex-row justify-center items-center">
                        <select
                            id="usersFilterNorm"
                            v-model="usersFilter.norm"
                            class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray self-center w-48 mb-0 outline-none focus:outline-none autofill:bg-tuvgray py-2"
                        >
                            <option selected disabled value="">Norma</option>
                            <option
                                v-for="(norm, index) in norms"
                                :key="index"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-row justify-center items-center ml-4">
                        <select
                            id="usersFilterAccr"
                            v-model="usersFilter.accreditation"
                            class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray self-center w-48 mb-0 outline-none focus:outline-none autofill:bg-tuvgray py-2"
                        >
                            <option selected disabled value="">
                                Acreditación
                            </option>
                            <option
                                v-for="(accreditation, index) in $store.state
                                    .accreditations.accreditations"
                                :key="index"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-row justify-center items-center ml-4">
                        <select
                            id="usersFilterEac"
                            v-model="usersFilter.eac"
                            class="placeholder:text-tuvdarkblue placeholder:italic text-xs border-2 border-tuvgray self-center w-48 mb-0 outline-none focus:outline-none autofill:bg-tuvgray py-2"
                        >
                            <option selected disabled value="">Eac</option>
                            <option
                                v-for="(normEac, index) in normsEacs"
                                :key="index"
                                :value="normEac.id"
                            >
                                <span v-if="normEac.eac">{{
                                    normEac.eac.eac
                                }}</span>
                                <span v-if="normEac.risk">{{
                                    normEac.risk.risk
                                }}</span>
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-row justify-center items-center ml-4">
                        <span
                            class="bg-tuvblue cursor-pointer px-4 py-3 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                            @click="
                                clearAuditorFilters();
                                openFiltersMenu = false;
                            "
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-filter-slash fa-3x"
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div
                class="bg-emerald-500 px-4 py-2 mb-4 text-white rounded-xl flex flex-row leading-5 items-center justify-between transition-all ease-in-out duration-200"
                v-if="
                    storeUserFormStatus.status &&
                    storeUserFormStatus.status <= 400
                "
            >
                {{ storeUserFormStatus.message }}
                <font-awesome-icon
                    icon="fa-duotone fa-circle-xmark"
                    size="2x"
                    class="cursor-pointer"
                    @click="
                        storeUserFormStatus.status = null;
                        storeUserFormStatus.message = null;
                        storeUserFormStatus.errors = null;
                    "
                />
            </div>

            <div class="my-4 flex justify-start items-center gap-4">
                <span class="py-1 px-2 bg-tuvlightblue"
                    >Total auditores {{ totalAuditors }}</span
                >
                <span
                    class="py-1 px-2 bg-tuvgreen"
                    v-if="openFiltersMenu || searchAuditors"
                    >Usuarios encontrados: {{ filteredUsers.length }}</span
                >
            </div>
            <table
                class="table w-full border-collapse border-none rounded text-sm"
            >
                <thead class="rounded-t table-row-group">
                    <tr class="table-row">
                        <th
                            class="table-cell font-bold w-3/12 border-none text-neutral-600 text-left p-2"
                        >
                            Nombre
                        </th>
                        <th
                            class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                        >
                            Usuario
                        </th>
                        <th
                            class="table-cell font-bold w-3/12 border-none text-neutral-600 text-left p-2"
                        >
                            Email
                        </th>
                        <th
                            class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                        >
                            Rol
                        </th>
                        <th
                            class="table-cell font-bold w-3/12 border-none text-neutral-600 text-left p-2 text-right"
                        >
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody class="table-row-group" v-if="loadingUsers">
                    <tr>
                        <td
                            class="table-cell border-none bg-tuvgray p-4 text-tuvblack"
                            colspan="5"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-spinner-third"
                                class="fa-spin w-8 text-xl text-tuvblack mr-2"
                            />Cargando listado de usuarios...
                        </td>
                    </tr>
                </tbody>
                <tbody
                    class="table-row-group"
                    v-if="!loadingUsers && filteredUsers.length <= 0"
                >
                    <tr>
                        <td
                            class="table-cell border-none bg-tuvgray p-4 text-tuvblack"
                            colspan="5"
                        >
                            No se encontraron usuarios
                        </td>
                    </tr>
                </tbody>
                <tbody class="table-row-group" v-else>
                    <user
                        class="hover:shadow-lg hover:shadow-neutral-200/50 hover:bg-neutral-100 border-l-4 border-l-transparent px-4 py-2"
                        v-for="(user, index) in filteredUsers"
                        :key="index"
                        :user="user"
                        :roles="roles"
                        :usersFilter="usersFilter"
                    >
                    </user>
                </tbody>
            </table>

            <modal ref="modificarUsuario">
                <template v-slot:header>
                    <h2>
                        {{ userData.persona.last_name }},
                        {{ userData.persona.first_name }}
                    </h2>
                </template>

                <template v-slot:body>
                    <div>
                        <form
                            @submit.prevent
                            id="updateUserTempForm"
                            name="updateUserTempForm"
                        >
                            <div class="modal-fields">
                                <div class="modal-field">
                                    <label for="username">Usuario</label>
                                    <input
                                        name="username"
                                        id="username"
                                        type="text"
                                        v-model="userData.username"
                                        placeholder="Nombre de usuario"
                                    />
                                </div>
                                <div class="modal-field">
                                    <label for="email">Email</label>
                                    <input
                                        name="email"
                                        id="email"
                                        type="email"
                                        v-model="userData.email"
                                        placeholder="Email"
                                    />
                                </div>
                                <div class="modal-field">
                                    <label for="password">Contraseña</label>
                                    <input
                                        name="password"
                                        id="password"
                                        type="password"
                                        v-model="userData.password"
                                        placeholder="Nueva contraseña"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </template>

                <template v-slot:footer>
                    <div class="modal-buttons">
                        <button
                            class="button button-sm blue-button"
                            @click="updateUserBtn(userData)"
                        >
                            Guardar
                        </button>
                        <button
                            class="button button-sm gray-button"
                            @click="$refs.modificarUsuario.closeModal()"
                        >
                            Cancelar
                        </button>
                        <!-- <button class="button button-sm red-button" @click="deleteServiceTempBtn(serviceData)">Eliminar</button> -->

                        <!-- <button class="button blue-button">Guardar</button> -->
                    </div>
                </template>
            </modal>

            <AppModal ref="nuevoUsuario">
                <template #title>Crear usuario</template>

                <template #body>
                    <div
                        class="bg-rose-500 px-4 py-2 mb-4 text-white rounded-xl flex flex-row items-center justify-between"
                        v-if="
                            storeUserFormStatus.status &&
                            storeUserFormStatus.status >= 400
                        "
                    >
                        <span>{{ storeUserFormStatus.message }}</span>
                    </div>
                    <div>
                        <form class="grid grid-cols-3 gap-4" @submit.prevent>
                            <div>
                                <label class="block" for="first_name"
                                    >Nombre</label
                                >
                                <AppInput
                                    v-model="storeUserForm.first_name"
                                    id="first_name"
                                    placeholder="Nombre"
                                    type="text"
                                    autocomplete="off"
                                />

                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.first_name
                                    "
                                    >{{
                                        storeUserFormStatus.errors.first_name[0]
                                    }}</span
                                >
                            </div>

                            <div>
                                <label class="block" for="last_name"
                                    >Apellido</label
                                >
                                <AppInput
                                    v-model="storeUserForm.last_name"
                                    id="last_name"
                                    placeholder="Apellido"
                                    type="text"
                                    autocomplete="off"
                                />

                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.last_name
                                    "
                                    >{{
                                        storeUserFormStatus.errors.last_name[0]
                                    }}</span
                                >
                            </div>
                            <div>
                                <label class="block" for="email">Email</label>
                                <AppInput
                                    v-model="storeUserForm.email"
                                    id="email"
                                    placeholder="Correo electrónico"
                                    type="text"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.email
                                    "
                                    >{{
                                        storeUserFormStatus.errors.email[0]
                                    }}</span
                                >
                            </div>

                            <div>
                                <label class="block" for="username"
                                    >Usuario</label
                                >
                                <AppInput
                                    v-model="storeUserForm.username"
                                    id="username"
                                    placeholder="Usuario"
                                    type="email"
                                    autocomplete="off"
                                />

                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.username
                                    "
                                    >{{
                                        storeUserFormStatus.errors.username[0]
                                    }}</span
                                >
                            </div>

                            <div>
                                <label class="block" for="password"
                                    >Contraseña</label
                                >
                                <AppInput
                                    v-model="storeUserForm.password"
                                    id="password"
                                    placeholder="Contraseña"
                                    type="password"
                                    autocomplete="off"
                                />

                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.password
                                    "
                                    >{{
                                        storeUserFormStatus.errors.password[0]
                                    }}</span
                                >
                            </div>

                            <div>
                                <label class="block" for="role_id">Rol</label>
                                <select
                                    class="rounded-xl h-9 w-full"
                                    name="role_id"
                                    id="role_id"
                                    v-model="storeUserForm.role_id"
                                    autocomplete="off"
                                >
                                    <option
                                        value=""
                                        disabled="disabled"
                                        selected="true"
                                    >
                                        Rol del usuario
                                    </option>
                                    <option value="2">Operador</option>
                                    <option value="3">Auditor</option>
                                </select>
                                <span
                                    class="text-xs text-rose-500"
                                    v-if="
                                        storeUserFormStatus.errors &&
                                        storeUserFormStatus.errors.role_id
                                    "
                                    >{{
                                        storeUserFormStatus.errors.role_id[0]
                                    }}</span
                                >
                            </div>
                        </form>
                    </div>
                </template>

                <template v-slot:footer>
                    <div class="flex flex-row justify-end gap-4">
                        <AppButton
                            class="bg-tuvgray text-tuvblack"
                            @click="$refs.nuevoUsuario.closeModal()"
                            @keyup.esc="$refs.nuevoUsuario.closeModal()"
                            >Cancelar</AppButton
                        >
                        <AppButton
                            class="bg-tuvblue text-tuvlightgray"
                            @click="storeUserFn()"
                            >Crear usuario</AppButton
                        >
                    </div>
                </template>
            </AppModal>

            <modal ref="usuariosEliminados">
                <template v-slot:header>
                    <h2 class="text-xl">Usuarios eliminados</h2>
                </template>

                <template v-slot:body>
                    <div>
                        <table
                            class="table w-full border-collapse border-none rounded text-sm"
                        >
                            <thead class="rounded-t table-row-group">
                                <tr class="table-row">
                                    <th
                                        class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                                    >
                                        Nombre
                                    </th>
                                    <th
                                        class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                                    >
                                        Usuario
                                    </th>
                                    <th
                                        class="table-cell font-bold w-3/12 border-none text-neutral-600 text-left p-2"
                                    >
                                        Email
                                    </th>
                                    <!-- <th class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2">Rol</th> -->
                                    <th
                                        class="table-cell font-bold w-3/12 border-none text-neutral-600 text-left p-2"
                                    >
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                class="table-row-group"
                                v-if="deletedUsers.length == 0"
                            >
                                <tr>
                                    <td
                                        class="table-cell border-none rounded-xl py-2 px-4 bg-neutral-300 text-neutral-800"
                                        colspan="5"
                                    >
                                        No hay usuarios eliminados
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-row-group" v-else>
                                <user-deleted
                                    class="hover:shadow-lg hover:shadow-neutral-200/50 px-4 py-2"
                                    v-for="(user, index) in deletedUsers"
                                    :key="index"
                                    :user="user"
                                    :roles="roles"
                                >
                                </user-deleted>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template v-slot:footer>
                    <div class="modal-buttons">
                        <button
                            class="button button-sm gray-button"
                            @click="$refs.usuariosEliminados.closeModal()"
                        >
                            Cerrar
                        </button>
                        <!-- <button class="button button-sm red-button" @click="deleteServiceTempBtn(serviceData)">Eliminar</button> -->

                        <!-- <button class="button blue-button">Guardar</button> -->
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>
